<template>
  <div>
    <!--<base-header
      type="gradient-success"
      class="pb-5 pb-4 pt-4 pt-md-4 header_gradiant"
    >
    </base-header>-->
    <body>
      <div class="container-fluid">
        <div class="div_img">
          <img
            class="img"
            src="../../../public/img/apracam_img/logoFooter.png"
          />
        </div>
        <div class="row">
          <div class="col-md-4">
            <div class="quad">
              <div class="quad_dvg">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="currentColor"
                  class="bi bi-hospital"
                  viewBox="0 0 16 16"
                >
                  <path
                    d="M8.5 5.034v1.1l.953-.55.5.867L9 7l.953.55-.5.866-.953-.55v1.1h-1v-1.1l-.953.55-.5-.866L7 7l-.953-.55.5-.866.953.55v-1.1h1ZM13.25 9a.25.25 0 0 0-.25.25v.5c0 .138.112.25.25.25h.5a.25.25 0 0 0 .25-.25v-.5a.25.25 0 0 0-.25-.25h-.5ZM13 11.25a.25.25 0 0 1 .25-.25h.5a.25.25 0 0 1 .25.25v.5a.25.25 0 0 1-.25.25h-.5a.25.25 0 0 1-.25-.25v-.5Zm.25 1.75a.25.25 0 0 0-.25.25v.5c0 .138.112.25.25.25h.5a.25.25 0 0 0 .25-.25v-.5a.25.25 0 0 0-.25-.25h-.5Zm-11-4a.25.25 0 0 0-.25.25v.5c0 .138.112.25.25.25h.5A.25.25 0 0 0 3 9.75v-.5A.25.25 0 0 0 2.75 9h-.5Zm0 2a.25.25 0 0 0-.25.25v.5c0 .138.112.25.25.25h.5a.25.25 0 0 0 .25-.25v-.5a.25.25 0 0 0-.25-.25h-.5ZM2 13.25a.25.25 0 0 1 .25-.25h.5a.25.25 0 0 1 .25.25v.5a.25.25 0 0 1-.25.25h-.5a.25.25 0 0 1-.25-.25v-.5Z"
                  />
                  <path
                    d="M5 1a1 1 0 0 1 1-1h4a1 1 0 0 1 1 1v1a1 1 0 0 1 1 1v4h3a1 1 0 0 1 1 1v7a1 1 0 0 1-1 1H1a1 1 0 0 1-1-1V8a1 1 0 0 1 1-1h3V3a1 1 0 0 1 1-1V1Zm2 14h2v-3H7v3Zm3 0h1V3H5v12h1v-3a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v3Zm0-14H6v1h4V1Zm2 7v7h3V8h-3Zm-8 7V8H1v7h3Z"
                  />
                </svg>
              </div>
              <div class="text_prof">
                <span
                  >Total de Cooperadores: {{ state.infos.COOLABORADORES }}</span
                >
              </div>
            </div>
          </div>
          <div class="col-md-4">
            <div class="quad">
              <div class="quad_dvg">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="currentColor"
                  class="bi bi-clipboard2-pulse"
                  viewBox="0 0 16 16"
                >
                  <path
                    d="M9.5 0a.5.5 0 0 1 .5.5.5.5 0 0 0 .5.5.5.5 0 0 1 .5.5V2a.5.5 0 0 1-.5.5h-5A.5.5 0 0 1 5 2v-.5a.5.5 0 0 1 .5-.5.5.5 0 0 0 .5-.5.5.5 0 0 1 .5-.5h3Z"
                  />
                  <path
                    d="M3 2.5a.5.5 0 0 1 .5-.5H4a.5.5 0 0 0 0-1h-.5A1.5 1.5 0 0 0 2 2.5v12A1.5 1.5 0 0 0 3.5 16h9a1.5 1.5 0 0 0 1.5-1.5v-12A1.5 1.5 0 0 0 12.5 1H12a.5.5 0 0 0 0 1h.5a.5.5 0 0 1 .5.5v12a.5.5 0 0 1-.5.5h-9a.5.5 0 0 1-.5-.5v-12Z"
                  />
                  <path
                    d="M9.979 5.356a.5.5 0 0 0-.968.04L7.92 10.49l-.94-3.135a.5.5 0 0 0-.926-.08L4.69 10H4.5a.5.5 0 0 0 0 1H5a.5.5 0 0 0 .447-.276l.936-1.873 1.138 3.793a.5.5 0 0 0 .968-.04L9.58 7.51l.94 3.135A.5.5 0 0 0 11 11h.5a.5.5 0 0 0 0-1h-.128L9.979 5.356Z"
                  />
                </svg>
              </div>
              <div class="text">
                <span>Total de Pacientes: {{ state.infos.PACIENTES }}</span>
              </div>
            </div>
          </div>
          <div class="col-md-4">
            <div class="quad">
              <div class="quad_dvg">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="currentColor"
                  class="bi bi-person-fill-add"
                  viewBox="0 0 16 16"
                >
                  <path
                    d="M12.5 16a3.5 3.5 0 1 0 0-7 3.5 3.5 0 0 0 0 7Zm.5-5v1h1a.5.5 0 0 1 0 1h-1v1a.5.5 0 0 1-1 0v-1h-1a.5.5 0 0 1 0-1h1v-1a.5.5 0 0 1 1 0Zm-2-6a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z"
                  />
                  <path
                    d="M2 13c0 1 1 1 1 1h5.256A4.493 4.493 0 0 1 8 12.5a4.49 4.49 0 0 1 1.544-3.393C9.077 9.038 8.564 9 8 9c-5 0-6 3-6 4Z"
                  />
                </svg>
              </div>
              <div class="text">
                <span
                  >Pacientes Cadastrados no mês:{{
                    state.infos.PACIENTES_MES
                  }}</span
                >
              </div>
            </div>
          </div>
        </div>
        <div class="card">
          <div class="row">
            <div class="col-md-4">
              <div class="form-group">
                <label>Data Inicial</label>
                <input
                  type="date"
                  class="form-control"
                  v-model="state.data_inicial"
                />
              </div>
            </div>
            <div class="col-md-4">
              <div class="form-group">
                <label>Data Final</label>
                <input
                  type="date"
                  class="form-control"
                  v-model="state.data_final"
                />
              </div>
            </div>
            <div class="col-md-2">
              <button @click="buscarInfo" type="button" class="btn btn-lupa">
                Buscar
              </button>
            </div>
          </div>
          <div class="row" v-if="state.open == true">
            <div class="col-md-4">
              <div class="quad">
                <div class="quad_dvg">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    class="bi bi-clipboard-heart"
                    viewBox="0 0 16 16"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M5 1.5A1.5 1.5 0 0 1 6.5 0h3A1.5 1.5 0 0 1 11 1.5v1A1.5 1.5 0 0 1 9.5 4h-3A1.5 1.5 0 0 1 5 2.5v-1Zm5 0a.5.5 0 0 0-.5-.5h-3a.5.5 0 0 0-.5.5v1a.5.5 0 0 0 .5.5h3a.5.5 0 0 0 .5-.5v-1Z"
                    />
                    <path
                      d="M3 1.5h1v1H3a1 1 0 0 0-1 1V14a1 1 0 0 0 1 1h10a1 1 0 0 0 1-1V3.5a1 1 0 0 0-1-1h-1v-1h1a2 2 0 0 1 2 2V14a2 2 0 0 1-2 2H3a2 2 0 0 1-2-2V3.5a2 2 0 0 1 2-2Z"
                    />
                    <path
                      d="M8 6.982C9.664 5.309 13.825 8.236 8 12 2.175 8.236 6.336 5.31 8 6.982Z"
                    />
                  </svg>
                </div>
                <div class="text_prof">
                  <span
                    >Pacientes Cadastrados: {{ state.busca.PACIENTES }}</span
                  >
                </div>
              </div>
            </div>
            <div class="col-md-4">
              <div class="quad">
                <div class="quad_dvg">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    class="bi bi-file-person"
                    viewBox="0 0 16 16"
                  >
                    <path
                      d="M12 1a1 1 0 0 1 1 1v10.755S12 11 8 11s-5 1.755-5 1.755V2a1 1 0 0 1 1-1h8zM4 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H4z"
                    />
                    <path d="M8 10a3 3 0 1 0 0-6 3 3 0 0 0 0 6z" />
                  </svg>
                </div>
                <div class="text_prof">
                  <span
                    >Cooperadores Cadastrados:
                    {{ state.busca.COOLABORADORES }}</span
                  >
                </div>
              </div>
            </div>
            <div class="col-md-4">
              <div class="quad">
                <div class="quad_dvg">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    class="bi bi-paperclip"
                    viewBox="0 0 16 16"
                  >
                    <path
                      d="M4.5 3a2.5 2.5 0 0 1 5 0v9a1.5 1.5 0 0 1-3 0V5a.5.5 0 0 1 1 0v7a.5.5 0 0 0 1 0V3a1.5 1.5 0 1 0-3 0v9a2.5 2.5 0 0 0 5 0V5a.5.5 0 0 1 1 0v7a3.5 3.5 0 1 1-7 0V3z"
                    />
                  </svg>
                </div>
                <div class="text_prof">
                  <span
                    >Entrevistas Cadastradas:
                    {{ state.busca.ENTREVISTAS }}</span
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="mt-3 card rounded p-3">
          <span class="mb-2">Pacientes por mês</span>
          <div>
            <canvas id="chartPacientes" height="200" />
          </div>
        </div>
        <div class="row">
          <div class="col-sm-3">
            <div class="mt-3 card rounded p-3">
              <span class="mb-2">Por genero</span>
              <div>
                <canvas id="chartGenero" height="200" />
              </div>
            </div>
          </div>
          <div class="col-sm-9">
            <div class="mt-3 card rounded p-3">
              <span class="mb-2">Estado</span>
              <div>
                <canvas id="chartEstado" height="200" />
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-sm-9">
            <div class="mt-3 card rounded p-3">
              <span class="mb-2">Por Médico</span>
              <div>
                <canvas id="chartMedico" height="200" />
              </div>
            </div>
          </div>
          <div class="col-sm-3">
            <div class="mt-3 card rounded p-3">
              <span class="mb-2">Por Idade</span>
              <div>
                <canvas id="chartIdade" height="200" />
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-sm-12">
            <div class="mt-3 card rounded p-3">
              <span class="mb-2">Por Patologia</span>
              <div>
                <canvas id="chartPatologia" height="200" />
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-sm-12">
            <div class="mt-3 card rounded p-3">
              <span class="mb-2">Por Tipo de Óleo</span>
              <div>
                <canvas id="chartTipoOleo" height="200" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </body>
  </div>
</template>
<script>
import { onMounted, reactive } from "vue";
import services from "../../services";
import { useStorage } from "vue3-storage";
import Chart from "chart.js";
export default {
  setup() {
    const storage = useStorage();
    var token = storage.getStorageSync("token");
    const state = reactive({
      infos: [],
      data_inicial: "",
      data_final: "",
      open: false,
    });
    onMounted(() => {
      fetchInfos();

      montaChartPacientes();
      montaChartGenero();
      montaChartEstado();
      montaChartMedico();
      montaChartPatologia();
      montaChartTipoOleo();
      montaChartIdade();
    });
    async function fetchInfos() {
      try {
        const { data } = await services.cadastrar.getAll({ token });
        state.infos = data;
      } catch (error) {
        console.log(error);
      }
    }
    async function buscarInfo() {
      try {
        let resposta = await services.cadastrar.buscaData({
          data_inicial: state.data_inicial,
          data_final: state.data_final,
          token,
        });
        state.busca = resposta.data;
        state.open = true;
      } catch (error) {
        console.log(error);
      }
    }
    async function montaChartPacientes() {
      try {
        const { data } = await services.cadastrar.buscaPacientes({ token });

        var meses = [];
        var dados = [];

        Object.keys(data).forEach((element) => {
          meses.push(element);
        });

        Object.values(data).forEach((element) => {
          dados.push(element);
        });

        new Chart(document.querySelector("#chartPacientes"), {
          type: "bar",
          data: {
            labels: meses,
            datasets: [
              {
                label: "Pacientes",
                borderWidth: 0,
                pointRadius: 0,
                backgroundColor: "#19625d",
                data: dados,
                maxBarThickness: 10,
              },
            ],
          },
          options: {
            responsive: true,
            maintainAspectRatio: false,
            legend: {
              display: false,
            },
            tooltips: {
              enabled: true,
              mode: "index",
              intersect: false,
            },
            scales: {
              yAxes: [
                {
                  gridLines: {
                    borderDash: [2],
                    borderDashOffset: [2],
                    zeroLineBorderDash: [2],
                    zeroLineBorderDashOffset: [2],
                  },
                  ticks: {
                    beginAtZero: true,
                    padding: 10,
                    fontSize: 13,
                    fontColor: "#8898aa",
                    fontFamily: "Open Sans",
                  },
                },
              ],
              xAxes: [
                {
                  gridLines: {
                    drawBorder: false,
                    drawOnChartArea: false,
                    drawTicks: false,
                  },
                  ticks: {
                    padding: 20,
                    fontSize: 13,
                    fontColor: "#8898aa",
                    fontFamily: "Open Sans",
                  },
                },
              ],
            },
          },
        });
      } catch (error) {
        console.log(error);
      }
    }
    async function montaChartGenero() {
      var data = await services.cadastrar.buscaPorGenero({ token });

      new Chart(document.querySelector("#chartGenero"), {
        type: "pie",
        data: {
          labels: ["Masculino", "Feminino", "Outros"],
          datasets: [
            {
              data: [data.data.MASCULINO, data.data.FEMININO, data.data.OUTROS],
              backgroundColor: [
                "rgb(54, 162, 235)",
                "rgb(255, 99, 132)",
                "rgb(255, 205, 86)",
              ],
            },
          ],
        },
        options: {
          responsive: true,
          maintainAspectRatio: false,
          legend: {
            display: false,
          },
          tooltips: {
            enabled: true,
            mode: "index",
            intersect: false,
          },
        },
      });
    }

    async function montaChartEstado() {
      var data = await services.cadastrar.buscaPorEstados({ token });

      console.log(data);

      var estados = [];
      var dados = [];
      var cores = [];

      data.data.forEach((element) => {
        estados.push(element.estado);
        dados.push(element.numero_de_pacientes);
        const letters = "0123456789ABCDEF";
        let color = "#";
        for (let i = 0; i < 6; i++) {
          color += letters[Math.floor(Math.random() * 16)];
        }
        cores.push(color);
      });

      new Chart(document.querySelector("#chartEstado"), {
        type: "bar",
        data: {
          labels: estados,
          datasets: [
            {
              data: dados,
              labels: ["Red", "Blue", "Yellow"],
              backgroundColor: cores,
            },
          ],
        },
        options: {
          responsive: true,
          maintainAspectRatio: false,
          legend: {
            display: false,
          },
          tooltips: {
            enabled: true,
            mode: "index",
            intersect: false,
          },
        },
      });
    }

    async function montaChartMedico() {
      var data = await services.cadastrar.buscaPorMedicos({ token });
      console.log(data.data);
      var nome = [];
      var dados = [];
      var cores = [];
      data.data.forEach((element) => {
        nome.push(element.nome);
        dados.push(element.numero_de_pacientes);

        const letters = "0123456789ABCDEF";
        let color = "#";
        for (let i = 0; i < 6; i++) {
          color += letters[Math.floor(Math.random() * 16)];
        }
        cores.push(color);
      });
      new Chart(document.querySelector("#chartMedico"), {
        type: "bar",
        data: {
          labels: nome,
          datasets: [
            {
              data: dados,
              labels: nome,
              backgroundColor: cores,
            },
          ],
        },
        options: {
          responsive: true,
          maintainAspectRatio: false,
          legend: {
            display: false,
          },
          tooltips: {
            enabled: true,
            mode: "index",
            intersect: false,
          },
          scales: {
            yAxes: [
              {
                ticks: {
                  beginAtZero: true,
                },
              },
            ],
          },
        },
      });
    }

    async function montaChartIdade() {
      var data = await services.cadastrar.buscaPorIdade({ token });
      console.log(data.data);
      var nome = [];
      var dados = [];
      var cores = [];
      data.data.forEach((element) => {
        nome.push(element.faixa_etaria);
        dados.push(element.numero_de_pacientes);

        const letters = "0123456789ABCDEF";
        let color = "#";
        for (let i = 0; i < 6; i++) {
          color += letters[Math.floor(Math.random() * 16)];
        }
        cores.push(color);
      });
      new Chart(document.querySelector("#chartIdade"), {
        type: "bar",
        data: {
          labels: nome,
          datasets: [
            {
              data: dados,
              labels: nome,
              backgroundColor: cores,
            },
          ],
        },
        options: {
          responsive: true,
          maintainAspectRatio: false,
          legend: {
            display: false,
          },
          tooltips: {
            enabled: true,
            mode: "index",
            intersect: false,
          },
          scales: {
            yAxes: [
              {
                ticks: {
                  beginAtZero: true,
                },
              },
            ],
          },
        },
      });
    }

    async function montaChartPatologia() {
      var data = await services.cadastrar.buscaPorPatologia({ token });

      var meses = [];
      var dados = [];
      var cores = [];

      data.data.forEach((element) => {
        meses.push(element.patologia);
        dados.push(element.total);
        const letters = "0123456789ABCDEF";
        let color = "#";
        for (let i = 0; i < 6; i++) {
          color += letters[Math.floor(Math.random() * 16)];
        }
        cores.push(color);
      });

      new Chart(document.querySelector("#chartPatologia"), {
        type: "bar",
        data: {
          labels: meses,
          datasets: [
            {
              data: dados,
              labels: ["Red", "Blue", "Yellow"],
              backgroundColor: [
                "rgb(255, 99, 132)",
                "rgb(54, 162, 235)",
                "rgb(255, 205, 86)",
              ],
            },
          ],
        },
        options: {
          responsive: true,
          maintainAspectRatio: false,
          legend: {
            display: false,
          },
          tooltips: {
            enabled: true,
            mode: "index",
            intersect: false,
          },
          scales: {
            yAxes: [
              {
                ticks: {
                  beginAtZero: true,
                },
              },
            ],
          },
        },
      });
    }

    async function montaChartTipoOleo() {
      var data = await services.cadastrar.buscaPorTipoOleo({ token });

      var meses = [];
      var dados = [];
      var cores = [];

      data.data.forEach((element) => {
        meses.push(element.oleo);
        dados.push(element.total);
        const letters = "0123456789ABCDEF";
        let color = "#";
        for (let i = 0; i < 6; i++) {
          color += letters[Math.floor(Math.random() * 16)];
        }
        cores.push(color);
      });

      new Chart(document.querySelector("#chartTipoOleo"), {
        type: "bar",
        data: {
          labels: meses,
          datasets: [
            {
              data: dados,
              labels: ["Red", "Blue", "Yellow"],
              backgroundColor: [
                "rgb(255, 99, 132)",
                "rgb(54, 162, 235)",
                "rgb(255, 205, 86)",
              ],
            },
          ],
        },
        options: {
          responsive: true,
          maintainAspectRatio: false,
          legend: {
            display: false,
          },
          tooltips: {
            enabled: true,
            mode: "index",
            intersect: false,
          },
          scales: {
            yAxes: [
              {
                ticks: {
                  beginAtZero: true,
                },
              },
            ],
          },
        },
      });
    }

    return {
      state,
      buscarInfo,
    };
  },
};
</script>

<style scoped>
body {
  background: linear-gradient(
    50deg,
    rgb(82, 181, 174) 0%,
    rgb(10, 36, 35) 100%,
    rgb(22, 82, 78)
  ) !important;
}
.header_gradiant {
  background: linear-gradient(
    50deg,
    rgb(82, 181, 174) 0%,
    rgb(10, 36, 35) 100%,
    rgb(22, 82, 78)
  ) !important;
}
.quad {
  padding: 10px;
  background: #fff;
  border: solid 2px #0a2423;
  border-radius: 10px;
  display: flex;
  margin-bottom: 1rem;
  box-shadow: 3px 3px 3px 1px rgba(0, 0, 0, 0.44);
}
.quad_dvg {
  background: #0a2423;
  border-radius: 10px;
  padding: 10px;
}
svg {
  width: 30px;
  height: 30px;
  color: #ffffff;
}
.text {
  margin-left: 5px;
  margin-top: 12px;
}
span {
  color: #0a2423;
  font-size: 11pt;
  font-weight: 510;
}
.img {
  width: 300px;
  height: auto;
}
.text_prof {
  margin-top: 12px;
  margin-left: 5px;
}
.card {
  padding: 10px;
  border: solid 2px #0a2423;
  border-radius: 10px;
}
label {
  color: #0a2423;
  font-weight: 700;
}
input {
  border: solid 1px #0a2423;
  color: #0a2423;
  border-radius: 5px;
}
input:focus {
  border: solid 2px #0a2423;
  color: #0a2423;
  border-radius: 5px;
}
.div_img {
  text-align: center;
  align-items: center;
}
.btn-lupa {
  width: 100%;
  background: #0a2423;
  color: #feb715;
  padding: 0px;
  height: 40px;
  margin-top: 33px;
  transition: 0.2s;
}
.btn-lupa:hover {
  color: #feb715;
  transform: none;
  box-shadow: 3px 3px 3px 1px rgba(0, 0, 0, 0.44);
}
</style>
export default httpClient => ({
    /*----------------SITE--------------------*/
    ativas: async () => {
        const response = await httpClient.get('/patologias-ativas')
        return { data: response }
    },


    /*--------------SISTEMA--------------------*/
    obterPorId: async ({ id, token }) => {
        const headers = {
            "Authorization": "Bearer " + token
        }
        const response = await httpClient.get('/patologias/' + id, { headers });
        return { data: response.data }
    },
    getAll: async ({ token }) => {
        const headers = {
            "Authorization": "Bearer " + token
        }
        const response = await httpClient.get('/patologias', { headers });
        return { data: response.data }
    }, 
    save: async ({ token, patologia }) => {
        const headers = {
            "Authorization": "Bearer " + token
        }
        const response = await httpClient.post('/patologias', patologia, { headers })

        return {
            data: response.data,
        }
    },
    update: async ({ token, patologia }) => {
        let headers = { "Authorization": "Bearer " + token }
        const response = await httpClient.put('/patologias/' + patologia.id, patologia, { headers })
        return {
            data: response.data,
        }
    },
    delete: async ({ id, token }) => {
        const headers = {
            "Authorization": "Bearer " + token
        }
        const response = await httpClient.delete('/patologias/' + id, { headers })
        return { data: response }
    },
})

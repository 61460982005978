<template>
  <div class="header" style="background: linear-gradient(50deg, rgb(0, 0, 0) 0%, rgb(rgb(0, 0, 0)%, rgb(255, 255, 255))">
    <div class="container-fluid">
      <div class="header-body">
        <slot></slot>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "base-header",
  props: {
    type: {
      type: String,
      default: "primary",
      description: "Header background type",
    },
  },
};
</script>
<style></style>

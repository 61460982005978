<template>
  <div class="d-flex d-sm-none">
    <TopCell />
  </div>
  <section id="curso_selecionado">
    <header>
      <div class="container">
        <div class="row">
          <MenuMobileDois />
          <div class="col-md-6">
            <h1 class="title_curso">FINALIZAÇÃO</h1>
            <div class="d-flex d-sm-none div_nome_curso">
              <img src="/img/icon_apracam/img_cursos.png" class="icon_cel" />
              <div class="titulo_cel">
                FORMULARIO DE CADASTRO APRACAM ASSOCIAÇÃO PARANAENSE DE APOIO A
                CANNABIS MEDICINAL
              </div>
            </div>
          </div>
          <div class="col-md-6" style="text-align: right">
            <a href="/">
              <img class="logoQuatro" src="../../../public/img/logo.png"
            /></a>
          </div>
        </div>
      </div>
    </header>
    <div class="container">
      <div style="background-color: #fff">
        <div class="quad-space">
          <div class="row">
            <div class="col-md-6 offset-md-1">
              <h1 class="text-apracam">
                SEU CADASTRO FOI REALIZADO COM SUCESSO! EM BREVE UM DE NOSSOS
                ACOLHEDORES ENTRARÁ EM CONTATO.
              </h1>
              <div class="btn_div">
                <a href="/"
                  ><button type="button" class="btn_inscricao">
                    VOLTAR AO INÍCIO
                  </button></a
                >
              </div>
            </div>
            <div class="col-md-3 offset-2">
              <div class="div-img">
                <img
                  class="img-heart"
                  src="../../../public/img/icon_apracam/heart_hand.png"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
import MenuMobileDois from "../../components/MenuMobileDois.vue";
import TopCell from "../../components/TopCell.vue";
import { reactive, onMounted } from "vue";
import { useStorage } from "vue3-storage";
import { toast } from "vue3-toastify";
import "vue3-toastify/dist/index.css";
export default {
  setup() {
    const storage = useStorage();
    const state = reactive({
      metodo: "",
      btn: false,
    });
    function copyToClipBoard() {
      var content = document.getElementById("textArea");

      content.select();
      document.execCommand("copy");
      state.btn = true;

      toast.success("PIX Copiado!!!", {
        autoClose: 2000,
      });
    }

    onMounted(() => {
      state.metodo = storage.getStorageSync("metodo");
      console.log(state.metodo);
      toast.success("Cadastro Realizado!!!", {
        autoClose: 2000,
      });
    });

    return {
      copyToClipBoard,
      state,
      MenuMobileDois,
      TopCell,
    };
  },
};
</script>
<style scoped>
h1,
h2,
h3,
h4,
h5,
h6,
p,
span {
  font-family: Yanone;
  font-weight: 100;
}
.escolha {
  color: rgb(22, 82, 78);
  font-weight: 400;
}
#curso_selecionado {
  background-color: #fff;
  min-height: 100vh;
}
header {
  background-color: rgb(22, 82, 78);
  padding-bottom: 50px;
  height: 90px;
}
.tipo {
  color: #16524e !important;
  text-align: left;
  font-size: 16pt;
  font-weight: 300;
  margin-right: 5px;
}
.quad-space {
  padding-top: 5rem;
}
.quad {
  margin-bottom: 1rem;
  border: solid 1px rgb(22, 82, 78);
  color: rgb(22, 82, 78);
  border-radius: 20px;
  padding: 10px;
  font-family: Yanone;
}
.quad-pix {
  margin-bottom: 1rem;
  border: solid 1px rgb(22, 82, 78);
  color: rgb(22, 82, 78);
  border-radius: 20px;
  padding: 10px;
  font-family: Yanone;
  display: flex;
}
img {
  width: 120px;
}

.btn-copy {
  margin-left: 6rem;
  background-color: transparent;
  color: rgb(22, 82, 78);
  padding: 5px;
  transition: 0.3s;
  border: none;
}
.btn-copy-check {
  margin-left: 6rem;
  background-color: transparent;
  border: none;
  color: rgb(22, 82, 78);
  padding: 5px;
  transition: 0.3s;
}
textarea {
  color: #16524e !important;
  text-align: left;
  font-size: 16pt;
  font-weight: 300;
  background: transparent;
  border: none;
  resize: none;
  height: 36px;
}
.title_curso {
  color: #fff;
  margin-top: 1rem;
}
textarea:focus {
  outline: none;
}
.text-apracam {
  font-weight: 500;
  color: #84a4a1;
  font-size: 40pt;
  line-height: 1.2;
  font-family: Yanone;
  margin-top: 5rem;
}
.btn_div {
  text-align: left;
}
.btn_inscricao {
  width: 150px;
  background: #1f6e69;
  color: #fff;
  font-size: 16pt;
  height: 50px;
  border: solid 2px #fff;
  border-radius: 20px;  transition: 0.4s;
  margin-top: 5px;
  border: solid 2px #fff;
  font-family: Yanone;
  font-weight: 100;
}
.div-img {
  text-align: center;
  margin-top: 5rem;
}
.img-heart {
  width: 100%;
}
.img-code {
  margin-left: 45px;
}
@media (min-width: 992px) and (max-width: 1200px) {
  .logoQuatro {
    display: none;
  }
  #curso_selecionado {
    height: 100vh;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .logoQuatro {
    position: absolute;
    margin-left: 0;
    left: auto;
    right: 0px;
    z-index: 100;
    width: 20%;
  }
  textarea {
    padding: 0;
    margin-top: 0;
  }
}
@media (min-width: 576px) and (max-width: 767px) {
  .logoQuatro {
    display: none;
  }
  #curso_selecionado {
    height: auto;
  }
  .div-img {
    display: none;
  }
}
@media (max-width: 575px) {
  .logoQuatro {
    display: none;
  }
  #curso_selecionado {
    height: auto;
  }
  .div-img {
    display: none;
  }
  .logoQuatro {
    display: none;
  }
  #curso_selecionado {
    height: auto;
  }
  .div-img {
    display: none;
  }
  .icon_cel {
    margin-right: 20px;
    width: 60px;
    height: 60px;
  }
  .titulo_cel {
    font-family: Yanone;
    font-size: 14pt;
    color: #feb715;
    padding-top: 1rem;
    text-align: right;
    padding-top: 2rem;
  }
  header {
    height: auto;
  }
  .logoQuatro {
    display: none;
  }
  .div_nome_curso {
    width: 100%;
  }
  .title_curso {
    color: #fff;
  }
  .btn_div {
    text-align: center;
  }
}
</style>
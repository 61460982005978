import { createRouter, createWebHistory } from "vue-router";
import DashboardLayout from "@/layout/DashboardLayout";
import AuthLayout from "@/layout/AuthLayout";
import LoginProfile from "../views/LoginProfile.vue";
import Guard from '../services/middleware';
import CadastrarUsuario from '../views/Sistema/CadastrarUsuario';

//site
import Home from "../views/Site/Home.vue";
import CursoDestaque from "../views/Site/CursoDestaque.vue";
import formCurso from "../views/Site/formCurso.vue";
import Cadastro from "../views/Site/Cadastro.vue";
import CadastroPessoa from "../views/Site/CadastroPessoa.vue";
import CadastroEntrevistaSocial from "../views/Site/CadastroEntrevistaSocial.vue";
import CadastroRealizado from "../views/Site/CadastroRealizado.vue";
import Apoiador from "../views/Site/Apoiador.vue";
import Associado from "../views/Site/Associado.vue";
import Agradecimento from "../views/Site/Agradecimento.vue";
import PagCurso from "../views/Site/PagCurso.vue";
import Teste from "../views/Site/Teste.vue";
import TermosDeUso from "../views/Site/TermosDeUso.vue";
import CadastroCancelado from "../views/Site/CadastroCancelado.vue";
import Finalizar from "../views/Site/Finalizar.vue";

//sistema
import Inicio from "../views/Sistema/Inicio.vue";
import Pacientes from "../views/Sistema/Pacientes.vue";
import VisualizarPaciente from "../views/Sistema/VisualizarPaciente.vue";
import ProfissionalSaude from "../views/Sistema/ProfissionalSaude.vue";
import VisualizarProfissional from "../views/Sistema/VisualizarProfissional.vue";
import Cursos from "../views/Sistema/Cursos.vue";
import CadastrarCurso from "../views/Sistema/CadastrarCurso.vue";
import Login from "../views/Sistema/Login.vue";
import AlunosCursos from "../views/Sistema/AlunosCursos.vue";
import VisualizarAluno from "../views/Sistema/VisualizarAluno.vue";
import Usuarios from "../views/Sistema/Usuarios.vue";
import EntrevistaSocialAdm from "../views/Sistema/EntrevistaSocialAdm.vue";
import VisualizarEntrevista from "../views/Sistema/VisualizarEntrevista.vue";
import TiposOleo from "../views/Sistema/TiposOleo.vue";
import CadastrarTipoOleo from "../views/Sistema/CadastrarTipoOleo.vue";
import CadastrarPatologia from "../views/Sistema/CadastrarPatologia.vue";
import Patologias from "../views/Sistema/Patologias.vue";
import Relatorios from "../views/Sistema/Relatorios.vue";
import Pets from "../views/Sistema/Pets.vue";
import Medicos from "../views/Sistema/Medicos.vue";
import CadastrarMedico from "../views/Sistema/CadastrarMedico.vue";
import DispensaOleos from "../views/Sistema/DispensaOleos.vue";


const routes = [
  {
    path: "/",
    name: "home",
    components: { default: Home },
  },
  {
    path: "/teste",
    name: "Teste",
    components: { default: Teste },
  },

  {
    path: "/atendimento",
    name: "atendimento",
    components: { default: Associado },
  },
  {
    path: "/curso-destaque/:id",
    name: "destaqueCurso",
    components: { default: CursoDestaque },
  },
  {
    path: "/inscricao-curso/:id",
    name: "formCurso",
    components: { default: formCurso },
  },
  {
    path: "/pag-curso",
    name: "pagCurso",
    components: { default: PagCurso },
  },
  {
    path: "/cadastrar/:tipo_cadastro",
    name: "cadastroTipo",
    components: { default: Cadastro },
  },
  {
    path: "/cadastro/:tipo_pessoa",
    name: "cadastroPessoa",
    components: { default: CadastroPessoa },
  },
  {
    path: "/cadastro-entrevista-social",
    name: "EntrevistaSocial",
    components: { default: CadastroEntrevistaSocial },
  },
  {
    path: "/cadastro-realizado",
    name: "cadastroRealizado",
    components: { default: CadastroRealizado },
  },
  {
    path: "/doe/:tipo_pagina",
    name: "apoiador",
    components: { default: Apoiador },
  },
  {
    path: "/agradecimento",
    name: "agradecimento",
    components: { default: Agradecimento },
  },
  {
    path: "/termos-de-responsabilidade",
    name: "TermosDeUso",
    components: { default: TermosDeUso },
  },
  {
    path: "/cadastro-cancelado",
    name: "CadastroCancelado",
    components: { default: CadastroCancelado },
  },
  {
    path: "/finalizacao",
    name: "Finalizar",
    components: { default: Finalizar },
  },
  {
    path: "/admin/login",
    name: "login",
    components: { default: Login },
  },

  {
    path: "/admin",
    redirect: "/admin/login",
    component: DashboardLayout,
    children: [
      {
        path: "/admin/inicio",
        name: "inicio",
        components: { default: Inicio },
        beforeEnter: Guard.auth

      },
      {
        path: "/admin/pacientes",
        name: "pacientes",
        components: { default: Pacientes },
        beforeEnter: Guard.auth
      },
      {
        path: "/admin/medicos",
        name: "medicos",
        components: { default: Medicos },
        beforeEnter: Guard.auth
      },
      {
        path: "/admin/cadastrar-medico",
        name: "cadastrarMedico",
        components: { default: CadastrarMedico },
        beforeEnter: Guard.auth
      },
      {
        path: "/admin/visualizar-medico/:id",
        name: "visualizarMedico",
        components: { default: CadastrarMedico },
        beforeEnter: Guard.auth

      },
      {
        path: "/admin/pets",
        name: "pets",
        components: { default: Pets },
        beforeEnter: Guard.auth
      },
      {
        path: "/admin/cadastrar-paciente",
        name: "cadastrarPaciente",
        components: { default: VisualizarPaciente },
        beforeEnter: Guard.auth
      },
      {
        path: "/admin/visualizar-paciente/:id",
        name: "visualizarPaciente",
        components: { default: VisualizarPaciente },
        beforeEnter: Guard.auth

      },
      {
        path: "/admin/cooperadores",
        name: "profissionalSaude",
        components: { default: ProfissionalSaude },
        beforeEnter: Guard.auth

      },

      {
        path: "/admin/visualizar-cooperador/:id",
        name: "VisualizarCooperrador",
        components: { default: VisualizarProfissional },
        beforeEnter: Guard.auth

      },

      {
        path: "/admin/patologias",
        name: "patologias",
        components: { default: Patologias },
        beforeEnter: Guard.auth

      },

      {
        path: "/admin/cadastrar-patologia",
        name: "CadastrarPatologia",
        components: { default: CadastrarPatologia },
        beforeEnter: Guard.auth
      },

      {
        path: "/admin/visualizar-patologia/:id",
        name: "visualizarPatologia",
        components: { default: CadastrarPatologia },
        beforeEnter: Guard.auth
      },

      {
        path: "/admin/tipos-oleos",
        name: "tipoOleo",
        components: { default: TiposOleo },
        beforeEnter: Guard.auth

      },


      {
        path: "/admin/cadastrar-tipo-oleo",
        name: "CadastrarTipoOleo",
        components: { default: CadastrarTipoOleo },
        beforeEnter: Guard.auth
      },

      {
        path: "/admin/visualizar-tipo-oleo/:id",
        name: "visualizarTipoOleo",
        components: { default: CadastrarTipoOleo },
        beforeEnter: Guard.auth
      },

      {
        path: "/admin/cursos",
        name: "cursos",
        components: { default: Cursos },
        beforeEnter: Guard.auth

      },
      {
        path: "/admin/alunos-cursos",
        name: "alunosCursos",
        components: { default: AlunosCursos },
        beforeEnter: Guard.auth

      },
      {
        path: "/admin/cadastrar-curso",
        name: "cadastrarCurso",
        components: { default: CadastrarCurso },
        beforeEnter: Guard.auth

      },

      {
        path: "/admin/visualizar-curso/:id",
        name: "visualizarCurso",
        components: { default: CadastrarCurso },
        beforeEnter: Guard.auth

      },
      {
        path: "/admin/visualizar-aluno/:id",
        name: "visualizarAluno",
        components: { default: VisualizarAluno },
        beforeEnter: Guard.auth

      },
      {
        path: "/admin/usuarios",
        name: "usuarios",
        components: { default: Usuarios },
        beforeEnter: Guard.auth
      },
      {
        path: "/admin/cadastrar-usuarios",
        name: "cadastrarUsuario",
        components: { default: CadastrarUsuario },
        beforeEnter: Guard.auth
      },
      {
        path: "/admin/visualizar-usuarios/:id",
        name: "visualizarUsuario",
        components: { default: CadastrarUsuario },
        beforeEnter: Guard.auth
      },
      {
        path: "/admin/entrevista-social",
        name: "EntrevistaSocialAdm",
        components: { default: EntrevistaSocialAdm },
        beforeEnter: Guard.auth
      },
      {
        path: "/admin/visualizar-entrevista-social/:id",
        name: "VisualizarEntrevista",
        components: { default: VisualizarEntrevista },
        beforeEnter: Guard.auth
      },
      {
        path: "/admin/dispensa-oleo/:id",
        name: "DispensaOleos",
        components: { default: DispensaOleos },
        beforeEnter: Guard.auth
      },
      {
        path: "/admin/relatorios",
        name: "relatorios",
        components: { default: Relatorios },
        beforeEnter: Guard.auth
      }
    ],
  },
  {
    path: "/",
    redirect: "loginProfile",
    component: AuthLayout,
    children: [
      {
        path: "/login-profile",
        name: "loginProfile",
        components: { default: LoginProfile },
      },

    ],
  },
];

const router = createRouter({
  history: createWebHistory(),
  linkActiveClass: "active",
  routes,
});

export default router;

<template>
  <div class="drop d-block d-sm-none">
    <button type="button" data-toggle="dropdown" aria-expanded="false">
      <img src="/img/menu_cel.png" style="width: 40px" />
    </button>
    <div class="dropdown-menu dropdown-menu-right">
      <a class="dropdown-item" href="/">HOME</a>
      <a class="dropdown-item" href="/#servicos">SERVIÇOS</a>
      <a class="dropdown-item" href="/#equipe">EQUIPE</a>
      <a class="dropdown-item" href="/#doe_apracam">DOE</a>
      <!--<a class="dropdown-item" href="#cursos">CURSOS</a>-->
      <a class="dropdown-item" href="/#colaboradores">COLABORADORES</a>
    </div>
  </div>
</template>
<style scoped>
.drop {
  right: 0;
  position: absolute;
  z-index: 1000;
  text-align: right;
}

button {
  width: 80px;
  height: 80px;
  border-radius: 50%;
  border: none;
  background: none;
}

.dropdown-item {
  color: #0c5b56 !important;
  font-family: Yanone;
}
.dropdown-menu .dropdown-item {
  font-size: 14pt !important;
}
a {
  font-size: 20pt;
}

.dropdown-item {
  color: #0c5b56 !important;
}
.dropdown-menu .dropdown-item:focus {
  background: #feb715;
}

@media (min-width: 576px) and (max-width: 767px) {
  .dropdown {
    display: none;
  }
  .dropdown {
    margin-top: 4rem;
  }
}
@media (max-width: 575px) {
  .dropdown {
    display: none !important;
  }
  button {
    margin-top: 30px !important;
  }
}
</style>
<template>
  <teleport to="body">
    <div
      v-if="state.isActive"
      class=" fixed-top left-0 z-50 d-flex  justify-content-center w-100 h-100 special-card"
      @click="handleModalToogle({ status: false })"
    >
      <div
        class="fixed mt-5"
        :class="state.width"
        @click.stop
      >
        <div class="d-flex flex-col overflow-hidden z-51 bg-white rounded-lg animate__animated animate__fadeInDown animate__faster">
          <div class="d-flex flex-col px-12 pt-3 pb-3 bg-white">
            <component :is="state.components" />
          </div>
        </div>

      </div>
    </div>
  </teleport>
</template>

<script>
import { reactive, onMounted, onBeforeUnmount } from 'vue'
import useModal from '../../hooks/useModal'
const DEFAULT_WIDTH = 'w-75 p-3'
export default {
  components: {
  },
  setup () {
    const modal = useModal()
    const state = reactive({
      isActive: false,
      component: {},
      props: {},
      width: DEFAULT_WIDTH
    })
    onMounted(() => {
      modal.listen(handleModalToogle)
    })
    onBeforeUnmount(() => {
      modal.off(handleModalToogle)
    })
    function handleModalToogle (payload) {
        console.log(payload);

      if (payload.status) {
        state.component = payload.component
        state.props = payload.props
        state.width = payload.width ?? DEFAULT_WIDTH
      } else {
        state.component = {}
        state.props = {}
        state.width = DEFAULT_WIDTH
      }
      state.isActive = payload.status
    }
    return {
      state,
      handleModalToogle
    }
  }
}
</script>

<style scoped>
.special-card {
  background-color: rgba(0, 0, 0, 0.5);
}
</style>
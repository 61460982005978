<template>
  <section id="curso_destaque">
    <header>
      <div class="container">
        <div>
          <div class="row">
            <div class="col-md-6">
              <h1 class="title_curso">CURSO</h1>
            </div>
            <div class="col-md-6" style="text-align: right">
              <a href="/">
                <img class="logoQuatro" src="../../../public/img/logo.png"
              /></a>
            </div>
          </div>
        </div>
      </div>
    </header>
    <div style="background-color: #fff;">
      <div class="container-fluid">
        <div class="row">
          <div class="col-md-5 offset-md-1">
            <div class="text_left_curso">
              <p class="p_ex">
                {{ state.descricao }}
              </p>
              <div style="text-align: center">
                <a :href="'/inscricao-curso/' + state.id">
                  <button class="btn_inscricao">Fazer Inscrição</button></a
                >
              </div>
            </div>
          </div>
          <div class="col-md-5">
            <div class="div_img_curso_destaque">
              <img class="img_destaque_curso" :src="state.imagem" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
import { onMounted, reactive } from "vue";
import { useRouter } from "vue-router";
import services from "../../services";
export default {
  setup() {
    const router = useRouter();
    const state = reactive({});
    onMounted(() => {
      router.currentRoute._value.params.id;
      if (router.currentRoute._value.params.id != undefined) {
        fetchCurso();
      }
    });
    async function fetchCurso() {
      try {
        const { data } = await services.cursos.cursoId({
          id: router.currentRoute._value.params.id,
        });
        state.id = data.id;
        state.nome = data.nome;
        state.status = data.status;
        state.descricao = data.descricao;
        state.imagem = data.imagem;
      } catch (error) {
        console.log(error);
      }
    }
    return { state };
  },
};
</script>
<style>
@import url("../../site.css");
</style>
<style scoped>
h1,
h2,
h3,
h4,
h5,
h6,
p,
span {
  font-family: Yanone;
}
header{
  background-color: rgb(22, 82, 78);
  padding-bottom: 50px;
}
.title_curso {
  color: #fff;
  font-weight:300;
  font-size: 30pt;
  padding-top: 20px;
  font-family: Yanone;
}
.p_ex {
  color: rgb(22, 82, 78);
  font-size: 16pt;
  line-height: 1.2;
  text-align: justify;
}
.p_li {
  color: rgb(22, 82, 78);
  font-size: 16pt;
  line-height: 1.2;
  margin-bottom: 10px;
  text-align: justify;
}
.li_curso {
  color: rgb(22, 82, 78);
  font-size: 14pt;
  line-height: 1.2;
  text-align: justify;
}
.text_left_curso {
  padding: 40px;
  margin-top: 8rem;
}

.btn_inscricao {
  width: 60%;
  background: #1f6e69;
  color: #fff;
  font-size: 13pt;
  padding: 10px;
  border: solid 2px #fff;
  border-radius: 12px;
  transition: 0.4s;
  margin-top: 25px;
}
.btn_inscricao:hover {
  width: 65%;
  color: #feb715;
  border: solid 2px #feb715;
}
.div_img_curso_destaque {
  margin-top: 10rem;
  margin-bottom: 2rem;
}
.logoQuatro {
  position: absolute;
  margin-left: 0;
  left: auto;
  right: 0;
  z-index: 100;
  width: 30%;
}
.img_destaque_curso {
  border-radius: 20px;
  width: 100%;
}
@media (min-width: 768px) and (max-width: 991px) {
  .p_ex {
    text-align: center;
  }
  .btn_inscricao {
    width: 90%;
  }
  .btn_inscricao:hover {
    width: 95%;
  }
}
@media (min-width: 576px) and (max-width: 767px) {
  .logoQuatro {
    display: none;
  }
  .text_left_curso {
    text-align: center;
  }
  .text_left_curso {
    padding: 30px;
    margin-top: 1rem;
  }
  .div_img_curso_destaque {
    margin-top: 0;
    margin-bottom: 0;
  }
}
@media (max-width: 575px) {
  .logoQuatro {
    display: none;
  }
  .text_left_curso {
    text-align: center;
  }
  .text_left_curso {
    padding: 30px;
    margin-top: 1rem;
  }
  .div_img_curso_destaque {
    margin-top: 0;
    margin-bottom: 0;
  }
  #curso_destaque{
    padding-bottom: 0;
    height: 100vh;
  }
}
</style>
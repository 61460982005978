export default httpClient => ({
    /*----------------SITE--------------------*/
    /*  deletarCor: async ({ id }) => {
          const response = await httpClient.delete('/cores/' + id, {
              id
          })
          return { data: response }
      },*/
    saveAluno: async ({ formArquivo }) => {
        const response = await httpClient.post('/aluno-cursos', formArquivo)
        return {
            data: response.data,
        }
    },
    cursosAtivos: async () => {
        const response = await httpClient.get('/cursos-ativos')
        return {
            data: response.data,
        }
    },
    cursoId: async ({ id }) => {
        const response = await httpClient.get('/curso/' + id)
        return {
            data: response.data,
        }
    },



    /*--------------SISTEMA--------------------*/
    obterPorId: async ({ id, token }) => {
        const headers = {
            "Authorization": "Bearer " + token
        }
        const response = await httpClient.get('/cursos/' + id, { headers });
        return { data: response.data }
    },
    getAll: async ({ token }) => {
        const headers = {
            "Authorization": "Bearer " + token
        }
        const response = await httpClient.get('/cursos', { headers });
        return { data: response.data }
    },
    saveCurso: async ({ token, formCurso }) => {
        const headers = {
            "Authorization": "Bearer " + token
        }
        const response = await httpClient.post('/cursos', formCurso, { headers })

        return {
            data: response.data,
        }
    },
    update: async ({ id, token, formCurso }) => {
        let headers = { "Authorization": "Bearer " + token, "Content-Type": "multipart/form-data" }
        const response = await httpClient.post('/cursos/' + id, formCurso, { headers })
        return {
            data: response.data,
        }
    },
    delete: async ({ id, token }) => {
        const headers = {
            "Authorization": "Bearer " + token
        }
        const response = await httpClient.delete('/cursos/' + id, { headers })
        return { data: response }
    },
    deleteAluno: async ({ id, token }) => {
        const headers = {
            "Authorization": "Bearer " + token
        }
        const response = await httpClient.delete('/aluno-cursos/' + id, { headers })
        return { data: response }
    },
    getAlunos: async ({ token }) => {
        const headers = {
            "Authorization": "Bearer " + token
        }
        const response = await httpClient.get('/aluno-cursos', { headers });
        return { data: response.data }
    },
    alunoById: async ({ id, token }) => {
        const headers = {
            "Authorization": "Bearer " + token
        }
        const response = await httpClient.get('/aluno-cursos/' + id, { headers });
        return { data: response.data }
    },
    updateAluno: async ({
        id,
        curso_id,
        nome,
        situacao,
        email,
        cultiva_cannabis,
        tem_autorizacao_jud,
        telefone,
        data_nascimento,
        curso, token }) => {
        const headers = {
            "Authorization": "Bearer " + token
        }
        const response = await httpClient.put('/aluno-cursos/' + id, {
            id,
            curso_id,
            nome,
            situacao,
            email,
            cultiva_cannabis,
            tem_autorizacao_jud,
            telefone,
            data_nascimento,
            curso,
            token,
        },
            { headers });
        return { data: response.data }
    },
})

export default httpClient => ({
    logarSistema: async ({ email, senha }) => {
        const response = await httpClient.post('/admin/login', {
            email,
            senha
        })
        let errors = null
        if (!response.data) {
            errors = {
                status: response.request.status,
                statusText: response.request.statusText
            }
        }
        return {
            data: response.data,
            errors
        }
    },

    verificaToken: async (token) => {
        let headers = {
            "Authorization": "Bearer " + token
        }
        const response = await httpClient.get('/admin/verifica-token', {
            headers
        })

        let errors = null

        if (!response.data) {
            errors = {
                status: response.request.status,
                statusText: response.request.statusText
            }
        }
        return {
            data: response.data,
            errors
        }
    },
})

<template>
  <div>
    <base-header type="gradient-success" class="pb-6 pb-8 pt-5 pt-md-8 header_gradiant">
    </base-header>
    <div class="container-fluid mt--7">
      <div class="card shadow p-3" :class="type === 'dark' ? 'bg-default' : ''" s>
        <div class="row">
          <div class="col-md-1">
            <div class="form-group">
              <label>Código</label>
              <input type="text" class="form-control" v-model="state.codigo_busca" />
            </div>
          </div>
          <div class="col-md-4">
            <div class="form-group">
              <label>Paciente</label>
              <input type="text" class="form-control" v-model="state.nome_busca" />
            </div>
          </div>
          <div class="col-md-1 div_btn_busca">
            <div class="form-group">
              <button type="button" class="btn_busca" @click="buscar">
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-search"
                  viewBox="0 0 16 16">
                  <path
                    d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z" />
                </svg>
              </button>
            </div>
          </div>
          <div class="col-md-3 div_btn_busca">
            <button class="btn_busca dropdown-toggle" type="button" @click="openMiniModal">
              Busca Detalhada
            </button>
            <div class="mini-modal" v-if="state.mini_modal">
              <div class="mb-2 div_avan">
                <button class="btn_busca_avan" type="button" @click="buscarAvan">Buscar</button>
                <button class="btn_busca_avan" type="button" @click="limparAvanca">Limpar</button>
              </div>
              <select v-model="filtros.genero">
                <option selected value="">Selecione um Gênero</option>
                <option value="FEMININO">Feminino</option>
                <option value="MASCULINO">Masculino</option>
                <option value="OUTROS">Outros</option>
              </select>
              <select v-model="filtros.doenca">
                <option selected value="">Selecione uma Patologia</option>
                <option v-for="patologia in state.patologias" :key="patologia.id" :value="patologia.id">
                  {{ patologia.nome }}
                </option>
              </select>
              <select v-model="filtros.tipo_oleo">
                <option selected value="">Selecione um Tipo de Óleo</option>
                <option v-for="oleo in state.tipos_oleos" :key="oleo.id" :value="oleo.id">
                  {{ oleo.nome }}
                </option>
              </select>
              <select v-model="filtros.idade">
                <option selected value="">Selecione uma Idade</option>
                <option value="16-30">16-30</option>
                <option value="31-45">31-45</option>
                <option value="46-60">46-60</option>
                <option value="61+">61+</option>
              </select>
            </div>
          </div>
          <div class="col-md-3 div_btn_novo">
            <div class="form-group">
              <a href="/admin/cadastrar-paciente">
                <button type="button" class="btn_novo">
                  Cadastrar
                </button>
              </a>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col">
          <div class="table-responsive" style="background-color: #fff">
            <table class="table tablesorter table align-items-center table-flush">
              <thead class="thead-light" style="border: solid 5px white">
                <th>Código</th>
                <th>Nome</th>
                <th>Telefone</th>
                <th>Email</th>
                <th>Tipo</th>
                <th style="text-align: center;">Ação</th>
              </thead>
              <tbody class="list">
                <corpo-tabela-paciente v-for="paciente in state.pacientes" :key="paciente.id" :paciente="paciente"
                  @deletePaciente="deletePaciente" />
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { onMounted, reactive } from "vue";
import CorpoTabelaPaciente from "../../components/CorpoTabelaPaciente";
import services from "../../services";
import { useStorage } from "vue3-storage";
export default {
  components: {
    CorpoTabelaPaciente,
  },
  setup() {
    const storage = useStorage();
    var token = storage.getStorageSync("token");

    const state = reactive({
      pacientes: [],
      codigo_busca: "",
      nome_busca: "",
      mini_modal: false,
      patologias: [],
      tipos_oleos: [],
    });

    const filtros = reactive({
      genero: '',
      doenca: '',
      tipo_oleo: '',
      idade: ''
    });


    onMounted(() => {
      fetchPacientes();
      fetchPatologias();
      fetchTipoOleo();
    });

    async function fetchPatologias() {
      try {
        const { data } = await services.patologia.getAll({ token });
        state.patologias = data;
      } catch (error) {
        console.log(error);
      }
    }

    async function fetchTipoOleo() {
      try {
        const { data } = await services.tipos_oleo.getAll({ token });
        state.tipos_oleos = data;
      } catch (error) {
        console.log(error);
      }
    }

    async function fetchPacientes() {
      try {
        const { data } = await services.paciente.getAll({ token });
        state.pacientes = data;
      } catch (error) {
        console.log(error);
      }
    }

    async function buscar() {
      if (state.codigo_busca.length == 0 && state.nome_busca.length == 0) {
        fetchPacientes()
        return;
      }
      var data = await services.paciente.busca({
        codigo: state.codigo_busca,
        nome: state.nome_busca,
        token,
      });
      state.pacientes = data.data;
    }

    async function deletePaciente(id) {
      if (confirm("Deseja remover este paciente?")) {
        try {
          const { data } = await services.paciente.delete({
            id: id,
            token,
          });
          if (data.status != 200) {
            alert("Erro ao deletar paciente.");
          } else {
            fetchPacientes();
          }
        } catch (error) {
          console.log(error);
        }
      }
    }

    function openMiniModal() {
      if (state.mini_modal === false) {
        state.mini_modal = true;
      } else {
        state.mini_modal = false
      }
    }

    async function buscarAvan() {
  // Filtrando apenas os campos que foram preenchidos
  const filtro = Object.fromEntries(
    Object.entries(filtros).filter(([, value]) => value !== "")
  );

  // Chamando a API com os filtros
  try {
    const response = await services.paciente.pesquisaAvan(filtro, token);
    state.pacientes = response.data;
    console.log('Pacientes filtrados:', state.pacientes);
  } catch (error) {
    console.error('Erro ao buscar pacientes:', error);
  }
}


    async function limparAvanca() {
      await fetchPacientes();
      filtros.genero = '';
      filtros.doenca = '';
      filtros.tipo_oleo = '';
      filtros.idade = '';
    }

    return {
      state,
      filtros,
      deletePaciente,
      buscar,
      openMiniModal,
      buscarAvan,
      limparAvanca,
    };
  },
};
</script>
<style scoped>
.header_gradiant {
  background: linear-gradient(50deg,
      rgb(82, 181, 174) 0%,
      rgb(10, 36, 35) 100%,
      rgb(22, 82, 78)) !important;
}

input {
  color: #000;
  border: solid 2px rgb(10, 36, 35);
  border-color: rgb(10, 36, 35);
  padding-top: 22px;
  padding-bottom: 22px;
  font-size: 12pt;
}

input:focus {
  color: #000;
  border: solid 2px rgb(40, 149, 146);
  border-color: rgb(40, 149, 146);
}

label {
  color: #000;
  margin-bottom: 0;
  font-size: 14pt;
}

select {
  margin-bottom: 10px;
  color: #000;
  border: solid 2px rgb(10, 36, 35);
  border-color: rgb(10, 36, 35);
  padding: 10px;
  font-size: 12pt;
  width: 100%;
  border-radius: 4px;
}

select:focus {
  color: #000;
  border: solid 2px rgb(40, 149, 146);
  border-color: rgb(40, 149, 146);
}

.div_btn_busca {
  margin-top: 30px;
}

.btn_busca {
  width: 100%;
  background-color: transparent;
  border: solid 2px rgb(10, 36, 35);
  border-color: rgb(10, 36, 35);
  border-radius: 5px;
  height: 45px;
  padding: 5px;
  transition: 0.4s;
  color: rgb(10, 36, 35);
}

.btn_busca:hover {
  background-color: rgb(10, 36, 35);
  color: rgb(255, 255, 255);
  transform: none;
}

.div_avan {
  display: flex;
  justify-content: center;
  align-items: center;
}

.btn_busca_avan {
  width: 100px;
  background-color: transparent;
  border: solid 2px rgb(10, 36, 35);
  border-color: rgb(10, 36, 35);
  border-radius: 5px;
  padding: 5px;
  transition: 0.4s;
  color: rgb(10, 36, 35);
  margin: 5px;
}

.btn_busca_avan:hover {
  background-color: rgb(10, 36, 35);
  color: rgb(255, 255, 255);
  transform: none;
}

.mini-modal {
  padding: 10px;
  background: gainsboro;
  border-radius: 0px 0px 5px 5px;
}

svg {
  height: 20px;
  transition: 0.4s;
  width: 100%;
}

svg:hover {
  height: 30px;
  width: 100%;
}

.div_btn_cliente {
  margin-top: 30px;
  text-align: right;
}

th {
  background: rgba(181, 181, 181, 0.744) !important;
  color: #000 !important;
}

.div_btn_novo {
  margin-top: 30px;
  text-align: right;
}

.btn_novo {
  border: solid 2px rgb(22, 82, 78);
  border-color: rgb(10, 36, 35);
  border-radius: 5px;
  width: 50%;
  height: 45px;
  padding: 5px;
  transition: 0.4s;
  background-color: rgb(22, 82, 78);
  color: #fff;
}
</style>
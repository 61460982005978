<template>
  <footer class="footer px-4 footer_centrion">
    <div class="row align-items-center justify-content-lg-between">
      <div class="col-lg-6">
        <div class="copyright text-center text-lg-left text-muted">
          © {{ year }}
          <a
            class="centrion_web"
            >Centrion Web</a
          >
        </div>
      </div>
      <div class="col-lg-6"></div>
    </div>
  </footer>
</template>
<script>
export default {
  data() {
    return {
      year: new Date().getFullYear(),
    };
  },
};
</script>
<style scoped>
.footer_centrion {
  background-color: #ffffff;
}
.centrion_web {
  color: blue;
  font-weight: 700;
}
</style>

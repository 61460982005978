<template>
  <modal-factory />
  <div>
    <base-header type="gradient-success" class="pb-6 pb-8 pt-5 pt-md-8 header_gradiant">
    </base-header>
    <div class="container-fluid mt--7">
      <div class="row">
        <div class="col-xl-12 col-lg-12">
          <stats-card>
            <nav aria-label="breadcrumb">
              <ol class="breadcrumb">
                <li class="breadcrumb-item router_header">
                  <router-link to="/admin/pacientes">Pacientes</router-link>
                </li>
                <li class="breadcrumb-item router_header">
                  Dispensa de Óleos
                </li>
              </ol>
            </nav>
            <form>
              <div class="row">
                <button @click="abrirDis" type="button" class="btn_salvar_oleo" title="Nova Dispensa"> Criar Nova
                  Dispensa</button>
              </div>
              <div class="row mt-4 mb-4" v-if="state.div_nova_dispensa">
                <div class="col-md-3">
                  <select v-model="state.oleo_id">
                    <option v-for="oleo in state.tipos_oleos" :key="oleo.id" :value="oleo.id">{{ oleo.nome }}</option>
                  </select>
                </div>
                <div class="col-md-3">
                  <input v-mask="'##/##/#### ##:##'" class="" placeholder="Insira uma DATA e uma HORA:"
                    v-model="state.data_hora_dispensa" />
                </div>
                <div class="col-md-2">
                  <button class="btn btn_salvar" id="submit-button" type="button" @click="saveDispensa">Salvar</button>
                </div>
              </div>
              <div class="row">
                <table class="table mt-4 mb-4">
                  <thead class="">
                    <tr>
                      <th class="text-light font-weight-bold" scope="col">Óleo Id</th>
                      <th class="text-light font-weight-bold" scope="col">Óleo</th>
                      <th class="text-light font-weight-bold" scope="col">Data e Hora</th>
                      <th class="text-light font-weight-bold" scope="col">Ação</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="oleo in state.oleos" :key="oleo.id">
                      <th scope="row">{{ oleo.oleo_id.id }}</th>
                      <td>{{ oleo.oleo_id.nome }}</td>
                      <td>{{ oleo.data_hora_dispensa }}</td>
                      <td><button type="button" class="btn btn-danger" @click="deleteDispensa(oleo.id)"><i
                            class="bi bi-trash-fill"></i></button></td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="div_btns">
                <router-link to="/admin/pacientes">
                  <button class="btn btn_cancelar" id="submit-button" type="button">
                    Voltar
                  </button>
                </router-link>
              </div>
            </form>
          </stats-card>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { onMounted, reactive } from "vue";
import { useRouter } from "vue-router";
import services from "../../services";
import { useStorage } from "vue3-storage";
export default {
  setup() {

    const storage = useStorage();
    const router = useRouter();
    var token = storage.getStorageSync("token");

    const state = reactive({
      oleos: [],
      tipos_oleos: [],
      div_nova_dispensa: false,
      oleo_id: "",
      data_hora_dispensa: "",
    });

    onMounted(() => {
      fetchTiposOleo();
      if (router.currentRoute._value.params.id != undefined) {
        fetchTipoOleo();
      }
    });

    function abrirDis() {
      if (state.div_nova_dispensa === false) {
        state.div_nova_dispensa = true;
      } else {
        state.div_nova_dispensa = false;
      }
    }

    async function fetchTipoOleo() {
      try {
        const { data } = await services.tipos_oleo.dispensaOleoCliente({
          id: router.currentRoute._value.params.id,
          token,
        });
        state.oleos = data;
      } catch (error) {
        console.log(error);
      }
    }

    async function fetchTiposOleo() {
      try {
        const { data } = await services.tipos_oleo.getAll({ token });
        state.tipos_oleos = data;
      } catch (error) {
        console.log(error);
      }
    }

    async function saveDispensa() {
      let dispensa = {}
      dispensa.pet_paciente_id = router.currentRoute._value.params.id;
      dispensa.oleo_id = state.oleo_id;
      dispensa.data_hora_dispensa = state.data_hora_dispensa;
      const data = await services.tipos_oleo.saveDispensa(dispensa, token);
      console.log(data);
      fetchTipoOleo();
    }
    async function deleteDispensa(id) {
      if (confirm("Deseja remover esta dispensa?")) {
        try {
          const { data } = await services.tipos_oleo.deleteDispensa({
            id: id,
            token,
          });
          console.log(data);
        } catch (error) {
          console.log(error);
          alert(error);
        }
        fetchTipoOleo();
      }
    }
    return {
      state,
      abrirDis,
      saveDispensa,
      deleteDispensa,
    };
  },
};
</script>

<style scoped>
.header_gradiant {
  background: linear-gradient(50deg,
      rgb(82, 181, 174) 0%,
      rgb(10, 36, 35) 100%,
      rgb(22, 82, 78)) !important;
}

h2 {
  color: black;
  font-size: 18pt;
  font-weight: 700;
}

.btn_cancelar {
  background-color: rgb(255, 255, 255);
  color: rgb(10, 36, 35);
  border: solid 3px rgb(10, 36, 35);
  padding: 15px;
  transition: 0.2s;
  margin-right: 15px;
  width: 20%;
  font-size: 11pt;
}

.btn_cancelar:hover {
  background-color: rgb(10, 36, 35);
  border: solid 3px rgb(10, 36, 35);

  color: #ffffff;
  padding: 15px;
  transform: none;
}

.btn_salvar {
  background-color: rgb(255, 255, 255);
  border: solid 3px rgb(40, 149, 146);
  color: rgb(40, 149, 146);
  padding: 5px;
  transition: 0.3s;
  width: 100%;
  font-size: 11pt;
}

.btn_salvar:hover {
  background-color: rgb(40, 149, 146);
  border: solid 3px rgb(40, 149, 146);
  color: rgb(0, 0, 0);
  transition: 0.3s;
  transform: none;
}

.btn_salvar_oleo {
  background-color: rgb(40, 149, 146);
  border: solid 3px rgb(40, 149, 146);
  color: #000;
  padding: 5px;
  transition: 0.3s;
  width: 200px;
  font-size: 11pt;
  font-weight: 700;
}

.btn_salvar_oleo:hover {
  background-color: rgb(34, 131, 128);
  transition: 0.3s;
  transform: none;
}

.div_btn {
  text-align: right;
}

input {
  color: #000;
  border: solid 2px rgb(10, 36, 35);
  border-color: rgb(10, 36, 35);
  font-size: 12pt;
  width: 100%;
  padding: 10px;
  outline: none;

}

input:focus {
  color: #000;
  border: solid 2px rgb(40, 149, 146);
  border-color: rgb(40, 149, 146);
  outline: none;
}

select {
  color: #000;
  border: solid 2px rgb(10, 36, 35);
  border-color: rgb(10, 36, 35);
  font-size: 12pt;
  width: 100%;
  padding: 10px;
}

select:focus {
  color: #000;
  border: solid 2px rgb(40, 149, 146);
  border-color: rgb(40, 149, 146);
  font-size: 12pt;
}

label {
  color: #000;
  margin-bottom: 0;
  font-size: 14pt;
}

.div_btns {
  width: 100%;
  text-align: right;
}

a {
  color: rgb(82, 181, 174);
}

a:hover {
  color: rgb(22, 82, 78);
}

.file_input {
  height: 180px;
  color: #000;
  border: solid 2px rgb(10, 36, 35);
  border-color: rgb(10, 36, 35);
  padding-top: 22px;
  padding-bottom: 22px;
  font-size: 12pt;
}

.file_input:focus {
  color: #000;
  border: solid 2px rgb(40, 149, 146);
  border-color: rgb(40, 149, 146);
  font-size: 12pt;
}

textarea {
  color: #000;
  border: solid 2px rgb(10, 36, 35);
  border-color: rgb(10, 36, 35);
  padding-top: 22px;
  padding-bottom: 22px;
  font-size: 12pt;
  resize: none;
}

textarea:focus {
  color: #000;
  border: solid 2px rgb(40, 149, 146);
  border-color: rgb(40, 149, 146);
  font-size: 12pt;
}

.img_fetch {
  width: 100%;
}

td {
  color: #000;
  font-size: 11pt;
  font-weight: 600;
}

thead {
  background-color: #0a2423;
}
</style>
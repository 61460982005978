<template>
  <div class="row justify-content-center">
    <div class="col-lg-5 col-md-7">
      <div class="card bg-secondary shadow border-0">

        <div class="card-body px-lg-5 py-lg-5">
          <div class="text-center text-muted mb-4">
            <small>Or sign in with credentials</small>
          </div>
          <form @submit.prevent="handleSubmit" role="form">
            <base-input
              formClasses="input-group-alternative mb-3"
              placeholder="Login"
              addon-left-icon="ni ni-email-83"
              v-model="state.login.value"
            >
            </base-input>
            <span
              v-if="!!state.login.errorMessage"
              class="block font-medium text-brand-danger">
              {{ state.login.errorMessage }}
            </span>


            <base-input
              formClasses="input-group-alternative mb-3"
              placeholder="Senha"
              type="password"
              addon-left-icon="ni ni-lock-circle-open"
              v-model="state.password.value"
            >
            </base-input>

            <span
              v-if="!!state.password.errorMessage"
              class="block font-medium text-brand-danger"
              >
              {{ state.password.errorMessage }}
            </span>

            <div class="text-center">
              <button type="submit" class="btn btn-primary">Entrar</button>
            </div>
          </form>
        </div>
      </div>
    <!--      <div class="row mt-3">
            <div class="col-6">
              <a href="#" class="text-light"><small>Forgot password?</small></a>
            </div>
          </div>  -->
    </div>
  </div>
</template>
<script>
import { reactive } from 'vue'
import services from '../services'
import { useField } from "vee-validate"
import { useRouter } from 'vue-router'
export default {
  setup() {
    const router = useRouter()
    const{
      value: loginValue,
      errorMessage: loginErrorMessage
    } = useField('login');

    const{
      value: passwordValue,
      errorMessage: passwordErrorMessage
    } = useField('password');

    const state = reactive({
      hasErrors: false,
      isLoading: false,
      login: {
        value: loginValue,
        errorMessage: loginErrorMessage
      },
      password: {
        value: passwordValue,
        errorMessage: passwordErrorMessage
      }
    })

    async function handleSubmit () {
      
      try{
        state.isLoading = true
        const { data, errors } = await services.auth.login({
          login: state.login.value,
          password: state.password.value
        })
        
        if(!errors) {
          window.localStorage.setItem('token', data.jwt)
          window.localStorage.setItem('empresa_id', data.empresa_id)
          window.localStorage.setItem('nome_pessoa', data.nome_pessoa)
          window.localStorage.setItem('colaborador_id', data.funcionario_id)
          window.localStorage.setItem('nome_empresa', data.nome_empresa)
          router.push({ name: 'dashboard' })
          
        }
      } catch (error) {
        state.isLoading = false
        state.hasErrors = !!error
      }
    }

    return {
      state,
      handleSubmit
    }
  }
}

</script>
<style></style>

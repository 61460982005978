<template>
  <modal-factory />
  <div>
    <base-header type="gradient-success" class="pb-6 pb-8 pt-5 pt-md-8 header_gradiant">
    </base-header>
    <div class="container-fluid mt--7">
      <div class="row">
        <div class="col-xl-12 col-lg-12">
          <stats-card>
            <nav aria-label="breadcrumb">
              <ol class="breadcrumb">
                <li class="breadcrumb-item router_header">
                  <router-link to="/admin/pacientes">Pacientes</router-link>
                </li>
                <li class="breadcrumb-item router_header">Visualizar</li>
              </ol>
            </nav>
            <form>
              <h2>Dados do Paciente:</h2>
              <div class="row" v-if="state.paciente.estado_civil != null">
                <div class="col-lg-2">
                  <a :href="'/admin/visualizar-entrevista-social/' + state.paciente.id">Visualizar Entrevista Social</a>
                </div>
              </div>
              <div class="row">
                <div class="col-md-1">
                  <div class="form-group">
                    <label>ID</label>
                    <input type="text" class="form-control" readonly v-model="state.paciente.id" />
                  </div>
                </div>
                <div class="col-md-2">
                  <div class="form-group">
                    <label>Tipo</label>
                    <select v-model="state.paciente.tipo">
                      <option value="PACIENTE">PACIENTE</option>
                      <option value="PET">TUTOR PET</option>
                    </select>
                  </div>
                </div>
                <div class="col-md-2">
                  <div class="form-group">
                    <label>Método de Contribuição</label>
                    <select v-model="state.paciente.metodo">
                      <option value="anualidade">Anual</option>
                      <option value="mensal">Mensal</option>
                      <option value="nao_contribuinte">Não Contribuinte</option>
                      <option value="cota">Cota Social</option>
                    </select>
                  </div>
                </div>
                <div class="col-md-3" v-if="state.paciente.tipo == 'PET'">
                  <div class="form-group">
                    <label>Nome do Pet</label>
                    <input type="text" class="form-control" v-model="state.paciente.nome_pet" />
                  </div>
                </div>
                <div class="col-md-3" v-if="state.paciente.tipo == 'PACIENTE'">
                  <div class="form-group">
                    <label>Nome</label>
                    <input type="text" class="form-control" v-model="state.paciente.pessoa.nome" />
                  </div>
                </div>
                <div class="col-md-2" v-if="state.paciente.tipo == 'PACIENTE'">
                  <div class="form-group">
                    <label>Data de Nascimento</label>
                    <input type="text" class="form-control" v-model="state.paciente.pessoa.data_nascimento"
                      v-mask-date.br />
                  </div>
                </div>
                <div class="col-md-2">
                  <div class="form-group">
                    <label>Data de Cadastro</label>
                    <input type="text" class="form-control" v-model="state.paciente.data_cadastro" v-mask-date.br />
                  </div>
                </div>
                <div class="col-md-3">
                  <div class="form-group">
                    <label>CPF</label>
                    <input type="text" class="form-control" v-model="state.paciente.cpf" v-mask-cpf />
                  </div>
                </div>
                <div class="col-md-3">
                  <div class="form-group">
                    <label for="voce">Gênero</label>
                    <select v-model="state.paciente.pessoa.genero">
                      <option value="MASCULINO">MASCULINO</option>
                      <option value="FEMININO">FEMININO</option>
                      <option value="OUTROS">OUTROS</option>
                    </select>
                  </div>
                </div>
                <div class="col-md-2">
                  <div class="form-group">
                    <label>Telefone</label>
                    <input type="text" class="form-control" v-model="state.paciente.pessoa.telefone" v-mask-phone.br />
                  </div>
                </div>
                <div class="col-md-3">
                  <div class="form-group">
                    <label>Email</label>
                    <input type="text" class="form-control" v-model="state.paciente.pessoa.email" />
                  </div>
                </div>
                <div class="col-md-3" v-if="state.paciente.tipo == 'PET'">
                  <div class="form-group">
                    <label>Nome do Tutor</label>
                    <input type="text" class="form-control" v-model="state.paciente.pessoa.nome" />
                  </div>
                </div>
                <div class="col-md-3" v-if="state.paciente.tipo == 'PACIENTE'">
                  <div class="form-group">
                    <label>Nome do Responsável</label>
                    <input type="text" class="form-control" v-model="state.paciente.responsavel" />
                  </div>
                </div>
              </div>
              <h2>Localidade:</h2>
              <div class="row">
                <div class="col-md-3">
                  <div class="form-group">
                    <label>CEP</label>
                    <input type="text" class="form-control" v-model="state.paciente.cep" v-mask-cep
                      @blur="cepAtributes" />
                  </div>
                </div>
                <div class="col-md-4">
                  <div class="form-group">
                    <label>Cidade</label>
                    <input type="text" class="form-control" v-model="state.paciente.pessoa.cidade" />
                  </div>
                </div>
                <div class="col-md-2">
                  <div class="form-group">
                    <label>Estado</label>
                    <input type="text" class="form-control" v-model="state.paciente.pessoa.estado" />
                  </div>
                </div>
                <div class="col-md-4">
                  <div class="form-group">
                    <label>Rua</label>
                    <input type="text" class="form-control" v-model="state.paciente.rua" />
                  </div>
                </div>
                <div class="col-md-2">
                  <div class="form-group">
                    <label>Número</label>
                    <input type="text" class="form-control" v-model="state.paciente.numero" v-mask="'#####'" />
                  </div>
                </div>
                <div class="col-md-3">
                  <div class="form-group">
                    <label>Bairro</label>
                    <input type="text" class="form-control" v-model="state.paciente.bairro" />
                  </div>
                </div>
                <div class="col-md-3">
                  <div class="form-group">
                    <label>Complemento</label>
                    <input type="text" class="form-control" v-model="state.paciente.complemento" />
                  </div>
                </div>
              </div>
              <h2>Médicos:</h2>
              <div class="row">
                <div class="col-md-4">
                  <div class="form-group">
                    <label>Selecione um Médico</label>
                    <select v-model="state.medico_id">
                      <option v-for="medico in state.medicos" :key="medico.id" :value="medico.id">
                        {{ medico.nome }}
                      </option>
                      <option value="OUTRO_MEDICO">Outro Médico</option>
                    </select>
                  </div>
                </div>
                <div class="col-md-5" v-if="state.medico_id === 'OUTRO_MEDICO'">
                  <div class="form-group">
                    <label>Outro Médico</label>
                    <input type="text" class="form-control" v-model="state.outro_medico" />
                  </div>
                </div>
              </div>
              <h2>Patologias:</h2>
              <div class="row">
                <div class="col-md-2" v-for="patologia in state.patologias" :key="patologia.id">
                  <div class="form-check">
                    <input :id="'patologia_' + patologia.id" type="checkbox" :value="patologia.id"
                      v-model="state.check_patologia" :true-value="[]" />
                    <label :for="'patologia_' + patologia.id">{{
                      patologia.nome
                      }}</label>
                  </div>
                </div>
                <div class="col-md-2">
                  <div class="form-check">
                    <input id="outro_patologia" type="checkbox" v-model="state.check_outra_patologia" />
                    <label for="outro_patologia">Outras Patologias</label>
                  </div>
                </div>
                <div class="col-md-6" v-if="state.check_outra_patologia">
                  <div class="form-group">
                    <label>Outra Patologia</label>
                    <input type="text" class="form-control" v-model="state.outra_patologia" />
                  </div>
                </div>
              </div>
              <h4>Patologias (sistema anterior)</h4>
              <input type="text" v-model="state.paciente.patologia" class="form-control" />
              <h2>Tipos de Óleo:</h2>
              <div class="row">
                <div class="col-md-2" v-for="tipo_oleo in state.tipos_oleos" :key="tipo_oleo.id">
                  <div class="form-check">
                    <input :id="'oleo_' + tipo_oleo.id" type="checkbox" :value="tipo_oleo.id"
                      v-model="state.check_oleos" :true-value="[]" />
                    <label :for="'oleo_' + tipo_oleo.id">{{
                      tipo_oleo.nome
                      }}</label>
                  </div>
                </div>
                <div class="col-md-2">
                  <div class="form-check">
                    <input id="outro_oleo" type="checkbox" v-model="state.check_outro_oleo" />
                    <label for="outro_oleo">Outros Tipos de Óleos</label>
                  </div>
                </div>
                <div class="col-md-6" v-if="state.check_outro_oleo">
                  <div class="form-group">
                    <label>Outro Óleo</label>
                    <input type="text" class="form-control" v-model="state.outro_oleo" />
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-md-4">
                  <label>Receitas</label>
                  <input type="file" class="form-control input-image" @change="adicionarReceitas" />
                </div>
                <div class="form-group">
                  <label>Data da Receita</label>
                  <input type="text" class="form-control" v-model="state.paciente.data_receita" v-mask-date.br />
                </div>
              </div>
              <div class="row">
                <div class="col-md-3 mt-2 d-flex" v-for="(receita, i) in state.paciente.documentosOrganizados
                  .RECEITA" :key="receita">
                  <a :href="receita.documento" target="_blank">
                    <div v-if="receita.tipo == 'pdf' || receita.tipo == 'PDF'">
                      <i class="bi bi-file-pdf" style="font-size: 30pt; color: red"></i>
                    </div>
                    <div v-else>
                      <img class="imagem-input" :src="receita.documento" />
                    </div>
                  </a>
                  <button type="button" class="btn_deletar_imagem" @click="removerReceita(i)">
                    <i class="bi bi-x-circle"></i>
                  </button>
                </div>
              </div>
              <hr />
              <div class="row">
                <div class="col-md-4">
                  <label>Laudos</label>
                  <input type="file" class="form-control input-image" @change="adicionarLaudos" />
                </div>
              </div>
              <div class="row">
                <div class="col-md-3 mt-2 d-flex" v-for="(laudo, i) in state.paciente.documentosOrganizados
                  .LAUDO" :key="laudo">
                  <a :href="laudo.documento" target="_blank">
                    <div v-if="laudo.tipo == 'pdf' || laudo.tipo == 'PDF'">
                      <i class="bi bi-file-pdf" style="font-size: 30pt; color: red"></i>
                    </div>
                    <div v-else>
                      <img class="imagem-input" :src="laudo.documento" />
                    </div>
                  </a>
                  <button type="button" class="btn_deletar_imagem" @click="removerLaudo(i)">
                    <i class="bi bi-x-circle"></i>
                  </button>
                </div>
              </div>
              <hr />
              <div class="row">
                <div class="col-md-4">
                  <label>Documentos do Responsável</label>
                  <input type="file" class="form-control input-image" @change="adicionarDocsResponsavel" />
                </div>
              </div>
              <div class="row">
                <div class="col-md-3 mt-2 d-flex" v-for="(doc_responsavel, i) in state.paciente
                  .documentosOrganizados.DOC_RESPONSAVEL" :key="doc_responsavel">
                  <a :href="doc_responsavel.documento" target="_blank">
                    <div v-if="
                      doc_responsavel.tipoPdfDocResponsavel == 'pdf' ||
                      doc_responsavel.tipoPdfDocResponsavel == 'PDF'
                    ">
                      <i class="bi bi-file-pdf" style="font-size: 30pt; color: red"></i>
                    </div>
                    <div v-else>
                      <img class="imagem-input" :src="doc_responsavel.documento" />
                    </div>
                  </a>
                  <button type="button" class="btn_deletar_imagem" @click="removerDocResponsavel(i)">
                    <i class="bi bi-x-circle"></i>
                  </button>
                </div>
              </div>
              <hr />
              <div class="row">
                <div class="col-md-4">
                  <label>Documentos do Paciente</label>
                  <input type="file" class="form-control input-image" @change="adicionarDocs" />
                </div>
              </div>
              <div class="row">
                <div class="col-md-3 mt-2 d-flex" v-for="(doc_paciente, i) in state.paciente
                  .documentosOrganizados.DOC_PACIENTE" :key="doc_paciente">
                  <a :href="doc_paciente.documento" target="_blank">
                    <div v-if="
                      doc_paciente.tipo == 'pdf' || doc_paciente.tipo == 'PDF'
                    ">
                      <i class="bi bi-file-pdf" style="font-size: 30pt; color: red"></i>
                    </div>
                    <div v-else>
                      <img class="imagem-input" :src="doc_paciente.documento" />
                    </div>
                  </a>
                  <button type="button" class="btn_deletar_imagem" @click="removerDoc(i)">
                    <i class="bi bi-x-circle"></i>
                  </button>
                </div>
              </div>
              <hr />
              <div class="row">
                <div class="col-md-4">
                  <label>Comprovante de Residência</label>
                  <input type="file" class="form-control input-image" @change="adicionarComprovante" />
                </div>
              </div>
              <div class="row" v-if="state.paciente.arquivos.comprovante_residencia != null">
                <div class="col-md-6 mt-2 d-flex">
                  <div v-if="
                    state.tipoPdfComprovante == 'pdf' ||
                    state.tipoPdfComprovante == 'PDF'
                  ">
                    <a :href="state.paciente.arquivos.comprovante_residencia" target="_blank">
                      <i class="bi bi-file-pdf" style="font-size: 30pt; color: red"></i>
                    </a>
                  </div>
                  <div v-else>
                    <a :href="state.paciente.arquivos.comprovante_residencia" target="_blank">
                      <img :src="state.paciente.arquivos.comprovante_residencia" class="imagem-input" />
                    </a>
                  </div>
                  <button class="btn_deletar_imagem" type="button" @click="removerComprovante">
                    <i class="bi bi-x-circle"></i>
                  </button>
                </div>
              </div>
              <div>
                <div class="div_btns">
                  <router-link to="/admin/pacientes">
                    <button class="btn btn_cancelar" id="submit-button" type="button">
                      Voltar
                    </button>
                  </router-link>
                  <button class="btn btn_salvar" id="submit-button" type="button" @click="upPaciente()">
                    Atualizar
                  </button>
                </div>
              </div>
            </form>
          </stats-card>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { onMounted, reactive } from "vue";
import { useRouter } from "vue-router";
import services from "../../services";
import { useStorage } from "vue3-storage";
// state.paciente.documentosOrganizados.DOC_RESPONSAVEL
export default {
  setup() {
    const storage = useStorage();
    var token = storage.getStorageSync("token");
    const router = useRouter();

    const state = reactive({
      paciente: {
        pessoa: {},
        tipo: "PACIENTE",
        arquivos: {
          comprovante_residencia: null,
        },
        documentosOrganizados: {
          DOC_RESPONSAVEL: [],
          DOC_PACIENTE: [],
          LAUDO: [],
          RECEITA: [],
        },
        data_receita: "",
      },
      tipoPdfReceita: "",
      tipoPdfLaudo: "",
      tipoPdfComprovante: "",
      tipoPdfDocumento: "",
      tipoPdfDocumentoPaciente: "",
      arquivos: [],
      imagens_deletar: [],
      medicos: [],

      tipos_oleos: [],
      check_oleos: [],
      check_outro_oleo: false,
      outro_oleo: "",

      patologias: [],
      patologia: "",
      check_patologia: [],
      check_outra_patologia: false,
      outra_patologia: "",

      medico_id: "",
      outro_medico: "",
    });

    onMounted(() => {
      if (router.currentRoute._value.params.id != undefined) {
        fetchPacientes();
      }
      fetchPatologias();
      fetchMedicos();
      fetchTipoOleo();
    });

    async function fetchPatologias() {
      try {
        const { data } = await services.patologia.getAll({ token });
        state.patologias = data;
      } catch (error) {
        console.log(error);
      }
    }
    async function fetchTipoOleo() {
      try {
        const { data } = await services.tipos_oleo.getAll({ token });
        state.tipos_oleos = data;
      } catch (error) {
        console.log(error);
      }
    }
    async function fetchMedicos() {
      try {
        const { data } = await services.medicos.getAll({ token });
        state.medicos = data;
      } catch (error) {
        console.log(error);
      }
    }
    async function fetchPacientes() {
      //  try {
      const { data } = await services.paciente.obterPorId({
        id: router.currentRoute._value.params.id,
        token,
      });
      if (data.arquivos != null) {
        if (
          data.arquivos.comprovante_residencia != null &&
          data.arquivos.comprovante_residencia != undefined
        ) {
          const comprovante = data.arquivos.comprovante_residencia;
          const myComprovante = comprovante.split(".");
          var i = myComprovante.length;
          var pdfComprovante = myComprovante[i - 1];
          //     state.arquivos.tipoPdfComprovante.tipo = pdfComprovante;
          state.tipoPdfComprovante = pdfComprovante;
        }
      }
      var length;
      for (
        let index = 0;
        index < data.documentosOrganizados.RECEITA.length;
        index++
      ) {
        const receita = data.documentosOrganizados.RECEITA[index].documento;
        var myArray = receita.split(".");
        length = myArray.length;
        var pdf = myArray[length - 1];
        data.documentosOrganizados.RECEITA[index].tipo = pdf;
      }

      for (
        let index = 0;
        index < data.documentosOrganizados.LAUDO.length;
        index++
      ) {
        const laudo = data.documentosOrganizados.LAUDO[index].documento;
        var myArray2 = laudo.split(".");
        length = myArray2.length;
        var pdf2 = myArray2[length - 1];
        data.documentosOrganizados.LAUDO[index].tipo = pdf2;
      }

      for (
        let index = 0;
        index < data.documentosOrganizados.DOC_RESPONSAVEL.length;
        index++
      ) {
        const doc_responsavel =
          data.documentosOrganizados.DOC_RESPONSAVEL[index].documento;
        var myArray3 = doc_responsavel.split(".");
        length = myArray3.length;
        var pdf3 = myArray3[length - 1];
        data.documentosOrganizados.DOC_RESPONSAVEL[index].tipo = pdf3;
      }

      for (
        let index = 0;
        index < data.documentosOrganizados.DOC_PACIENTE.length;
        index++
      ) {
        const doc_responsavel =
          data.documentosOrganizados.DOC_PACIENTE[index].documento;
        var myArray4 = doc_responsavel.split(".");
        length = myArray4.length;
        var pdf4 = myArray4[length - 1];
        data.documentosOrganizados.DOC_PACIENTE[index].tipo = pdf4;
      }
      console.log(data);
      state.paciente = data;
      state.medico_id = data.medico_id;
      const patologiaIds = data.patologia_paciente.map(
        (item) => item.patologia_id
      );
      state.check_patologia = patologiaIds;

      const oleosId = data.tipos_oleos_paciente.map(
        (item) => item.tipo_oleo_id
      );
      state.check_oleos = oleosId;
      console.log("patologia_ids: ", state.check_patologia);
      state.paciente.metodo = data.contribuicao;
      console.log(data.arquivos);

      if (data.nome_outro_medico) {
        state.medico_id = "OUTRO_MEDICO";
        state.outro_medico = data.nome_outro_medico;
      }
      if (data.outra_patologia) {
        state.check_outra_patologia = true;
        state.outra_patologia = data.outra_patologia;
      }
      if (data.outro_tipo_oleo) {
        state.check_outro_oleo = true;
        state.outro_oleo = data.outro_tipo_oleo;
      }
      if (data.arquivos == undefined) {
        console.log("entrou no if");
        state.paciente.arquivos = {};
      }

      //   } catch (error) {
      //  console.log(error);
      // }
    }
    async function upPaciente() {

      console.log(state.check_patologia);
      console.log(state.check_oleos);
      let dados = new FormData();

      if (state.check_patologia) {
        dados.append("patologias", state.check_patologia);
      }

      if (state.check_oleos) {
        dados.append("tipos_oleo", state.check_oleos);
      }

      if (state.medico_id === "OUTRO_MEDICO") {
        dados.append("nome_outro_medico", state.outro_medico);
      } else {
        dados.append("medico_id", state.medico_id);
      }

      dados.append("check_outra_patologia", state.check_outra_patologia);
      if (state.check_outra_patologia) {
        dados.append("outra_patologia", state.outra_patologia);
      }

      dados.append("check_outro_oleo", state.check_outro_oleo);
      if (state.check_outro_oleo) {
        dados.append("outro_tipo_oleo", state.outro_oleo);
      }

      dados.append("id", state.paciente.id);
      dados.append("nome", state.paciente.pessoa.nome);
      dados.append("nome_pet", state.paciente.nome_pet);
      dados.append("tipo", state.paciente.tipo);

      if (state.paciente.tipo == "PACIENTE") {
        dados.append("tipo_pessoa", "PACIENTE");
      }

      if (state.paciente.tipo == "PET") {
        dados.append("tipo_pessoa", "TUTOR_PET");
      }

      dados.append("contribuicao", state.paciente.metodo);
      dados.append("metodo", state.paciente.metodo);
      dados.append("responsavel", state.paciente.responsavel);
      dados.append("email", state.paciente.pessoa.email);
      dados.append("telefone", state.paciente.pessoa.telefone);
      dados.append("genero", state.paciente.pessoa.genero);
      dados.append("data_nascimento", state.paciente.pessoa.data_nascimento);
      dados.append("data_receita", state.paciente.data_receita);
      dados.append("data_cadastro", state.paciente.data_cadastro);
      dados.append("numero", state.paciente.numero);
      dados.append("rua", state.paciente.rua);
      dados.append("bairro", state.paciente.bairro);
      dados.append("cidade", state.paciente.pessoa.cidade);
      dados.append("estado", state.paciente.pessoa.estado);
      dados.append("complemento", state.paciente.complemento);
      dados.append("cep", state.paciente.cep);
      dados.append("cpf", state.paciente.cpf);

      if (
        state.paciente.id != null &&
        state.paciente.id != undefined &&
        state.paciente.id != ""
      ) {
        if (state.paciente.arquivos != null) {
          //Se tiver o arquivo, então ele colocou agora, deve mexer
          if (state.paciente.arquivos.file != null) {
            dados.append(
              "comprovante_residencia",
              state.paciente.arquivos.file
            );
          } else if (state.paciente.arquivos.comprovante_residencia == null) {
            dados.append("comprovante_residencia", null);
          }
        } else {
          dados.append("comprovante_residencia", null);
        }
      }

      if (
        state.paciente.id != null &&
        state.paciente.id != undefined &&
        state.paciente.id != ""
      ) {
        dados.append("_method", "PATCH");
        try {
          await services.paciente.update(dados, token);
        } catch (error) {
          var valores = Object.values(error.response.data.errors);
          valores.forEach((element) => {
            element.forEach((element2) => {
              alert(element2);
            });
          });
          return;
        }
      } else {
        try {
          var paciente = await services.paciente.save(dados, token);
          console.log(paciente);
          state.paciente.id = paciente.data[0].id;
        } catch (error) {
          console.log(error);
          valores = Object.values(error.response.data.errors);
          valores.forEach((element) => {
            element.forEach((element2) => {
              alert(element2);
            });
          });
          return;
        }
      }

      for (
        let i = 0;
        i < state.paciente.documentosOrganizados.RECEITA.length;
        i++
      ) {
        if (
          state.paciente.documentosOrganizados.RECEITA[i].file != null &&
          state.paciente.documentosOrganizados.RECEITA[i].file != undefined
        ) {
          var formReceitas = new FormData();
          formReceitas.append("paciente_id", state.paciente.id);
          formReceitas.append("tipo", "RECEITA");
          formReceitas.append(
            "documento",
            state.paciente.documentosOrganizados.RECEITA[i].file
          );
          try {
            await services.cadastrar.saveDocsPacientes(formReceitas);
          } catch (error) {
            console.log(error);
          }
        }
      }

      for (
        let i = 0;
        i < state.paciente.documentosOrganizados.LAUDO.length;
        i++
      ) {
        if (
          state.paciente.documentosOrganizados.LAUDO[i].file != null &&
          state.paciente.documentosOrganizados.LAUDO[i].file != undefined
        ) {
          var formLaudos = new FormData();
          formLaudos.append("paciente_id", state.paciente.id);
          formLaudos.append("tipo", "LAUDO");
          formLaudos.append(
            "documento",
            state.paciente.documentosOrganizados.LAUDO[i].file
          );
          try {
            await services.cadastrar.saveDocsPacientes(formLaudos);
          } catch (error) {
            console.log(error);
          }
        }
      }
      //  console.log(state.paciente.documentosOrganizados.DOC_RESPONSAVEL);
      for (
        let i = 0;
        i < state.paciente.documentosOrganizados.DOC_RESPONSAVEL.length;
        i++
      ) {
        if (
          state.paciente.documentosOrganizados.DOC_RESPONSAVEL[i].file !=
          null &&
          state.paciente.documentosOrganizados.DOC_RESPONSAVEL[i].file !=
          undefined
        ) {
          var formDocsResponsavel = new FormData();
          formDocsResponsavel.append("paciente_id", state.paciente.id);
          formDocsResponsavel.append("tipo", "DOC_RESPONSAVEL");
          formDocsResponsavel.append(
            "documento",
            state.paciente.documentosOrganizados.DOC_RESPONSAVEL[i].file
          );
          try {
            await services.cadastrar.saveDocsPacientes(formDocsResponsavel);
          } catch (error) {
            console.log(error);
          }
        }
      }

      for (
        let i = 0;
        i < state.paciente.documentosOrganizados.DOC_PACIENTE.length;
        i++
      ) {
        if (
          state.paciente.documentosOrganizados.DOC_PACIENTE[i].file != null &&
          state.paciente.documentosOrganizados.DOC_PACIENTE[i].file != undefined
        ) {
          var formDocsPaciente = new FormData();
          formDocsPaciente.append("paciente_id", state.paciente.id);
          formDocsPaciente.append("tipo", "DOC_PACIENTE");
          formDocsPaciente.append(
            "documento",
            state.paciente.documentosOrganizados.DOC_PACIENTE[i].file
          );
          try {
            await services.cadastrar.saveDocsPacientes(formDocsPaciente);
          } catch (error) {
            console.log(error);
          }
        }
      }
      console.log(
        "Qtd de imagens para deletar: " + state.imagens_deletar.length
      );
      if (state.imagens_deletar.length > 0) {
        try {
          const { data } = await services.paciente.deletarDocumentos({
            token,
            imagens_deletar: state.imagens_deletar,
          });
          console.log(data);
        } catch (error) {
          console.log(error);
        }
      }
      router.push({ name: "pacientes" });
    }
    //Add e remover comprovante
    async function adicionarComprovante(event) {
      var img = event.target.files[0];
      var objComprovante = new Object();
      objComprovante.file = img;
      var split = objComprovante.file.name.split(".");
      var length = split.length;
      var pdf_comprovante = split[length - 1];
      objComprovante.tipoPdfComprovante = pdf_comprovante;
      objComprovante.comprovante_residencia = URL.createObjectURL(img);
      state.paciente.arquivos = objComprovante;
    }
    async function removerComprovante() {
      state.paciente.arquivos.comprovante_residencia = null;
      document.querySelector('input[type="file"]').value = null;
    }
    //add e remover receitas
    async function adicionarReceitas(event) {
      for (let index = 0; index < event.target.files.length; index++) {
        const element = event.target.files[index];
        var objReceita = new Object();
        objReceita.id = index + 1;
        objReceita.file = element;
        var split = objReceita.file.name.split(".");
        var length = split.length;
        var pdf_receita = split[length - 1];
        objReceita.tipoPdfReceita = pdf_receita;
        objReceita.documento = URL.createObjectURL(element);
        state.paciente.documentosOrganizados.RECEITA.push(objReceita);
      }
    }

    async function cepAtributes() {
      var cep = state.paciente.cep;
      cep = cep.replace("-", "").replace(".", "");
      console.log(cep);

      try {
        const { data } = await services.cep.apiCep(cep);
        state.paciente.rua = data.logradouro;
        state.paciente.bairro = data.bairro;
        state.paciente.pessoa.cidade = data.localidade;
        state.paciente.pessoa.estado = data.uf;
        //    state.uf = data.uf;
        //     console.log(data);
      } catch (error) {
        console.log(error);
      }

      /*
        var cep = state.cep;
        
        
        */
    }
    async function removerReceita(i) {
      if (state.paciente.documentosOrganizados.RECEITA[i].id != undefined) {
        state.imagens_deletar.push(
          state.paciente.documentosOrganizados.RECEITA[i].id
        );
      }
      state.paciente.documentosOrganizados.RECEITA.splice(i, 1);
      document.querySelector('input[type="file"]').value = null;
    }
    //add e remover laudos
    async function adicionarLaudos(event) {
      for (let index = 0; index < event.target.files.length; index++) {
        const element = event.target.files[index];
        var objImagem = new Object();
        objImagem.id = index + 1;
        objImagem.file = element;
        var split = objImagem.file.name.split(".");
        var length = split.length;
        var pdf_laudo = split[length - 1];
        objImagem.tipoPdfLaudo = pdf_laudo;
        objImagem.documento = URL.createObjectURL(element);
        state.paciente.documentosOrganizados.LAUDO.push(objImagem);
      }
    }
    async function removerLaudo(i) {
      if (state.paciente.documentosOrganizados.LAUDO[i].id != undefined) {
        state.imagens_deletar.push(
          state.paciente.documentosOrganizados.LAUDO[i].id
        );
      }
      state.paciente.documentosOrganizados.LAUDO.splice(i, 1);
      document.querySelector('input[type="file"]').value = null;
    }
    //add e remover laudos
    async function adicionarDocsResponsavel(event) {
      for (let index = 0; index < event.target.files.length; index++) {
        const element = event.target.files[index];
        var objImagem = new Object();
        objImagem.id = index + 1;
        objImagem.file = element;
        var split = objImagem.file.name.split(".");
        var length = split.length;
        var pdf_responsavel = split[length - 1];
        objImagem.tipoPdfDocResponsavel = pdf_responsavel;
        objImagem.documento = URL.createObjectURL(element);
        console.log(state.paciente.documentosOrganizados.DOC_RESPONSAVEL);
        state.paciente.documentosOrganizados.DOC_RESPONSAVEL.push(objImagem);
      }
    }
    async function removerDocResponsavel(i) {
      if (
        state.paciente.documentosOrganizados.DOC_RESPONSAVEL[i].id != undefined
      ) {
        state.imagens_deletar.push(
          state.paciente.documentosOrganizados.DOC_RESPONSAVEL[i].id
        );
      }
      console.log(state.imagens_deletar);
      state.paciente.documentosOrganizados.DOC_RESPONSAVEL.splice(i, 1);
      document.querySelector('input[type="file"]').value = null;
    }
    //add e remover laudos
    async function adicionarDocs(event) {
      for (let index = 0; index < event.target.files.length; index++) {
        const element = event.target.files[index];
        var objImagem = new Object();
        objImagem.id = index + 1;
        objImagem.file = element;
        var split = objImagem.file.name.split(".");
        var length = split.length;
        var pdf_doc = split[length - 1];
        objImagem.tipoPdfDoc = pdf_doc;
        objImagem.documento = URL.createObjectURL(element);
        state.paciente.documentosOrganizados.DOC_PACIENTE.push(objImagem);
      }
    }
    async function removerDoc(i) {
      if (
        state.paciente.documentosOrganizados.DOC_PACIENTE[i].id != undefined
      ) {
        state.imagens_deletar.push(
          state.paciente.documentosOrganizados.DOC_PACIENTE[i].id
        );
      }
      console.log(state.imagens_deletar);
      state.paciente.documentosOrganizados.DOC_PACIENTE.splice(i, 1);
      document.querySelector('input[type="file"]').value = null;
    }
    return {
      upPaciente,
      removerComprovante,
      adicionarComprovante,
      adicionarReceitas,
      removerReceita,
      adicionarLaudos,
      removerLaudo,
      adicionarDocsResponsavel,
      removerDocResponsavel,
      adicionarDocs,
      removerDoc,
      state,
      cepAtributes,
    };
  },
};
</script>
<style scoped>
.header_gradiant {
  background: linear-gradient(50deg,
      rgb(82, 181, 174) 0%,
      rgb(10, 36, 35) 100%,
      rgb(22, 82, 78)) !important;
}

h2 {
  color: black;
  font-size: 18pt;
  font-weight: 700;
}

.btn_cancelar {
  background-color: rgb(255, 255, 255);
  color: rgb(10, 36, 35);
  border: solid 3px rgb(10, 36, 35);
  height: 50px;
  transition: 0.2s;
  margin-right: 15px;
  width: 20%;
  font-size: 11pt;
}

.btn_cancelar:hover {
  background-color: rgb(10, 36, 35);
  border: solid 3px rgb(10, 36, 35);
  color: #ffffff;
  height: 50px;
  transform: none;
}

.btn_salvar {
  background-color: rgb(255, 255, 255);
  border: solid 3px rgb(40, 149, 146);
  color: rgb(40, 149, 146);
  height: 50px;
  transition: 0.3s;
  width: 15%;
  font-size: 11pt;
}

.btn_salvar:hover {
  background-color: rgb(40, 149, 146);
  border: solid 3px rgb(40, 149, 146);
  color: rgb(0, 0, 0);
  height: 50px;
  transition: 0.3s;
  transform: none;
}

.div_btn {
  text-align: right;
}

.form-outro {
  color: #000;
  border-top: none;
  border-right: none;
  border-left: none;
  border-bottom: solid 2px rgb(0, 0, 0);
  width: 100%;
  font-size: 12pt;
}

.form-outro:focus {
  outline: 0;
  color: #000;
  border-top: none;
  border-right: none;
  border-left: none;
  border-bottom: solid 2px rgb(40, 149, 146);
  width: 100%;
  font-size: 12pt;
}

.form-control {
  color: #000;
  border: solid 2px rgb(10, 36, 35);
  border-color: rgb(10, 36, 35);
  padding-top: 22px;
  padding-bottom: 22px;
  font-size: 12pt;
}

.form-control:focus {
  color: #000;
  border: solid 2px rgb(40, 149, 146);
  border-color: rgb(40, 149, 146);
}

select {
  color: #000;
  background-color: #fff;
  border: solid 2px rgb(10, 36, 35);
  border-color: rgb(10, 36, 35);
  font-size: 12pt;
  border-radius: 0.375rem;
  height: calc(1.5em + 1.25rem + 4px);
  padding: 0.625rem 0.75rem;
  width: 100%;
}

select:focus {
  color: #000;
  border: solid 2px rgb(40, 149, 146);
  border-color: rgb(40, 149, 146);
  font-size: 12pt;
}

label {
  color: #000;
  margin-bottom: 0;
  font-size: 11pt;
}

.div_btns {
  width: 100%;
  text-align: right;
}

.input_add_img {
  color: #000;
  border: solid 2px rgb(151, 110, 96);
  border-color: rgb(151, 110, 96);
  height: 150px;
  margin-bottom: 15px;
}

.input_add_img:focus {
  border: solid 2px rgb(0, 0, 0);
  border-color: rgb(0, 0, 0);
}

a {
  color: rgb(82, 181, 174);
}

a:hover {
  color: rgb(22, 82, 78);
}

svg {
  width: 50px;
  height: 50px;
  color: #000;
}

.quad_pdf {
  border: solid 2px #000;
  border-radius: 10px;
  margin-bottom: 40px;
  text-align: center;
  padding: 15px;
  width: 100%;
}

.p_pdf {
  color: #000;
  font-weight: 700;
}

.img_documento {
  width: 80px;
}

.input-image {
  padding-top: 10px;
  padding-bottom: 10px;
}

.imagem-input {
  width: 200px;
  height: auto;
}

.btn_deletar_imagem {
  margin-left: 1rem;
  font-size: 14pt;
  background: red;
  text-align: center;
  border: solid 2px #000;
  width: 30px;
  height: 30px;
  border-radius: 100%;
  transition: 0.2s;
}

.btn_deletar_imagem:hover {
  color: #ffffff;
}

.form-check {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  height: 30px;
  /* Defina uma altura uniforme para todos os elementos */
}

.form-check label {
  margin-left: 8px;
  /* Ajuste o valor conforme necessário */
  line-height: 1;
  flex: 1;
  /* Permite que o label ocupe o espaço restante */
  display: flex;
  align-items: center;
  height: 100%;
  /* Garante que o label tenha a mesma altura que o container */
}

.form-check input[type="checkbox"] {
  width: 16px;
  /* Ajuste o tamanho conforme necessário */
  height: 16px;
  accent-color: green;
  /* Para navegadores que suportam accent-color */
}

.form-check input[type="checkbox"]::before {
  border-radius: 3px;
  /* Deixa os cantos arredondados */
}

.form-check input[type="checkbox"]:checked {
  background-color: green;
  /* Para navegadores que não suportam accent-color */
}
</style>
<template>
  <section id="curso_selecionado">
    <div class="container">
      <div>
        <div class="row">
          <div class="col-md-6">
            <p class="title_curso">CADASTRO REALIZADO</p>
          </div>
        </div>
      </div>
    </div>
    <div class="container-fluid">
      <div class="row">
        <div class="col-md-8 offset-md-2">
          <div
            class="text_form"
            style="background-color: #fff; border-radius: 20px"
          >
            <p class="text">
              Em conclusão, o processo de cadastro foi concluído com sucesso!
            </p>
          </div>
          <div class="btn_div_home">
            <router-link to="/cadastro-entrevista-social">
              <button class="btn_inscricao">
                IR PARA ENTREVISTA PESSOAL
              </button></router-link
            >
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
import { onMounted, reactive } from "vue";
//import { useStorage } from "vue3-storage";
import { toast } from "vue3-toastify";
export default {
  setup() {
    const state = reactive({});
    //  const storage = useStorage();
    onMounted(() => {
      toast.success("Cadastro Realizado!!!", {
        autoClose: 4000,
      });
    });
    async function atendimento() {
      window.open(
        "https://api.whatsapp.com/send/?phone=5544999490034&text&type=phone_number&app_absent=0",
        "_blank"
      );
    }
    return {
      state,
      atendimento,
    };
  },
};
</script>

<style scoped>
h1,
h2,
h3,
h4,
h5,
h6,
p,
span {
  font-family: Yanone;
  font-weight: 100;

}
#curso_selecionado {
  background-color: rgb(22, 82, 78);
  height: 100vh;
}
.title_curso {
  color: #feb715;
  font-size: 25pt;
  padding-top: 20px;
  margin-bottom: 5rem;
}
.btn_inscricao {
  width: 300px;
  background: #1f6e69;
  color: #fff;
  font-size: 16pt;
  height: 50px;
  border: solid 2px #fff;
  border-radius: 12px;
  transition: 0.4s;
  margin-top: 5px;
  font-family: Yanone;
  font-weight: 100;
}
.btn_inscricao:hover {
  width: 320px;
  color: #feb715;
  border: solid 2px #feb715;
}
.text_form {
  padding: 40px;
  text-align: justify;
}
.text {
  color: #16524e;
  font-size: 18pt;
  line-height: 1.5;
}

.btn_div_home {
  text-align: center;
  margin-top: 5px;
  margin-bottom: 1rem;
}
@media (min-width: 992px) and (max-width: 1200px) {
  .div_one {
    display: none;
  }
  .img_one {
    display: none;
  }
  .img_two {
    display: none;
  }
  .div_two {
    display: none;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .div_one {
    display: none;
  }
  .img_one {
    display: none;
  }
  .img_two {
    display: none;
  }
  .div_two {
    display: none;
  }
}
@media (min-width: 576px) and (max-width: 767px) {
  .div_one {
    display: none;
  }
  .img_one {
    display: none;
  }
  .div_two {
    margin-top: 1rem;
  }
  .img_two {
    width: 50%;
  }
}
@media (max-width: 575px) {
  .title_curso {
    font-size: 20pt;
    margin-bottom: 5px;
  }
  .div_one {
    display: none;
  }
  .img_one {
    display: none;
  }
  .text {
    font-size: 11pt;
  }
  .text_form {
    padding: 20px;
  }
  .btn_inscricao {
    width: 90%;
  }
  .btn_inscricao:hover {
    width: 100%;
  }
  .div_two {
    margin-top: 1rem;
  }
  .img_two {
    width: 100%;
  }
}
</style>
<template>
  <div>
    <base-header
      type="gradient-success"
      class="pb-6 pb-8 pt-5 pt-md-8 header_gradiant"
    >
      <!-- Card stats -->
      <!--<  <div class="row">
              div class="col-xl-3 col-lg-6">
                <card class="card-stats">
                  <div class="quad">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="currentColor"
                      class="bi bi-bank"
                      viewBox="0 0 16 16"
                    >
                      <path
                        d="m8 0 6.61 3h.89a.5.5 0 0 1 .5.5v2a.5.5 0 0 1-.5.5H15v7a.5.5 0 0 1 .485.38l.5 2a.498.498 0 0 1-.485.62H.5a.498.498 0 0 1-.485-.62l.5-2A.501.501 0 0 1 1 13V6H.5a.5.5 0 0 1-.5-.5v-2A.5.5 0 0 1 .5 3h.89L8 0ZM3.777 3h8.447L8 1 3.777 3ZM2 6v7h1V6H2Zm2 0v7h2.5V6H4Zm3.5 0v7h1V6h-1Zm2 0v7H12V6H9.5ZM13 6v7h1V6h-1Zm2-1V4H1v1h14Zm-.39 9H1.39l-.25 1h13.72l-.25-1Z"
                      />
                    </svg>
                  </div>
                  <p class="title2">Locais</p>
                  <div class="num"></div>
                </card>
              </div>
            </div>-->
    </base-header>

    <div class="container-fluid mt--7">
      <div class="row">
        <div class="col">
          <div class="table-responsive" style="background-color: #fff">
            <table
              class="table tablesorter table align-items-center table-flush"
            >
              <thead class="thead-light" style="border: solid 5px white">
                <th>Código</th>
                <th>Nome</th>
                <th>Especialidade</th>
                <th>Área</th>
                <th style="text-align: center;">Ação</th>
              </thead>
              <tbody class="list">
                <corpo-tabela-medico
                  v-for="medico in state.medicos"
                  :key="medico.id"
                  :medico="medico"
                  @deleteProfissional="deleteProfissional"
                />
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
  <script>
import { onMounted, reactive } from "vue";
import CorpoTabelaMedico from "../../components/CorpoTabelaMedico";
import services from "../../services";
import { useStorage } from "vue3-storage";
export default {
  components: {
    CorpoTabelaMedico,
  },
  setup() {
    const storage = useStorage();
    var token = storage.getStorageSync("token");
    const state = reactive({
      medicos: [],
    });
    onMounted(() => {
      fetchMedicos();
    });
    async function fetchMedicos() {
      try {
        const { data } = await services.cooperadores.getAll({ token });
        console.log(data);
        state.medicos = data;
      } catch (error) {
        console.log(error);
      }
    }
      async function buscar() {
        var data = await services.medicos.buscar({
          codigo: state.codigo_busca,
          nome: state.nome_busca,
          token,
        });
        state.clientes = data.data;
      }
    async function deleteProfissional(id) {
      if (confirm("Deseja remover este profissional?")) {
        try {
          const { data } = await services.medicos.delete({
            id: id,
            token,
          });
          if (data.status != 200) {
            alert("Erro ao deletar profissional.");
          } else {
            fetchMedicos();
          }
        } catch (error) {
          console.log(error);
        }
      }
    }
    return {
      state,
      buscar,
      deleteProfissional,
    };
  },
};
</script>
<style scoped>
.header_gradiant {
  background: linear-gradient(
    50deg,
    rgb(82, 181, 174) 0%,
    rgb(10, 36, 35) 100%,
    rgb(22, 82, 78)
  ) !important;
}
input {
  color: #000;
  border: solid 2px rgb(10, 36, 35);
  border-color: rgb(10, 36, 35);
  padding-top: 22px;
  padding-bottom: 22px;
  font-size: 12pt;
}
input:focus {
  color: #000;
  border: solid 2px rgb(40, 149, 146);
  border-color: rgb(40, 149, 146);
}
label {
  color: #000;
  margin-bottom: 0;
  font-size: 14pt;
}
.div_btn_busca {
  margin-top: 30px;
}
.btn_busca {
  background-color: transparent;
  border: solid 2px rgb(10, 36, 35);
  border-color: rgb(10, 36, 35);
  border-radius: 5px;
  width: 50%;
  height: 45px;
  padding: 5px;
  transition: 0.4s;
  color: rgb(10, 36, 35);
}
.btn_busca:hover {
  background-color: rgb(10, 36, 35);
  color: rgb(255, 255, 255);
  transform: none;
}
svg {
  height: 20px;
  transition: 0.4s;
  width: 100%;
}
svg:hover {
  height: 30px;
  width: 100%;
}
.div_btn_cliente {
  margin-top: 30px;
  text-align: right;
}
th {
  background: rgba(181, 181, 181, 0.744) !important;
  color: #000 !important;
}
.btn_add_cliente {
  background-color: transparent;
  border: solid 2px rgb(10, 36, 35);
  border-color: rgb(10, 36, 35);
  border-radius: 5px;
  width: 90%;
  height: 45px;
  padding: 5px;
  transition: 0.4s;
  color: rgb(10, 36, 35);
  font-size: 11pt;
  line-height: 0;
}
.btn_add_cliente:hover {
  background-color: rgb(10, 36, 35);
  color: rgb(255, 255, 255);
  transform: none;
  font-size: 12pt;
  line-height: 0;
}
</style>
        
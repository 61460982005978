export default httpClient => ({
  getAll: async ({ token }) => {
    const headers = {
      "Authorization": "Bearer " + token
    }
    const response = await httpClient.get('/med', { headers });
    return { data: response.data }
  },

  ativos: async () => {
    const response = await httpClient.get('/medicos-ativos');
    return { data: response.data }
  },

  obterPorId: async ({ id, token }) => {
    const headers = {
      "Authorization": "Bearer " + token
    }
    const response = await httpClient.get('/med/' + id, { headers });
    return { data: response.data }
  },

  save: async (dados, token) => {
    const headers = { "Authorization": "Bearer " + token, "Accept": "application/json", "Content-Type": "multipart/form-data" }
    const response = await httpClient.post('/med', dados, { headers });
    return {
      data: response.data
    }
  },
    update: async ({ id, token, formMedico }) => {
        let headers = { "Authorization": "Bearer " + token, "Accept": "application/json", "Content-Type": "multipart/form-data" }
        const response = await httpClient.post('/med/' + id, formMedico, { headers })
        return {
            data: response.data,
        }
    },

  delete: async ({ id, token }) => {
    const headers = {
      "Authorization": "Bearer " + token
    }
    const response = await httpClient.delete('/medicos/' + id, { headers })
    return { data: response }
  },
})

export default httpClient => ({
  cadastrarPaciente: async (formPaciente) => {
    const response = await httpClient.post('/criar-pet-paciente', formPaciente)
    return {
      data: response.data,
    }
  },
  cadastrarProfissional: async (formProfissional) => {
    const response = await httpClient.post('/criar-mpp', formProfissional)
    return {
      data: response.data,
    }
  },
  saveEntrevista: async (hash, dados) => {
    const response = await httpClient.post('/entrevista-social/' + hash, dados);
    return { data: response.data }
  },
  aprovaCadastro: async ({ id, metodo }) => {
    const response = await httpClient.post('/aprova-cadastro/' + id,{ metodo });
    return { data: response.data }
  },
  cancelaCadastro: async (id) => {
    const response = await httpClient.get('/cancela-cadastro/' + id);
    return { data: response.data }
  },
  //-------INFO-SISTEMA
  getAll: async ({ token }) => {
    const headers = {
      "Authorization": "Bearer " + token
    }
    const response = await httpClient.get('/infos-sistema', { headers });
    return { data: response.data }
  },
  buscaData: async ({ data_inicial, data_final, token, }) => {
    const headers = {
      "Authorization": "Bearer " + token
    }
    const response = await httpClient.post('/infos-sistema', {
      data_inicial, data_final
    }, { headers })
    return {
      data: response.data,
    }
  },
  buscaPacientes: async ({ token }) => {
    const headers = {
      "Authorization": "Bearer " + token
    }
    const response = await httpClient.get('/pacientes-ultimos-seis-meses', { headers });
    return { data: response.data }
  },

  buscaPorGenero: async ({ token }) => {
    const headers = {
      "Authorization": "Bearer " + token
    }
    const response = await httpClient.get('/admin/infoGraficoGenero', { headers });
    return { data: response.data }
  },

    buscaPorPatologia: async ({ token }) => {
    const headers = {
      "Authorization": "Bearer " + token
    }
    const response = await httpClient.get('/patologia-grafico', { headers });
    return { data: response.data }
  },
    
  buscaPorTipoOleo: async ({ token }) => {
    const headers = {
      "Authorization": "Bearer " + token
    }
    const response = await httpClient.get('/oleo-grafico', { headers });
    return { data: response.data }
  },

  buscaPorMedicos: async ({ token }) => {
    const headers = {
      "Authorization": "Bearer " + token
    }
    const response = await httpClient.get('/pacientes-por-medicos', { headers });
    return { data: response.data }
  },

  buscaPorEstados: async ({ token }) => {
    const headers = {
      "Authorization": "Bearer " + token
    }
    const response = await httpClient.get('/pacientes-por-estados', { headers });
    return { data: response.data }
  },

  buscaPorIdade: async ({ token }) => {
    const headers = {
      "Authorization": "Bearer " + token
    }
    const response = await httpClient.get('/paciente-por-idade', { headers });
    return { data: response.data }
  },


  saveDocsPacientes: async (formPaciente) => {
    const headers = {
      "Content-Type": "multipart/form-data"
    }
    const response = await httpClient.post('/documentos-pacientes', formPaciente,{ headers })
    return {
      data: response.data,
    }
  },
  
  saveAnexos: async (formPaciente) => {
    const headers = {
      "Content-Type": "multipart/form-data"
    }
    const response = await httpClient.post('/salvar-anexos', formPaciente,{ headers })
    return {
      data: response.data,
    }
  },
})

export default httpClient => ({
    getAll: async ({ token }) => {
        const headers = {
            "Authorization": "Bearer " + token
        }
        const response = await httpClient.get('/usuarios', { headers });
        return { data: response.data }
    },

    obterPorId: async ({ id, token }) => {
        const headers = {
            "Authorization": "Bearer " + token
        }
        const response = await httpClient.get('/usuarios/' + id, { headers });
        return { data: response.data }
    },
    save: async ({ id,
        token,
        name,
        email,
        password
        }) => {
        const headers = {
            "Authorization": "Bearer " + token
        }
        const response = await httpClient.post('/usuarios', {
            id,
            name,
            email,
            password
        }, { headers })
        return {
            data: response.data,
        }
    },
    update: async ({ id,
        token,
        name,
        email,
        password
        }) => {
        const headers = {
            "Authorization": "Bearer " + token
        }
        const response = await httpClient.patch('/usuarios/' + id, {
            id,
            name,
            email,
            password
        }, { headers })
        return {
            data: response.data,
        }
    },
    delete: async ({ id, token }) => {
        const headers = {
            "Authorization": "Bearer " + token
        }
        const response = await httpClient.delete('/usuarios/' + id, { headers })
        return { data: response }
    },
})

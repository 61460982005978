<template>
  <div>
    <section id="login">
      <div class="container">
        <div class="row">
          <div class="col-md-10 offset-1">
            <div class="div_form">
              <h1 class="title-login">APRACAM</h1>
              <form>
                <div class="row">
                  <div class="col-md-6">
                    <h2 class="bemvindo">Bem Vindo Usuário</h2>
                  </div>
                  <div class="col-md-6"><h1 class="login_h2">Login</h1></div>
                </div>
                <div class="form-group">
                  <label for="email">EMAIL</label>
                  <input
                    type="email"
                    id="email"
                    placeholder="Digite seu email:"
                    v-model="state.email"
                  />
                </div>
                <div class="form-group">
                  <label for="senha">SENHA</label>
                  <input
                    type="password"
                    id="senha"
                    placeholder="Digite sua senha:"
                    v-model="state.senha"
                  />
                </div>
                <div class="p-3 mb-2 bg-warning text-white" v-if="state.erro">Login ou Senha incorretos!</div>
                <div style="text-align: center">
                  <button type="button" @click="logar" class="btn btn_logar">
                    Login
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>
<script>
import { reactive } from "vue";
import services from "../../services";
//import { useField } from "vee-validate";
import { useRouter } from "vue-router";
import { useStorage } from "vue3-storage";
export default {
  setup() {
    const state = reactive({
      erro: false
    });
    const router = useRouter();
    const storage = useStorage();
    async function logar() {
      //try {
        services.login.logarSistema({
          email: state.email,
          senha: state.senha,
        }).then((data) => {
          if (data) {
            storage.setStorageSync("token", data.data.token);
            storage.setStorageSync("nome", data.data.nome);
            router.push("inicio");
          }
          else {
            state.erro = true;
          }
        })
        .catch(() => {
          state.erro = true;
        });
/*
        console.log(data.token);
        if (data) {
          //window.localStorage.setItem('token', data.jwt)
          storage.setStorageSync("token", data.token);
          //window.localStorage.setItem('nome_pessoa', data.nome_pessoa)
          storage.setStorageSync("nome", data.nome);
          //window.localStorage.setItem('colaborador_id', data.funcionario_id)
          router.push("inicio");
        }*/

    }
    return {
      state,
      logar,
    };
  },
};
</script>
<style scoped>
#login {
  background-color: rgb(22, 82, 78);
  width: 100%;
  height: 100vh;
}
.img_one {
  width: 100%;
}
.img_two {
  width: 100%;
}
.btn_logar {
}
form {
  padding: 20px;
  background: rgba(255, 255, 255, 0.805);
  border: solid 2px #000000;
  border-radius: 10px;
}
label {
  margin-bottom: 0;
  color: #000000;
  font-weight: 500;
}
.btn_logar {
  width: 50%;
  height: 55px;
  background: #feb715;
  border: 1px solid #feb715;
  color: rgb(0, 0, 0);
  transition: 0.3s;
}
.btn_logar:hover {
  background: #feb715;
  border: 1px solid rgb(0, 0, 0);
  color: rgb(0, 0, 0);
  transform: none;
}
.div_form {
  margin-top: 50px;
  margin-bottom: 50px;
}
input {
  background: transparent;
  width: 100%;
  border: 1px solid #000000;
  color: rgb(0, 0, 0);
  border-radius: 5px;
  padding: 10px;
  outline: none;
}
input:focus {
  background: transparent;
  border: 1px solid #000000;
}
.div_img {
  margin-top: 70px;
  margin-bottom: 50px;
}
.bemvindo {
  color: #000000;
}
.login_h2 {
  text-align: right;
  color: #000000;
}
.title-login {
  color: #feb715;
  text-align: center;
}
</style>
<template>
  <div>
    <div class="d-flex d-sm-none">
      <TopCell />
    </div>
    <section id="curso_selecionado">
      <header>
        <div class="container">
          <div>
            <div class="row">
              <MenuMobileDois />
              <div class="col-md-6">
                <h1 class="title_curso" v-if="state.tipo_pagina == 'contribuicao'" >ADESÃO</h1>
                <h1 class="title_curso" v-else >APOIADOR</h1>
                <div class="d-flex d-sm-none div_nome_curso">
                  <img
                    src="/img/icon_apracam/img_cursos.png"
                    class="icon_cel"
                  />
                  <div class="titulo_cel">
                    FORMULARIO DE CADASTRO APRACAM ASSOCIAÇÃO PARANAENSE DE
                    APOIO A CANNABIS MEDICINAL
                  </div>
                </div>
              </div>
              <div class="col-md-6" style="text-align: right">
                <a href="/">
                  <img class="logoQuatro" src="../../../public/img/logo.png"
                /></a>
              </div>
            </div>
          </div>
        </div>
      </header>
      <div>
        <div class="container">
          <div class="row">
            <div class="col-md-4">
              <div class="div_form">
                <form>
                  <p class="contribuir">ESCOLHA A FORMA DE CONTRIBUIR</p>
                  <div class="form-group">
                    <label class="radio-container"
                      >ANUALIDADE R$ 240,00
                      <input
                        type="radio"
                        name="anualidade"
                        value="anualidade"
                        v-model="state.metodo"
                      />
                      <span class="checkmark"></span>
                    </label>
                  </div>
                  <div class="form-group">
                    <label class="radio-container"
                      >MENSAL R$ 20,00
                      <input
                        type="radio"
                        name="mensal"
                        value="mensal"
                        v-model="state.metodo"
                      />
                      <span class="checkmark"></span>
                    </label>
                  </div>
                  <div
                    class="form-group"
                    v-if="state.tipo_pagina == 'contribuicao'"
                  >
                    <label class="radio-container"
                      >NÃO CONTRIBUINTE
                      <input
                        type="radio"
                        name="nao_contribuinte"
                        value="nao_contribuinte"
                        v-model="state.metodo"
                      />
                      <span class="checkmark"></span>
                    </label>
                  </div>
                  <div
                    class="form-group"
                    v-if="state.tipo_pagina == 'contribuicao'"
                  >
                    <label class="radio-container"
                      >COTA SOCIAL
                      <input
                        type="radio"
                        name="cota"
                        value="cota"
                        v-model="state.metodo"
                      />
                      <span class="checkmark"></span>
                    </label>
                  </div>
                  <div class="btn_div">
                    <button
                      @click="agradecimento"
                      type="button"
                      class="btn_inscricao"
                    >
                      PRÓXIMO
                    </button>
                  </div>
                </form>
              </div>
            </div>
            <div class="col-md-3 offset-md-1">
              <div class="text_form">
                <h1 class="form_text">
                  FORMULÁRIO DE CADASTRO APRACAM ASSOCIAÇÃO PARANAENSE DE APOIO
                  A CANNABIS MEDICINAL
                </h1>
              </div>
            </div>
            <div class="col-md-4">
              <div class="div_img">
                <img src="../../../public/img/icon_apracam/heart_hand.png" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>
<script>
import MenuMobileDois from "../../components/MenuMobileDois.vue";
import TopCell from "../../components/TopCell.vue";
import { reactive, onMounted } from "vue";
import { useRouter } from "vue-router";
import { useStorage } from "vue3-storage";
import { toast } from "vue3-toastify";
import "vue3-toastify/dist/index.css";
export default {
  setup() {
    const router = useRouter();
    const storage = useStorage();
    const state = reactive({
      metodo: "",
    });
    onMounted(() => {
      state.tipo_pagina = router.currentRoute._value.params.tipo_pagina;
      /*
      if (state.tipo_pagina == "contribuicao") {
        toast.success("Etapa Concluída!!!", {
          autoClose: 4000,
        });
      }
      */

    });
    async function agradecimento() {
      if (
        state.metodo == null ||
        state.metodo == undefined ||
        state.metodo == ""
      ) {
        toast.warning("Insira um método de contribuição", {
          autoClose: 2000,
        });
        return;
      }
      if (state.tipo_pagina != "contribuicao") {
        storage.setStorageSync("metodo", state.metodo);
        router.push({ name: "agradecimento" });
      } else {
        if (state.metodo != "cota") {
          storage.setStorageSync("metodo", state.metodo);
          router.push({ name: "TermosDeUso" });
        } else {
          storage.setStorageSync("metodo", state.metodo);
          router.push({ name: "EntrevistaSocial" });
        }
      }
    }
    return {
      state,
      agradecimento,
      MenuMobileDois,
      TopCell,
    };
  },
};
</script>
<style>
@import url("../../site.css");
</style>
<style scoped>
h1,
h2,
h3,
h4,
h5,
h6,
p,
span {
  font-family: Yanone;
  font-weight: 100;
}
header {
  background-color: rgb(22, 82, 78);
  padding-bottom: 50px;
  height: 90px;
}
#curso_selecionado {
  background: #fff;
  height: 100vh;
}
.title_curso {
  color: #ffffff;
  font-size: 30pt;
  padding-top: 15px;
}
.btn_inscricao {
  width: 150px;
  height: 50px;
  background: #1f6e69;
  color: #fff;
  border: solid 2px #fff;
  font-size: 16pt;
  border-radius: 20px;  transition: 0.4s;
  margin-top: 5px;
  font-family: Yanone;
  font-weight: 100;
}
.logoQuatro {
  position: absolute;
  margin-left: 0;
  left: auto;
  right: auto;
  z-index: 100;
  width: 20%;
}
.btn_div {
  text-align: center;
}
input {
  border: solid 2px rgb(22, 82, 78);
  color: rgb(22, 82, 78);
  border-radius: 20px;
  padding: 30px;
  padding-left: none !important;
  font-size: 12pt;
}
input:focus {
  border: solid 2px rgb(22, 82, 78);
  color: rgb(22, 82, 78);
}
input::placeholder {
  color: rgba(17, 62, 59, 0.551);
}
.div_form {
  padding: 20px;
  margin-top: 5rem;
}
.text_form {
  text-align: left;
  margin-top: 5rem;
}
.form-group {
  margin-bottom: 0;
}
.form_text {
  font-weight: 600;
  color: rgba(22, 82, 78, 0.554);
  font-size: 25pt;
  font-family: Yanone;
  font-size: 32pt;
  line-height: 1.2;
}
.contribuir {
  color: rgb(22, 82, 78);
  font-size: 24pt;
  text-align: left;
  font-family: Yanone;
  font-weight: 300;
}
.div_img {
  margin-top: 10rem;
  text-align: center;
}
.radio-container {
  display: inline-block;
  position: relative;
  padding-left: 3rem;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 22pt;
  font-family: Yanone;
}
/* Estilo personalizado para o círculo do botão de opção */
.radio-container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}
/* Estilo personalizado para o marcador do botão de opção */
.radio-container .checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 30px;
  width: 30px;
  background-color: #eee;
  border-radius: 50%;
}
/* Estilo personalizado para o marcador quando selecionado */
.radio-container input:checked ~ .checkmark {
  background-color: #16524e;
}
/* Estilo personalizado para o ponto interno do marcador */
.radio-container .checkmark::after {
  content: "";
  position: absolute;
  display: none;
}
/* Estilo personalizado para o ponto interno do marcador quando selecionado */
.radio-container input:checked ~ .checkmark::after {
  display: block;
}
/* Estilo personalizado para o ponto interno do marcador */
.radio-container .checkmark::after {
  top: 6px;
  left: 6px;
  width: 18px;
  height: 18px;
  border-radius: 50%;
  background: white;
}
label {
  color: #16524e;
  font-size: 15pt;
  font-family: Yanone;
  margin-top: 10px;
}
@media (min-width: 992px) and (max-width: 1200px) {
  .logoQuatro {
    display: none;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .logoQuatro {
    display: none;
  }
  .form_text {
    font-size: 20pt;
  }
  img {
    width: 70%;
  }
}
@media (min-width: 576px) and (max-width: 767px) {
  .div_form {
    margin-top: 1rem;
  }
  .logoQuatro {
    display: none;
  }
  .text_form {
    margin-top: 1rem;
  }
  .form_text {
    font-size: 20pt;
    text-align: center;
  }
  img {
    width: 50%;
  }
  .div_img {
    margin-top: 0;
    margin-bottom: 1rem;
  }
}
@media (max-width: 575px) {
  .icon_cel {
    margin-right: 20px;
    width: 60px;
    height: 60px;
  }
  .titulo_cel {
    font-family: Yanone;
    font-size: 14pt;
    color: #feb715;
    padding-top: 1rem;
    text-align: right;
  }
  header {
    height: auto;
  }
  .logoQuatro {
    display: none;
  }
  .div_form {
    margin-top: 1rem;
  }
  .logoQuatro {
    display: none;
  }
  .text_form {
    margin-top: 1rem;
  }
  .form_text {
    font-size: 20pt;
    text-align: center;
  }
  img {
    width: 50%;
  }
  .div_img {
    margin-top: 0;
    margin-bottom: 1rem;
  }
  #curso_selecionado {
    background-color: rgb(22, 82, 78);
    padding-bottom: 0;
    height: 20vh;
  }
  .btn_inscricao {
    width: 150px;
    height: 50px;
    background: #1f6e69;
    color: #fff;
    font-size: 13pt;
    border: solid 2px #fff;
    border-radius: 10px;
    transition: 0.4s;
    margin-top: 5px;
    font-size: 22pt;
    font-family: Yanone;
  }
}
</style>
<template>
  <modal-factory />
  <div>
    <base-header
      type="gradient-success"
      class="pb-6 pb-8 pt-5 pt-md-8 header_gradiant"
    >
    </base-header>
    <div class="container-fluid mt--7">
      <div class="row">
        <div class="col-xl-12 col-lg-12">
          <stats-card>
            <nav aria-label="breadcrumb">
              <ol class="breadcrumb">
                <li class="breadcrumb-item router_header">
                  <router-link to="/cursos">Cursos</router-link>
                </li>
                <li class="breadcrumb-item router_header">
                  <router-link to="/cadastrar-curso" class="primary"
                    >Cadastrar
                  </router-link>
                </li>
              </ol>
            </nav>
            <form>
              <h2>Informações do Curso:</h2>
              <div class="row">
                <div class="col-md-2" style="display: none">
                  <div class="form-group">
                    <label>ID</label>
                    <input
                      type="text"
                      class="form-control"
                      readonly
                      v-model="state.id"
                    />
                  </div>
                </div>

                <div class="col-md-6">
                  <div class="form-group">
                    <label>Banner do Curso</label>
                    <input
                      type="file"
                      class="form-control file_input"
                      @change="bannerCurso"
                    />
                  </div>
                </div>
                <div
                  style="display: flex"
                  class="col-md-2"
                  v-if="state.imagem.imagem"
                >
                  <img :src="state.imagem.imagem" class="img_fetch" />
                  <button
                    @click="deleteImagem"
                    type="button"
                    class="btn_deletar_imagem"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="10"
                      height="10"
                      fill="currentColor"
                      class="bi bi-x-lg"
                      viewBox="0 0 16 16"
                    >
                      <path
                        d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8 2.146 2.854Z"
                      />
                    </svg>
                  </button>
                </div>
              </div>

              <div class="row">
                <div class="col-md-6">
                  <div class="form-group">
                    <label>Nome do Curso</label>
                    <input
                      type="text"
                      class="form-control"
                      v-model="state.nome"
                    />
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group">
                    <label>Situação do Curso</label>
                    <select
                      type="text"
                      class="form-control"
                      v-model="state.status"
                    >
                      <option value="ATIVO">Ativo</option>
                      <option value="INATIVO">Inativo</option>
                    </select>
                  </div>
                </div>
                <div class="col-md-4">
                  <div class="form-group">
                    <label>Preço do Curso</label>
                    <input
                      type="text"
                      class="form-control"
                      v-model="state.preco"
                      v-mask-decimal.br="2"
                    />
                  </div>
                </div>
                <div class="col-md-12">
                  <div class="form-group">
                    <label>Descrição do Curso</label>
                    <textarea
                      class="form-control"
                      placeholder="Descrição..."
                      v-model="state.descricao"
                    ></textarea>
                  </div>
                </div>
              </div>
              <div class="div_btns">
                <router-link to="/admin/cursos">
                  <button
                    class="btn btn_cancelar"
                    id="submit-button"
                    type="button"
                  >
                    Cancelar
                  </button>
                </router-link>
                <button
                  class="btn btn_salvar"
                  id="submit-button"
                  type="button"
                  @click="salvarCurso"
                >
                  Salvar
                </button>
              </div>
            </form>
          </stats-card>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { onMounted, reactive } from "vue";
import { useRouter } from "vue-router";
import services from "../../services";
import { useStorage } from "vue3-storage";
export default {
  setup() {
    const storage = useStorage();
    const router = useRouter();
    var token = storage.getStorageSync("token");
    const state = reactive({
      id: "",
      nome: "",
      status: "ATIVO",
      descricao: "",
      imagem: {},
    });
    onMounted(() => {
      console.log(router.currentRoute._value.params.id);
      if (router.currentRoute._value.params.id != undefined) {
        fetchCurso();
      }
    });
    async function fetchCurso() {
      try {
        const { data } = await services.cursos.obterPorId({
          id: router.currentRoute._value.params.id,
          token,
        });
        state.id = data.id;
        state.nome = data.nome;
        state.status = data.status;
        state.preco = data.preco;
        state.descricao = data.descricao;
        state.imagem.imagem = data.imagem;
      } catch (error) {
        console.log(error);
      }
    }
    async function bannerCurso(event) {
      const element = event.target.files[0];
      var objBanner = new Object();
      //   objImagem.id = 0 + 1;
      objBanner.file = element;
      objBanner.imagem = URL.createObjectURL(element);
      state.imagem = objBanner;
    }
    function deleteImagem() {
      state.imagem.imagem = null;
    }
    async function salvarCurso() {
      try {
        var formCurso = new FormData();
        if (!state.id) {
          if (state.imagem == null || state.imagem == undefined) {
            alert("Por favor insira o banner do curso!");
          } else {
            formCurso.append("nome", state.nome);
            formCurso.append("status", state.status);
            formCurso.append("preco", state.preco);
            formCurso.append("descricao", state.descricao);
            formCurso.append("imagem", state.imagem.file);
            services.cursos
              .saveCurso({ token, formCurso })
              .then((data) => {
                console.log(data);
              })
              .catch((error) => {
                console.log(error);
              });
            router.push({ name: "cursos" });
          }
        } else {
          formCurso.append("nome", state.nome);
          formCurso.append("status", state.status);
          formCurso.append("preco", state.preco);
          formCurso.append("descricao", state.descricao);
          if (state.imagem.file) {
            formCurso.append("imagem", state.imagem.file);
          }
          formCurso.append("_method", "PUT");
          services.cursos
            .update({ id: state.id, token, formCurso })
            .then((data) => {
              console.log(data);
            })
            .catch((error) => {
              console.log(error);
            });
          router.push({ name: "cursos" });
        }
      } catch (error) {
        console.log(error);
      }
    }
    return {
      salvarCurso,
      state,
      bannerCurso,
      deleteImagem,
    };
  },
};
</script>
      <style scoped>
.header_gradiant {
  background: linear-gradient(
    50deg,
    rgb(82, 181, 174) 0%,
    rgb(10, 36, 35) 100%,
    rgb(22, 82, 78)
  ) !important;
}
h2 {
  color: black;
  font-size: 18pt;
  font-weight: 700;
}
.btn_cancelar {
  background-color: rgb(255, 255, 255);
  color: rgb(10, 36, 35);
  border: solid 3px rgb(10, 36, 35);
  padding: 15px;
  transition: 0.2s;
  margin-right: 15px;
  width: 20%;
  font-size: 11pt;
}
.btn_cancelar:hover {
  background-color: rgb(10, 36, 35);
  border: solid 3px rgb(10, 36, 35);

  color: #ffffff;
  padding: 15px;
  transform: none;
}
.btn_salvar {
  background-color: rgb(255, 255, 255);
  border: solid 3px rgb(40, 149, 146);
  color: rgb(40, 149, 146);
  padding: 15px;
  transition: 0.3s;
  width: 15%;
  font-size: 11pt;
}
.btn_salvar:hover {
  background-color: rgb(40, 149, 146);
  border: solid 3px rgb(40, 149, 146);
  color: rgb(0, 0, 0);
  padding: 15px;
  transition: 0.3s;
  transform: none;
}
.div_btn {
  text-align: right;
}
input {
  color: #000;
  border: solid 2px rgb(10, 36, 35);
  border-color: rgb(10, 36, 35);
  padding-top: 22px;
  padding-bottom: 22px;
  font-size: 12pt;
}
input:focus {
  color: #000;
  border: solid 2px rgb(40, 149, 146);
  border-color: rgb(40, 149, 146);
}
select {
  color: #000;
  border: solid 2px rgb(10, 36, 35);
  border-color: rgb(10, 36, 35);
  font-size: 12pt;
}
select:focus {
  color: #000;
  border: solid 2px rgb(40, 149, 146);
  border-color: rgb(40, 149, 146);
  font-size: 12pt;
}
label {
  color: #000;
  margin-bottom: 0;
  font-size: 14pt;
}
.div_btns {
  width: 100%;
  text-align: right;
}
a {
  color: rgb(82, 181, 174);
}
a:hover {
  color: rgb(22, 82, 78);
}
.file_input {
  height: 180px;
  color: #000;
  border: solid 2px rgb(10, 36, 35);
  border-color: rgb(10, 36, 35);
  padding-top: 22px;
  padding-bottom: 22px;
  font-size: 12pt;
}
.file_input:focus {
  color: #000;
  border: solid 2px rgb(40, 149, 146);
  border-color: rgb(40, 149, 146);
  font-size: 12pt;
}
textarea {
  color: #000;
  border: solid 2px rgb(10, 36, 35);
  border-color: rgb(10, 36, 35);
  padding-top: 22px;
  padding-bottom: 22px;
  font-size: 12pt;
  resize: none;
}
textarea:focus {
  color: #000;
  border: solid 2px rgb(40, 149, 146);
  border-color: rgb(40, 149, 146);
  font-size: 12pt;
}
.img_fetch {
  width: 100%;
}
.btn_deletar_imagem {
  background: rgb(255, 0, 0);
  border: solid 1px #000;
  height: 30px;
  border-radius: 5px;
  color: #fff;
  transition: 0.2s;
}
.btn_deletar_imagem:hover {
  background: rgb(255, 255, 255);
  color: #000000;
}
</style>
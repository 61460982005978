<template>
  <div>
    <base-header type="gradient-success" class="pb-6 pb-8 pt-5 pt-md-8 header_gradiant"></base-header>
    <div class="container-fluid mt--7">
      <div class="card shadow p-3" :class="type === 'dark' ? 'bg-default' : ''">
        <h2>Relatórios</h2>
        <div class="row">
          <div class="col-md-4">
            <label for="relatorio-select">Selecione um relatório</label>
            <select id="relatorio-select" class="form-select" v-model="state.relatorio"
              @change="getTable(state.relatorio)">
              <option value="PACIENTE">Paciente</option>
              <option value="MEDICO">Médico</option>
              <option value="ENTREVISTA">Entrevista</option>
            </select>
          </div>
          <div class="col-md-2 d-flex justify-content-center align-items-end">
            <button @click="generatePdf" class="btn_novo" type="button" title="Gerar PDF"
              :disabled="state.loading">Gerar PDF</button>
          </div>
        </div>
        <div>
          <!-- <div id="content" ref="contentRef">
            <div class="table-responsive mt-5">
              <table class="table table-striped" v-if="state.relatorio === 'PACIENTE'">
                <thead>
                  <tr>
                    <th scope="col">Nome</th>
                    <th scope="col">Telefone</th>
                    <th scope="col">Patologia</th>
                    <th scope="col">Data de Cadastro</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="paciente in state.pacientes" :key="paciente.id">
                    <th>{{ paciente.pessoa.nome }}</th>
                    <td>{{ paciente.pessoa.telefone }}</td>
                    <td>{{ paciente.patologia }}</td>
                    <td>{{ paciente.created_at }}</td>
                  </tr>
                </tbody>
              </table>
              <table class="table table-striped" v-if="state.relatorio === 'MEDICO'">
                <thead>
                  <tr>
                    <th scope="col">Nome</th>
                    <th scope="col">Especialidade</th>
                    <th scope="col">Telefone</th>
                    <th scope="col">Data de Cadastro</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="medico in state.medicos" :key="medico.id">
                    <th>{{ medico.pessoa.nome }}</th>
                    <td>{{ medico.formacao }}</td>
                    <td>{{ medico.pessoa.telefone }}</td>
                    <td>{{ medico.data_criacao }}</td>
                  </tr>
                </tbody>
              </table>
              <table class="table table-striped" v-if="state.relatorio === 'ENTREVISTA'">
                <thead>
                  <tr>
                    <th scope="col">Paciente</th>
                    <th scope="col">Status</th>
                    <th scope="col">Data</th>
                    <th scope="col">Email</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="entrevista in state.entrevistas" :key="entrevista.id">
                    <th>{{ entrevista.pessoa.nome }}</th>
                    <td>{{ entrevista.status }}</td>
                    <td>{{ entrevista.created_at }}</td>
                    <td>{{ entrevista.pessoa.email }}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div> -->
        </div>
      </div>
    </div>
    <div v-if="state.loading === true">
      <div class="loader"></div>
    </div>
  </div>
</template>
<script>
import { ref, reactive, onMounted } from "vue";
import services from "../../services";
import { useStorage } from "vue3-storage";
import jsPDF from "jspdf";
import "jspdf-autotable";

export default {
  setup() {
    const storage = useStorage();
    const token = storage.getStorageSync("token");
    const state = reactive({
      relatorio: "",
      pacientes: [],
      medicos: [],
      entrevistas: [],
      loading: false,
    });

    const contentRef = ref(null);

    onMounted(() => {
    });

    async function getTable(relatorio) {
      if (relatorio === "PACIENTE") {
        await fetchPacientes();
      } else if (relatorio === "MEDICO") {
        await fetchMedicos();
      } else if (relatorio === "ENTREVISTA") {
        await fetchEntrevistas();
      }
    }

    async function fetchPacientes() {
      state.loading = true;
      try {
        const { data } = await services.paciente.getAll({ token });
        state.pacientes = data;
        state.pacientes.forEach((paciente) => {
          paciente.created_at = formatDate(paciente.created_at);
        })
      } catch (error) {
        console.error(error);
      }
      state.loading = false;

    }

    async function fetchMedicos() {
      state.loading = true;

      try {
        const { data } = await services.medicos.getAll({ token });
        state.medicos = data;
      } catch (error) {
        console.error(error);
      }
      state.loading = false;

    }

    async function fetchEntrevistas() {
      state.loading = true;
      try {
        const { data } = await services.paciente.getEntrevista({ token });
        state.entrevistas = data;
        state.entrevistas.forEach((entrevista) => {
          entrevista.created_at = formatDate(entrevista.created_at);
        })
      } catch (error) {
        console.error(error);
      }
      state.loading = false;
    }
    function formatDate(dateString) {
      const options = { day: '2-digit', month: '2-digit', year: 'numeric' };
      const date = new Date(dateString);
      return date.toLocaleDateString('pt-BR', options);
    }

    function generatePdf() {
      state.loading = true;

      const doc = new jsPDF({ orientation: "portrait" });
      const columns = [];
      const rows = [];

      if (state.relatorio === "PACIENTE") {
        columns.push("Nome", "Telefone", "Patologia", "Data de Cadastro");
        state.pacientes.forEach(paciente => {
          const temp = [
            paciente.pessoa.nome,
            paciente.pessoa.telefone,
            paciente.patologia,
            paciente.created_at
          ];
          rows.push(temp);
        });
      } else if (state.relatorio === "MEDICO") {
        columns.push("Nome", "Especialidade", "Telefone", "Data de Cadastro");
        state.medicos.forEach(medico => {
          const temp = [
            medico.pessoa.nome,
            medico.formacao,
            medico.pessoa.telefone,
            medico.data_criacao
          ];
          rows.push(temp);
        });
      } else if (state.relatorio === "ENTREVISTA") {
        columns.push("Paciente", "Status", "Data", "Email");
        state.entrevistas.forEach(entrevista => {
          const temp = [
            entrevista.pessoa.nome,
            entrevista.status,
            entrevista.created_at,
            entrevista.pessoa.email
          ];
          rows.push(temp);
        });
      }

      const headerStyles = {
        fillColor: [22, 82, 78], // Cor de preenchimento do cabeçalho
        textColor: [255, 255, 255], // Cor do texto do cabeçalho
        fontStyle: 'bold', // Estilo da fonte do cabeçalho
      };

      doc.autoTable({
        head: [columns],
        body: rows,
        headStyles: headerStyles, // Estilos do cabeçalho
        styles: {
          fontSize: 8, // Tamanho da fonte das células
          cellPadding: 2, // Padding das células
        },
        columnStyles: {
          0: { cellWidth: 'auto' }, // Define a largura das colunas
          1: { cellWidth: 'wrap' },
          2: { cellWidth: 40 },
          3: { cellWidth: 30 }
        },
      });

      if (!state.relatorio) {
        alert("Selecione um relatório para gerar.");
      } else {
        doc.save(`relatorio_${state.relatorio.toLowerCase()}_apracam.pdf`);
      }

      state.loading = false;
    }

    return {
      state,
      getTable,
      contentRef,
      generatePdf,
    };
  },
};

</script>

<style scoped>
.header_gradiant {
  background: linear-gradient(50deg,
      rgb(82, 181, 174) 0%,
      rgb(10, 36, 35) 100%,
      rgb(22, 82, 78)) !important;
}

select {
  color: #000;
  border: solid 2px rgb(10, 36, 35);
  border-color: rgb(10, 36, 35);
  padding: 10px;
  font-size: 12pt;
  width: 100%;
  border-radius: 4px;
}

select:focus {
  color: #000;
  border: solid 2px rgb(40, 149, 146);
  border-color: rgb(40, 149, 146);
}

label {
  color: #000;
  margin-bottom: 0;
  font-size: 14pt;
}

.btn_novo {
  border-radius: 4px;
  width: 100%;
  height: 45px;
  padding: 5px;
  transition: 0.5s;
  background-color: rgb(22, 82, 78);
  border: solid 2px rgb(22, 82, 78);
  color: #fff;
}

.btn_novo:hover {
  background-color: rgb(40, 149, 146);
  border: solid 2px rgb(40, 149, 146);
}

/* Estilo do loader */
.loader {
  border: 4px solid rgb(10, 36, 35);
  border-left-color: transparent;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  animation: spin89345 1s linear infinite;
  position: fixed;
  /* Usar position: fixed para centralizar em toda a tela */
  top: 50%;
  /* Centraliza verticalmente */
  left: 50%;
  /* Centraliza horizontalmente */
  transform: translate(-50%, -50%);
  /* Ajusta a posição para alinhar ao centro */
  z-index: 9999;
  /* Garante que o loader fique na frente de outros elementos */
}

/* Animação do loader */
@keyframes spin89345 {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

@media (max-width: 600px) {
  .loader {
    width: 30px;
    height: 30px;
    border: 3px solid rgb(10, 36, 35);
  }
}
</style>

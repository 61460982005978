<template>
  <div class="d-flex d-sm-none">
    <TopCell />
  </div>
  <section id="curso_selecionado">
    <header>
      <div class="container">
        <div>
          <div class="row">
            <MenuMobileDois />
            <div class="col-md-6">
              <h1 class="title_curso">ATENDIMENTO</h1>
              <div class="d-flex d-sm-none div_nome_curso">
                <img src="/img/icon_apracam/img_cursos.png" class="icon_cel" />
                <div class="titulo_cel">
                  FORMULARIO DE CADASTRO APRACAM ASSOCIAÇÃO PARANAENSE DE APOIO
                  A CANNABIS MEDICINAL
                </div>
              </div>
            </div>
            <div class="col-md-6" style="text-align: right">
              <a href="/">
                <img class="logoQuatro" src="../../../public/img/logo.png"
              /></a>
            </div>
          </div>
        </div>
      </div>
    </header>
    <div class="container">
      <div style="background-color: #fff; border-radius: 20px">
        <div class="row">
          <div class="col-md-6 col-sm-9">
            <div class="div_form">
              <div class="form-group">
                <label class="radio-container">
                  <div class="borda">
                    <p>
                      JÁ TENHO RECEITA.<br />
                      QUERO SER ACOLHIDO.
                    </p>
                  </div>
                  <input
                    type="radio"
                    name="opcao"
                    value="sim"
                    v-model="state.associado"
                  />
                  <span class="checkmark"></span>
                </label>
              </div>
              <div class="form-group">
                <label class="radio-container">
                  <div class="borda">
                    <p>
                      NÃO TENHO RECEITA,<br />
                      QUERO SER ACOLHIDO.
                    </p>
                  </div>
                  <input
                    type="radio"
                    name="opcao"
                    value="nao"
                    v-model="state.associado"
                  />
                  <span class="checkmark"></span>
                </label>
              </div>

            </div>
          </div>
          <div class="col-md-3">
            <div class="text_form">
              <h1 class="form_text">ATENDIMENTO AO PACIENTE!</h1>
            </div>
          </div>
          <div class="col-md-3">
            <div class="div_img">
              <img src="../../../public/img/icon_apracam/medical_herba.png" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
import { reactive, onMounted } from "vue";
import { useRouter } from "vue-router";
import MenuMobileDois from "../../components/MenuMobileDois.vue";
import TopCell from "../../components/TopCell.vue";
import { useStorage } from "vue3-storage";
export default {
  setup() {
    const router = useRouter();
    const storage = useStorage();

    const state = reactive({
      associado: "",
    });
    onMounted(() => {
      if (router.currentRoute._value.params.tipo_pessoa != undefined) {
        state.tipo_pessoa = router.currentRoute._value.params.tipo_pessoa;
      }
      state.tipo_pessoa = storage.getStorageSync("pessoa");
      state.pessoa = storage.getStorageSync("pessoa");
    });
    async function prosseguir() {
      if (state.associado == "sim") {
        router.push("/cadastro/" + state.tipo_pessoa);
      } else {
        window.open(
          "https://api.whatsapp.com/send/?phone=44999409860&text&type=phone_number&app_absent=0",
          "_blank"
        );
      }
    }
    return {
      state,
      prosseguir,
      MenuMobileDois,
      TopCell,
    };
  },
};
</script>
<style scoped>
h1,
h2,
h3,
h4,
h5,
h6,
p,
span {
  font-family: Yanone;
  font-weight: 100;
}
header {
  background-color: rgb(22, 82, 78);
  padding-bottom: 50px;
  height: 90px;
}

#curso_selecionado {
  background-color: #fff;
  height: 100vh;
}
.title_curso {
  color: #ffffff;
  font-size: 25pt;
  padding-top: 20px;
}

.borda {
  padding: 5px;
  border: #16524e solid 1px;
  width: 100%;
  border-radius: 20px;
}
.borda p {
  margin-bottom: 0;
  padding-left: 1rem;
}
.checkmark {
  border: #16524e solid 1px;
  border-radius: 10px;
}

.btn_inscricao {
  width: 150px;
  height: 50px;
  background: #1f6e69;
  color: #fff;
  font-size: 16pt;
  border: solid 2px #fff;
  border-radius: 12px;
  transition: 0.4s;
  margin-top: 5px;
  font-family: Yanone;
  font-weight: 100;
}
.logoQuatro {
  position: absolute;
  margin-left: 0;
  left: auto;
  right: auto;
  z-index: 100;
  width: 20%;
}
.img_destaque_curso {
  border-radius: 20px;
}
.btn_div {
  text-align: center;
}
.div_form {
  padding: 20px;
  margin-top: 5rem;
}
.text_form {
  text-align: left;
  margin-top: 8rem;
}
.form_text {
  font-weight: 900;
  color: rgba(22, 82, 78, 0.554);
  font-size: 25pt;
  line-height: 1.2;
  text-align: center;
}
.contribuir {
  color: rgb(22, 82, 78);
  font-size: 12pt;
  text-align: center;
}
.div_img {
  margin-top: 5rem;
  text-align: center;
}
.div_img img {
  width: 100%;
}
.p_qrcode {
  color: rgb(22, 82, 78);
}
input {
  width: 30px;
}
.radio-container {
  display: inline-block;
  position: relative;
  padding-left: 4.3rem;
  background-color: #fff;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 14px;
  width: 80%;
}
/* Estilo personalizado para o círculo do botão de opção */
.radio-container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}
.radio-container span {
  font-size: 16pt;
  border-radius: 10px;
  padding: 10px;
}
/* Estilo personalizado para o marcador do botão de opção */
.radio-container .checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 60px;
  width: 60px;
  border-radius: 50%;
  background-color: #fff;
}
/* Estilo personalizado para o marcador quando selecionado */
.radio-container input:checked ~ .checkmark {
  background-color: none;
  border: solid 15px;
}
/* Estilo personalizado para o ponto interno do marcador */
.radio-container .checkmark::after {
  content: "";
  position: absolute;
  display: none;
}
/* Estilo personalizado para o ponto interno do marcador quando selecionado */
.radio-container input:checked ~ .checkmark::after {
  display: block;
}
/* Estilo personalizado para o ponto interno do marcador */
.radio-container .checkmark::after {
  top: 6px;
  left: 6px;
  width: 18px;
  height: 18px;
  border-radius: 50%;
  background: white;
}
label {
  color: #16524e;
}
@media (min-width: 992px) and (max-width: 1200px) {
  .logoQuatro {
    display: none;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .form_text {
    font-size: 20pt;
  }
  .logoQuatro {
    display: none;
  }
  img {
    width: 50%;
  }
  .div_img {
    margin-top: 8rem;
  }
  .radio-container span {
    font-size: 12pt;
    border-radius: 10px;
    padding: 10px;
  }
  .radio-container {
    width: 100%;
  }
}
@media (min-width: 576px) and (max-width: 767px) {
  .form_text {
    font-size: 20pt;
  }
  .div_form {
    margin-top: 1rem;
  }
  .logoQuatro {
    display: none;
  }
  img {
    width: 30%;
  }
  .div_img {
    margin-top: 0;
  }
  .text_form {
    margin-top: 0;
  }
}
@media (max-width: 575px) {
  .form_text {
    font-size: 20pt;
  }
  .div_form {
    margin-top: 1rem;
  }
  .logoQuatro {
    display: none;
  }
  img {
    width: 30%;
  }
  .div_img {
    margin-top: 0;
  }
  .text_form {
    margin-top: 0;
  }
  .radio-container span {
    font-size: 12pt;

    padding: 10px;
  }
  .radio-container {
    width: 100%;
  }
  .checkmark {
    border: #16524e solid 1px;
    border-radius: 10px;
  }
  span {
    width: 100%;
  }
  .icon_cel {
    margin-right: 20px;
    width: 60px;
    height: 60px;
  }
  .titulo_cel {
    font-family: Yanone;
    font-size: 14pt;
    color: #feb715;
    padding-top: 1rem;
    text-align: right;
  }
  header {
    height: auto;
  }
  .logoQuatro {
    display: none;
  }
}
</style>
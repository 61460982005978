export default httpClient => ({
    getAll: async () => {
      const response = await httpClient.get('/erros')
      return { data: response.data }
    },
    save: async ({ nome, descricao, status }) => {
      const response = await httpClient.post('/erro/adicionar',{
        nome,
        descricao,
        status
      })
      let errors = null
  
      if(!response.data){
        console.log('erroo')
        errors = {
          status: response.request.status,
          statusText: response.request.statusText
        }
      }
      return {
        data: response.data,
        errors
      }
    },
    update: async ({ id, nome,descricao, status }) => {
      const response = await httpClient.post('/erro/alterar/'+id,{
        nome,
        descricao,
        status
      })
      let errors = null
  
      if(!response.data){
        console.log('erroo')
        errors = {
          status: response.request.status,
          statusText: response.request.statusText
        }
      }
      return {
        data: response.data,
        errors
      }
    }
})
export default httpClient => ({
  getAll: async ({ token }) => {
    const headers = {
      "Authorization": "Bearer " + token
    }
    const response = await httpClient.get('/medicos', { headers });
    return { data: response.data }
  },

  obterPorId: async ({ id, token }) => {
    const headers = {
      "Authorization": "Bearer " + token
    }
    const response = await httpClient.get('/medicos/' + id, { headers });
    return { data: response.data }
  },

  update: async ({ id,
    token,
    nome,
    tipo_pessoa,
    formacao,
    email,
    telefone,
    data_nascimento,
    genero,
    proposta_parceria,
    rua,
    numero,
    bairro,
    complemento,
    cidade,
    estado, }) => {
    const headers = {
      "Authorization": "Bearer " + token
    }
    const response = await httpClient.put('/medicos/' + id, {
      id,
      nome,
      tipo_pessoa,
      formacao,
      email,
      telefone,
      data_nascimento,
      genero,
      proposta_parceria,
      rua,
      numero,
      bairro,
      complemento,
      cidade,
      estado,
    }, { headers })
    return {
      data: response.data,
    }
  },
  delete: async ({ id, token }) => {
    const headers = {
      "Authorization": "Bearer " + token
    }
    const response = await httpClient.delete('/medicos/' + id, { headers })
    return { data: response }
  },
})

<template>
  <section id="curso_selecionado">
    <header>
      <div class="container-fluid">
        <div>
          <div class="row">
            <div class="col-md-4 offset-md-1">
              <h1 class="title_curso">
                <img src="../../../public/img/icon_apracam/img_cursos.png" /> {{ state.curso.nome }}</h1>
            </div>
            <div class="col-md-4" style="text-align: right">
              <a href="/">
                <img class="logoQuatro" src="../../../public/img/logo.png"
              /></a>
            </div>
          </div>
        </div>
      </div>
    </header>
    <div class="container-fluid">
      <div style="background-color: #fff; border-radius: 20px">
        <div class="row">
          <div class="col-md-4 offset-md-1">
            <div class="div_form">
              <form>
                <div class="form-group">
                  <label for="nome">SEU NOME</label>
                  <input
                    type="text"
                    class="form-control"
                    id="nome"
                    placeholder="Ex: Maria Joana"
                    v-model="state.nome"
                  />
                </div>
                <div class="form-group">
                  <label for="email">SEU EMAIL</label>
                  <input
                    type="email"
                    class="form-control"
                    id="email"
                    placeholder="Ex: meuemail@gmail.com"
                    v-model="state.email"
                  />
                </div>
                <div class="form-group">
                  <label for="phone">TELEFONE</label>
                  <input
                    type="text"
                    class="form-control"
                    id="phone"
                    placeholder="Ex: (44) 99999-9999"
                    v-model="state.telefone"
                    v-mask-phone.br
                  />
                </div>
                <div class="form-group">
                  <label for="data">DATA DE NASCIMENTO</label>
                  <input
                    type="date"
                    class="form-control"
                    id="data"
                    v-model="state.data_nascimento"
                  />
                </div>
                <div class="form-group">
                  <h2 class="title_radio">JÁ TEM CULTIVO DE CANNABIS?</h2>
                  <label class="radio-container"
                    >SIM
                    <input type="radio" value="SIM" v-model="state.cannabis" />
                    <span class="checkmark"></span>
                  </label>
                  <label class="radio-container">
                    NÃO
                    <input type="radio" value="NAO" v-model="state.cannabis" />
                    <span class="checkmark"></span>
                  </label>
                </div>
                <div class="form-group">
                  <h2 class="title_radio">
                    TEM AUTORIZAÇÃO JUDICIAL PARA CULTIVO?
                  </h2>
                  <label class="radio-container"
                    >SIM
                    <input type="radio" value="SIM" v-model="state.judicial" />
                    <span class="checkmark"></span>
                  </label>
                  <label class="radio-container">
                    NÃO
                    <input type="radio" value="NAO" v-model="state.judicial" />
                    <span class="checkmark"></span>
                  </label>
                </div>
                <div class="btn_div">
                  <button
                    type="button"
                    class="btn_inscricao"
                    @click="finalizaInscricao"
                  >
                    CONCLUIR INSCRIÇÃO
                  </button>
                </div>
              </form>
            </div>
          </div>
          <div class="col-md-5">
            <div class="row">
              <div class="col-sm-5">
                <img class="img_curso" :src="state.curso.imagem" />
              </div>
            </div>
            <div class="row">
              <div class="col-sm-8">
                <h2 class="name_curso">
                  {{ state.curso.nome }}
                  <span class="valor_curso">R$ {{ state.curso.preco }}</span>
                </h2>
              </div>
            </div>
            <div class="row">
              <div class="col-sm-5">
                <img class="img_curso" :src="state.curso.imagem" />
              </div>
              <div class="col-sm-1">
                <img src="../../../public/img/plus.png" style="width:100%; margin-top: 200px;"/>
              </div>
              <div :class="{
                'col-sm-5': cur.id != state.curso.id,
              }"   v-for="cur in state.cursos" :key="cur.id">
                <div v-if="cur.id != state.curso.id">
                  <img class="img_curso" :src="cur.imagem"/>
                </div>
              </div>
            </div>
            <div class="div_img_curso" v-if="state.cursos.length == 0">
              <div style="text-align: center">
                <h2 class="name_curso">
                  {{ state.curso.nome }}
                  <span class="valor_curso">R$ {{ state.curso.preco }}</span>
                </h2>
              </div>
            </div>
            <div class="row">
              <div class="col-sm-6">
                <h2 class="name_curso">
                  {{ state.curso.nome }} + 
                  <span ></span>
                </h2>
                
              </div>
              <div class="col-sm-6">
                <h2 class="name_curso">
                  {{ state.curso.nome }} + 
                  <span ></span>
                </h2>
              </div>
            </div>
          </div>
          <!--
          <div class="col-md-7">
            <div class="div_cursos">
              <div class="row">
                <div class="col-md-5">
                  <img
                    class="img_curso"
                    src="../../../public/img/apracam_img/cursoUm.png"
                  />
                </div>
                <div class="col-md-2">
                  <div class="div_plus">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="currentColor"
                      class="bi bi-plus"
                      viewBox="0 0 16 16"
                    >
                      <path
                        d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z"
                      />
                    </svg>
                  </div>
                </div>
                <div class="col-md-5">
                  <img
                    class="img_curso"
                    src="../../../public/img/apracam_img/cursoDois.png"
                  />
                </div>
              </div>

              <div class="div_cursos_nome">
                <h2 class="name_curso">
                  NOME DO CURSO + NOME DO CURSO
                  <span class="valor_curso">R$ 200,00</span>
                </h2>
              </div>
            </div>
          </div>-->
        </div>
      </div>
    </div>
  </section>
</template>
<script>
import { reactive, onMounted } from "vue";
import { useRouter } from "vue-router";
//import { useStorage } from "vue3-storage";
import services from "../../services";
import { toast } from "vue3-toastify";
import "vue3-toastify/dist/index.css";

export default {
  setup() {
    //const storage = useStorage();
    const router = useRouter();
    const state = reactive({
      nome: "",
      email: "",
      telefone: "",
      data_nascimento: "",
      cannabis: "",
      judicial: "",
      curso: {},
      cursos:[]
    });
    onMounted(() => {
      router.currentRoute._value.params.id;
      if (router.currentRoute._value.params.id != undefined) {
        fetchCursos();
        fetchCurso();
      }
    });
    async function fetchCurso() {
      try {
        const { data } = await services.cursos.cursoId({
          id: router.currentRoute._value.params.id,
        });
        state.curso = data;
      } catch (error) {
        console.log(error);
      }
    }

    async function fetchCursos() {
      try {
        const { data } = await services.cursos.cursosAtivos();
        state.cursos = data;
      } catch (error) {
        console.log(error);
      }
    }


    async function finalizaInscricao() {
      /* var banner = {};
      banner.nome = state.nome;
      banner.status = state.status;
      banner.catagoria_banners_id = state.categoriaBanner_id;*/
      if (state.nome == null || state.nome == undefined || state.nome == "") {
        toast.error("Insira um nome !", {
          autoClose: 2000,
        });
        return;
      }
      if (
        state.email == null ||
        state.email == undefined ||
        state.email == ""
      ) {
        toast.error("Insira um email !", {
          autoClose: 2000,
        });
        return;
      }
      if (
        state.telefone == null ||
        state.telefone == undefined ||
        state.telefone == ""
      ) {
        toast.error("Insira um telefone !", {
          autoClose: 2000,
        });
        return;
      }
      if (
        state.data_nascimento == null ||
        state.data_nascimento == undefined ||
        state.data_nascimento == ""
      ) {
        toast.error("Insira uma data de nascimento !", {
          autoClose: 2000,
        });
        return;
      }
      var formArquivo = new FormData();
      formArquivo.append("nome", state.nome);
      formArquivo.append("email", state.email);
      formArquivo.append("telefone", state.telefone);
      formArquivo.append("data_nascimento", state.data_nascimento);
      formArquivo.append("tem_autorizacao_jud", state.judicial);
      formArquivo.append("cultiva_cannabis", state.cannabis);
      formArquivo.append("curso_id", state.curso.id);
      services.cursos.saveAluno({ formArquivo })
        .then((data) => {
          if (data != undefined && data != null) {
            router.push({ name: "pagCurso" });
          } else {
            alert("Erro ao cadastrar!");
          }
          
        })
        .catch(() => {
          alert("Erro ao cadastrar!");

        });
      
    }

    return {
      state,
      finalizaInscricao,
    };
  },
};
</script>
<style>
@import url("../../site.css");
</style>
<style scoped>
h1,
h2,
h3,
h4,
h5,
h6,
p,
span {
  font-family: Yanone;
}
header{
  background-color: rgb(22, 82, 78);
  padding-bottom: 50px;
  height: 90px
}


.title_curso {
  color: #feb715;
  font-size: 25pt;
  padding-top: 20px;
}
.title_curso img{
  width: 70px;
}


.p_ex {
  color: rgb(22, 82, 78);
  font-size: 16pt;
  line-height: 1.2;
  text-align: justify;
}
.p_li {
  color: rgb(22, 82, 78);
  font-size: 16pt;
  line-height: 1.2;
  margin-bottom: 10px;
  text-align: justify;
}
.li_curso {
  color: rgb(22, 82, 78);
  font-size: 14pt;
  line-height: 1.2;
  text-align: justify;
}
.text_left_curso {
  padding: 40px;
  margin-top: 8rem;
}

.btn_inscricao {
  width: 60%;
  background: #1f6e69;
  color: #fff;
  font-size: 13pt;
  padding: 10px;
  border: solid 2px #fff;
  border-radius: 12px;
  transition: 0.4s;
  margin-top: 5px;
}
.btn_inscricao:hover {
  width: 65%;
  color: #feb715;
  border: solid 2px #feb715;
}
.div_img_curso_destaque {
  margin-top: 10rem;
  margin-bottom: 2rem;
}
.logoQuatro {
  position: absolute;
  margin-left: 0;
  left: auto;
  right: auto;
  z-index: 100;
  width: 20%;
}
.img_destaque_curso {
  border-radius: 20px;
}
.btn_div {
  text-align: center;
}
label {
  font-size: 16pt;
  font-family: Yanone;
  font-weight: 400;
  color: rgb(22, 82, 78);
  margin-bottom: 0;
}
input, #data{
  border: solid 2px rgb(22, 82, 78);
  color: rgb(22, 82, 78);
  border-radius: 20px;
  font-family: Yanone;
  padding: 30px;
  padding-left: none !important;
  font-size: 14pt;
}
input:focus {
  border: solid 2px rgb(22, 82, 78);
  color: rgb(22, 82, 78);
}
input::placeholder {
  color: rgba(17, 62, 59, 0.551);
}
.div_form {
  padding: 20px;
  margin-top: 2rem;
}
.div_img_curso {
  padding: 20px;
}
.img_curso {
  width: 100%;
  margin-top: 3rem;
}

.name_curso {
  color: rgb(22, 82, 78);
}
.valor_curso {
  padding-left: 100px;
}
.div_cursos {
  margin-top: 2rem;
}
svg {
  width: 70px;
  height: 70px;
  color: #fff;
  background: #16524e;
  border-radius: 100%;
  transition: 0.4s;
}
svg:hover {
  color: #16524e;
  background: #fff;
}
.div_plus {
  margin-top: 10rem;
  text-align: center;
}
.div_cursos_nome {
  margin-top: 15px;
  text-align: center;
}
.radio-container {
  display: inline-block;
  position: relative;
  padding-left: 3rem;
  margin-right: 2rem;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 16px;
}
/* Estilo personalizado para o círculo do botão de opção */
.radio-container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}
/* Estilo personalizado para o marcador do botão de opção */
.radio-container .checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 30px;
  width: 30px;
  background-color: #eee;
  border-radius: 50%;
}
/* Estilo personalizado para o marcador quando selecionado */
.radio-container input:checked ~ .checkmark {
  background-color: #16524e;
}
/* Estilo personalizado para o ponto interno do marcador */
.radio-container .checkmark::after {
  content: "";
  position: absolute;
  display: none;
}
/* Estilo personalizado para o ponto interno do marcador quando selecionado */
.radio-container input:checked ~ .checkmark::after {
  display: block;
}
/* Estilo personalizado para o ponto interno do marcador */
.radio-container .checkmark::after {
  top: 6px;
  left: 6px;
  width: 18px;
  height: 18px;
  border-radius: 50%;
  background: white;
}
.title_radio {
  font-weight: 900;
  color: rgb(22, 82, 78);
  margin-bottom: 0;
}
@media (min-width: 992px) and (max-width: 1200px) {
  .logoQuatro {
    display: none;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .btn_inscricao {
    width: 90%;
    font-size: 9pt;
  }
  .btn_inscricao:hover {
    width: 95%;
  }
  .logoQuatro {
    display: none;
  }
  .title_radio {
    font-size: 11pt;
  }
}
@media (min-width: 576px) and (max-width: 767px) {
  .logoQuatro {
    display: none;
  }
  .img_curso {
    margin-top: 0;
  }
}
@media (max-width: 575px) {
  .logoQuatro {
    display: none;
  }
  .img_curso {
    margin-top: 0;
  }
  .btn_inscricao {
    width: 95%;
  }
  .btn_inscricao:hover {
    width: 100%;
  }
}
</style>
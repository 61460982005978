export default httpClient => ({
  getAll: async ({ token }) => {
    const headers = {
      "Authorization": "Bearer " + token
    }
    const response = await httpClient.get('/pet-pacientes', { headers });
    return { data: response.data }
  },
  obterPorId: async ({ id, token }) => {
    const headers = {
      "Authorization": "Bearer " + token
    }
    const response = await httpClient.get('/pet-pacientes/' + id, { headers });
    return { data: response.data }
  },
  save: async (dados, token) => {
    const headers = { "Authorization": "Bearer " + token, "Accept": "application/json", "Content-Type": "multipart/form-data" }
    const response = await httpClient.post('/admin/criar-pet-paciente', dados, { headers });
    return {
      data: response.data
    }
  },
  update: async (dados, token) => {
    const headers = { "Authorization": "Bearer " + token, "Accept": "application/json", "Content-Type": "multipart/form-data" }
    const response = await httpClient.post('/pet-pacientes/' + dados.get('id'), dados, { headers });
    return {
      data: response.data
    }
  },
  busca: async ({ token, nome, codigo }) => {
    const headers = {
      "Authorization": "Bearer " + token
    }
    const response = await httpClient.post('/admin/pesquisa-pet-paciente', {
      codigo,
      nome,
    }, { headers })
    return {
      data: response.data,
    }
  },
  delete: async ({ id, token }) => {
    const headers = {
      "Authorization": "Bearer " + token
    }
    const response = await httpClient.delete('/pet-pacientes/' + id, { headers })
    return { data: response }
  },
  petPaciente: async ({ token, tipo }) => {
    const headers = {
      "Authorization": "Bearer " + token
    }
    const response = await httpClient.get('/pet-paciente/' + tipo, { headers });
    return { data: response.data }
  },
  getEntrevista: async ({ token }) => {
    const headers = {
      "Authorization": "Bearer " + token
    }
    const response = await httpClient.get('/entrevistas-sociais', { headers });
    return { data: response.data }
  },
  getEntrevistaById: async ({ id, token }) => {
    const headers = {
      "Authorization": "Bearer " + token
    }
    const response = await httpClient.get('/entrevistas-sociais/' + id, { headers });
    return { data: response.data }
  },
  updateEntrevista: async ({
    id, token, entrevista }) => {
    const headers = {
      "Authorization": "Bearer " + token
    }
    const response = await httpClient.patch('/entrevistas-sociais/' + id, entrevista, { headers })
    return {
      data: response.data,
    }
  },
  deleteEntrevista: async ({ id, token }) => {
    const headers = {
      "Authorization": "Bearer " + token
    }
    const response = await httpClient.delete('/entrevistas-sociais/' + id, { headers })
    return { data: response }
  },
  deletarDocumentos: async ({ token, imagens_deletar }) => {
    let headers = { "Authorization": "Bearer " + token }
    const response = await httpClient.post('/excluir-documento-paciente', { imagens_deletar }, { headers })
    return { data: response }
  },
  pesquisaAvan: async (filtro, token) => {
    const headers = { "Authorization": "Bearer " + token }
    const response = await httpClient.post('/pesquisa-avancada', filtro, { headers })
    return {
      data: response.data,
    }
  },
})

<template>
  <div>
    <base-header
      type="gradient-success"
      class="pb-6 pb-8 pt-5 pt-md-8 header_gradiant"
    >
    </base-header>

    <div class="container-fluid mt--7">
      <div
        class="card shadow p-3"
        :class="type === 'dark' ? 'bg-default' : ''"
        s
      >
        <div class="row">
          <div class="col-md-2 offset-md-10 div_btn_novo">
            <div class="form-group">
              <a href="/admin/cadastrar-patologia">
                <button type="button" class="btn_novo">Cadastrar</button>
              </a>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col">
          <div class="table-responsive" style="background-color: #fff">
            <table
              class="table tablesorter table align-items-center table-flush"
            >
              <thead class="thead-light" style="border: solid 5px white">
                <th>Código</th>
                <th>Nome</th>
                <th>Status</th>
                <th style="text-align: center">Ação</th>
              </thead>
              <tbody class="list">
                <corpo-tabela-patologia
                  v-for="patologia in state.patologias"
                  :key="patologia.id"
                  :patologia="patologia"
                  @deletePatologia="deletePatologia"
                />
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { onMounted, reactive } from "vue";
import CorpoTabelaPatologia from "../../components/CorpoTabelaPatologia";
import services from "../../services";
import { useStorage } from "vue3-storage";
export default {
  components: {
    CorpoTabelaPatologia,
  },
  setup() {
    const storage = useStorage();
    var token = storage.getStorageSync("token");
    const state = reactive({
      patologias: [],
      codigo_busca: "",
      nome_busca: "",
    });
    onMounted(() => {
      fetchPatologias();
    });
    async function fetchPatologias() {
      try {
        const { data } = await services.patologia.getAll({ token });
        console.log(data);
        state.patologias = data;
      } catch (error) {
        console.log(error);
      }
    }
    async function buscar() {
      if (state.codigo_busca.length == 0 && state.nome_busca.length == 0) {
        fetchPatologias();
        return;
      }
      var data = await services.patologia.busca({
        codigo: state.codigo_busca,
        nome: state.nome_busca,
        token,
      });
      state.patologias = data.data;
    }
    async function deletePatologia(id) {
      if (confirm("Deseja remover este patologia?")) {
        try {
          const { data } = await services.patologia.delete({
            id: id,
            token,
          });
          if (data.status != 200) {
            alert("Erro ao deletar patologia.");
          } else {
            fetchPatologias();
          }
        } catch (error) {
          console.log(error);
        }
      }
    }
    return {
      state,
      deletePatologia,
      buscar,
    };
  },
};
</script>
    <style scoped>
.header_gradiant {
  background: linear-gradient(
    50deg,
    rgb(82, 181, 174) 0%,
    rgb(10, 36, 35) 100%,
    rgb(22, 82, 78)
  ) !important;
}
input {
  color: #000;
  border: solid 2px rgb(10, 36, 35);
  border-color: rgb(10, 36, 35);
  padding-top: 22px;
  padding-bottom: 22px;
  font-size: 12pt;
}
input:focus {
  color: #000;
  border: solid 2px rgb(40, 149, 146);
  border-color: rgb(40, 149, 146);
}
label {
  color: #000;
  margin-bottom: 0;
  font-size: 14pt;
}
.div_btn_busca {
  margin-top: 30px;
}
.btn_busca {
  background-color: transparent;
  border: solid 2px rgb(10, 36, 35);
  border-color: rgb(10, 36, 35);
  border-radius: 5px;
  width: 50%;
  height: 45px;
  padding: 5px;
  transition: 0.4s;
  color: rgb(10, 36, 35);
}
.btn_busca:hover {
  background-color: rgb(10, 36, 35);
  color: rgb(255, 255, 255);
  transform: none;
}
svg {
  height: 20px;
  transition: 0.4s;
  width: 100%;
}
svg:hover {
  height: 30px;
  width: 100%;
}
.div_btn_cliente {
  margin-top: 30px;
  text-align: right;
}
th {
  background: rgba(181, 181, 181, 0.744) !important;
  color: #000 !important;
}
.div_btn_novo {
  margin-top: 30px;
  text-align: right;
}
.btn_novo {
  border: solid 2px rgb(22, 82, 78);
  border-color: rgb(10, 36, 35);
  border-radius: 5px;
  width: 50%;
  height: 45px;
  padding: 5px;
  transition: 0.4s;
  background-color: rgb(22, 82, 78);
  color: #fff;
}
</style>
      

import { useStorage } from "vue3-storage";
import services from '../services';
export default {

  auth(to, from, next) {
    const storage = useStorage();
    var token = storage.getStorageSync("token");
    services.login.verificaToken(token).then(data => {
      if (data.data.token) {
        next();

      } else {
        next('/admin/login');

      }
      //  autorizado = dat
    }).catch(function () {
      next('/admin/login');
    });
    //ajax para o backend para checar a validade do token */
  },
}
<template>
  <section id="curso_selecionado">
    <div class="container">
      <div>
        <div class="row">
          <div class="col-md-6">
            <h1 class="title_curso">INSCRIÇÃO CONCLUÍDA</h1>
          </div>
          <div class="col-md-6" style="text-align: right">
            <a href="/">
              <img class="logoQuatro" src="../../../public/img/logo.png"
            /></a>
          </div>
        </div>
      </div>
    </div>
    <div class="container">
      <div style="background-color: #fff; border-radius: 20px">
        <div class="text_agradecimentos">
          <p class="p_text_agradecimento">
            Parabéns! Sua inscrição foi concluída com sucesso! Estamos animados
            em tê-lo(a) como parte do nosso programa/evento. Prepare-se para uma
            experiência incrível de aprendizado e crescimento. Aguardamos
            ansiosamente por sua participação. Até breve!
          </p>
          <P class="p_apracam">APRACAM</P>
        </div>
      </div>
      <div class="row">
        <div class="col-md-4">
          <div class="div_img_one">
            <img
              class="img_one"
              src="../../../public/img/icon_apracam/join_icon.svg"
            />
          </div>
        </div>
        <div class="col-md-4">
          <div class="div_qrcode">qrcode</div>
          <div class="local_pag">
            <h2 class="cod_pag">Chave Pix: 36.716.085/0001-56</h2>
          </div>
          <div class="btn_div">
            <a href="/">
              <button type="button" class="btn_inscricao">HOME</button></a
            >
          </div>
        </div>
        <div class="col-md-4">
          <div class="div_img_two">
            <img
              class="img_two"
              src="../../../public/img/icon_apracam/chek.svg"
            />
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
        <style scoped>
#curso_selecionado {
  background-color: rgb(22, 82, 78);
  height: 100vh;
}
.title_curso {
  color: #feb715;
  font-size: 25pt;
  padding-top: 20px;
}
.logoQuatro {
  position: absolute;
  margin-left: 0;
  left: auto;
  right: auto;
  z-index: 100;
  width: 20%;
}

.text_agradecimentos {
  padding: 20px;
  margin-top: 20px;
  text-align: justify;
}
.p_text_agradecimento {
  color: #16524e;
  font-size: 15pt;
  font-weight: 500;
}
.p_apracam {
  color: #16524e;
  margin-bottom: 0;
  font-size: 16pt;
  font-weight: 700;
}
.img_one {
  width: 100%;
}
.div_img_one {
  padding: 40px;
  text-align: center;
}
.img_two {
  width: 100%;
}
.div_img_two {
  margin-top: 6rem;
  text-align: center;
  padding: 15px;
}
h1,
h2,
h3,
h4,
h5,
h6,
p,
span {
  font-family: Yanone;
}
.cod_pag {
  color: #feb715;
}
.local_pag {
  text-align: center;
  margin-top: 12rem;
  border: solid 2px #feb715;
  padding: 10px;
  border-radius: 10px;
}
.btn_inscricao {
  width: 60%;
  background: #1f6e69;
  color: #fff;
  font-size: 13pt;
  padding: 10px;
  border: solid 2px #fff;
  border-radius: 12px;
  transition: 0.4s;
  margin-top: 5px;
}
.btn_inscricao:hover {
  width: 65%;
  color: #feb715;
  border: solid 2px #feb715;
}
.btn_div {
  text-align: center;
}
@media (min-width: 992px) and (max-width: 1200px) {
  .logoQuatro {
    display: none;
  }
  .div_img_two {
    margin-top: 3rem;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .logoQuatro {
    display: none;
  }
  .div_img_two {
    margin-top: 3rem;
  }
}
@media (min-width: 576px) and (max-width: 767px) {
  .logoQuatro {
    display: none;
  }
  .div_img_two {
    display: none;
  }
  .img_two {
    display: none;
  }
  .local_pag {
    margin-top: 0;
  }
  .img_one {
    width: 50%;
  }
}
@media (max-width: 575px) {
  .logoQuatro {
    display: none;
  }
  .div_img_two {
    display: none;
  }
  .img_two {
    display: none;
  }
  .local_pag {
    margin-top: 0;
  }
  .img_one {
    width: 50%;
  }
  .p_text_agradecimento {
    color: #16524e;
    font-size: 14pt;
    font-weight: 500;
    text-align: center;
    line-height: 1.2;
  }
  .title_curso {
    padding-top: 10px;
    line-height: 1.1;
  }
}
</style>
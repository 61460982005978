<template>
  <section id="curso_selecionado">
    <div class="container">
      <div>
        <div class="row">
          <div class="col-md-6">
            <h1 class="title_curso">CANCELADO</h1>
          </div>
          <div class="col-md-6" style="text-align: right">
            <a href="/">
              <img class="logoQuatro" src="../../../public/img/logo.png"
            /></a>
          </div>
        </div>
      </div>
    </div>
    <div class="container">
      <div class="row">
        <div class="col-md-8 offset-lg-2">
          <div class="quad_agradecimento">
            <p class="p-text">
              Seu cadastro foi cancelado com sucesso, esperamos que em outra
              oportunidade você o faça novamente.
            </p>
          </div>
        </div>
      </div>
      <div class="btn_div">
        <a href="/">
          <button type="button" class="btn_inscricao">IR PARA HOME</button></a
        >
      </div>
    </div>
  </section>
</template>
<script>
import { reactive, onMounted } from "vue";
export default {
  setup() {
    const state = reactive({});
    onMounted(() => {});
    return {
      state,
    };
  },
};
</script>
<style scoped>
h1,
h2,
h3,
h4,
h5,
h6,
p,
span {
  font-family: Yanone;
  font-weight: 100;
}
#curso_selecionado {
  background-color: rgb(22, 82, 78);
  height: 100vh;
}
.title_curso {
  color: #feb715;
  font-size: 25pt;
  padding-top: 20px;
}
.logoQuatro {
  position: absolute;
  margin-left: 0;
  left: auto;
  right: auto;
  z-index: 100;
  width: 20%;
}
.btn_inscricao {
  width: 150px;
  height: 50px;
  background: #1f6e69;
  color: #fff;
  font-size: 16pt;
  border: solid 2px #fff;
  border-radius: 12px;
  transition: 0.4s;
  margin-top: 15px;
  font-weight: 100;
  font-family: Yanone;

}
.btn_inscricao:hover {
  width: 160px;
  color: #feb715;
  border: solid 2px #feb715;
}
.btn_div {
  text-align: center;
}
.p-text {
  color: #fff;
  font-size: 18pt;
}
.p-apracam {
  color: #feb715;
  font-weight: 700;
}
.quad_agradecimento {
  box-shadow: 7px 10px 5px 2px #040d0ca3;
  background: #19625d;
  padding: 10px;
  border-radius: 10px;
  margin-top: 2rem;
  margin-bottom: 3rem;
}
@media (min-width: 992px) and (max-width: 1200px) {
  .logoQuatro {
    display: none;
  }
  #curso_selecionado {
    height: 100vh;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .logoQuatro {
    position: absolute;
    margin-left: 0;
    left: auto;
    right: 0px;
    z-index: 100;
    width: 20%;
  }
}
@media (min-width: 576px) and (max-width: 767px) {
  .logoQuatro {
    display: none;
  }
  #curso_selecionado {
    height: auto;
  }
  .pag-deb {
    margin-top: 1rem;
  }
  .pag-pix {
    margin-top: 1rem;
  }
  .pag-qrcode {
    margin-top: 1rem;
  }
}
@media (max-width: 575px) {
  .logoQuatro {
    display: none;
  }
  #curso_selecionado {
    height: auto;
  }
  .pag-deb {
    margin-top: 1rem;
  }
  .pag-pix {
    margin-top: 1rem;
  }
  .pag-qrcode {
    margin-top: 1rem;
  }
}
</style>
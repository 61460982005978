import axios from 'axios'
import AuthService from './auth'
import ErroService from './erro'
import medicosService from './medicos'
import CursosService from './cursos'
import PacienteService from './paciente'
import LoginService from './login'
import CadastrarService from './cadastrar'
import UsuarioService from './usuario'
import cepService from './cep'
import patologiaService from './patologia'
import tiposOleoService from './tipo_oleo'
import cooperadorService from './cooperadores'


const API_ENVS = {
  production: '',
  development: '',
  local: 'https://backend.apracam.com.br/api/',//'https://backtesteapracam.centrion.com.br/api/',
  // 'https://backend.apracam.com.br/api/', // 'http://192.168.3.12:8585/api/',// local: 'http://192.168.3.103:8000/api/',// 'http://192.168.3.12:8585/api/',  
  cep: 'http://viacep.com.br/ws/'

}
const httpClient = axios.create({
  baseURL: API_ENVS.local
})
const httpCep = axios.create({
  baseURL: API_ENVS.cep
})
export default {
  devServer: {
    //    proxy: 'https://backendapracam.centrion.com.br/api/'
    proxy: 'http://192.168.3.103:8000/api/'
  },
  auth: AuthService(httpClient),
  erro: ErroService(httpClient),
  //medicos: MedicosService(httpClient),
  cursos: CursosService(httpClient),
  paciente: PacienteService(httpClient),
  login: LoginService(httpClient),
  cadastrar: CadastrarService(httpClient),
  usuario: UsuarioService(httpClient),
  cep: cepService(httpCep),
  patologia: patologiaService(httpClient),
  tipos_oleo: tiposOleoService(httpClient),
  medicos: medicosService(httpClient),
  cooperadores: cooperadorService(httpClient)
}
<template>
  <tr @click="handleToggle">
    <th scope="row">
      <div class="media align-items-center">
        <div class="media-body num_id_table">
          {{ paciente.id }}
        </div>
      </div>
    </th>
    <td>
      {{ paciente.pessoa.nome }}
    </td>
    <td>
      {{ paciente.pessoa.telefone }}
    </td>
    <td>
      {{ paciente.pessoa.email }}
    </td>
    <td>
      {{ paciente.tipo }}
    </td>
    <td class="text-right">
      <router-link :to="{ name: 'DispensaOleos', params: { id: paciente.id } }">

      <button @click="openModal(paciente.id)" class="btn_pen" type="button" title="Dispensar Óleo">
        <i class="bi bi-bag-check-fill"></i>
      </button>
      </router-link>
      <router-link :to="{ name: 'visualizarPaciente', params: { id: paciente.id } }">
        <button class="btn_pen" type="button" title="Alterar">
          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-pen"
            viewBox="0 0 16 16">
            <path
              d="m13.498.795.149-.149a1.207 1.207 0 1 1 1.707 1.708l-.149.148a1.5 1.5 0 0 1-.059 2.059L4.854 14.854a.5.5 0 0 1-.233.131l-4 1a.5.5 0 0 1-.606-.606l1-4a.5.5 0 0 1 .131-.232l9.642-9.642a.5.5 0 0 0-.642.056L6.854 4.854a.5.5 0 1 1-.708-.708L9.44.854A1.5 1.5 0 0 1 11.5.796a1.5 1.5 0 0 1 1.998-.001zm-.644.766a.5.5 0 0 0-.707 0L1.95 11.756l-.764 3.057 3.057-.764L14.44 3.854a.5.5 0 0 0 0-.708l-1.585-1.585z" />
          </svg>
        </button>
      </router-link>
      <button @click="$emit('deletePaciente', paciente.id)" class="btn_delete" type="button" title="Deletar">
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-trash"
          viewBox="0 0 16 16">
          <path
            d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5Zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5Zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6Z" />
          <path
            d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1ZM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118ZM2.5 3h11V2h-11v1Z" />
        </svg>
      </button>
    </td>
  </tr>
</template>
<script>
import { reactive } from "vue";
export default {

  props: {
    isOpened: { type: Boolean, default: false },
    paciente: { type: Object, required: true },
  },
  setup(props) {
    const state = reactive({
      isOpen: props.isOpened,
      isClosing: !props.isOpened,
      modal: false,
      id_cliente: null,
    });

    async function handleToggle() {
      state.isClosing = true;
      //  await wait(250)
      state.isOpen = !state.isOpen;
      state.isClosing = false;
    }

    return {
      state,
      handleToggle,
    };
  },
};
</script>
<style scoped>
.btn_pen {
  color: #ffffff;
  background: rgb(40, 149, 146);
  border: rgb(40, 149, 146) solid 2px;
  border-radius: 5px;
  margin-right: 10px;
  transition: 0.2s;
  width: 35px;
  height: 35px;
}

.btn_pen:hover {
  background: rgb(10, 36, 35);
  color: rgb(40, 149, 146);
}

.btn_delete {
  width: 35px;
  height: 35px;
  color: #ffffff;
  background: rgb(40, 149, 146);
  border: rgb(40, 149, 146) solid 2px;
  border-radius: 5px;
  margin-right: 10px;
  transition: 0.2s;
}

.btn_delete:hover {
  background: rgb(10, 36, 35);
  color: rgb(40, 149, 146);
}
</style>

<template>
  <div class="d-flex d-sm-none">
    <TopCell />
  </div>
  <section id="curso_selecionado">
    <header>
      <div class="container">
        <div class="row">
          <MenuMobileDois />
          <div class="col-md-6">
            <h1 class="title_curso">PAGAMENTO</h1>
            <div class="d-flex d-sm-none div_nome_curso">
              <img src="/img/icon_apracam/heart_hand.png" class="icon_cel" />
            </div>
          </div>
          <div class="col-md-6" style="text-align: right">
            <a href="/">
              <img class="logoQuatro" src="../../../public/img/logo.png"
            /></a>
          </div>
        </div>
      </div>
    </header>
    <div class="container">
      <div style="background-color: #fff">
        <div class="quad-space">
          <h2 class="escolha">ESCOLHA COMO CONTRIBUIR</h2>
          <div class="row">
            <div class="col-md-4">
              <div class="quad">
                <span class="tipo">Débito:</span>
                <span class="tipo">Ag: 0726 </span>
                <span class="tipo">c/c: 55363-7</span>
              </div>
              <div class="quad-pix">
                <div style="display: flex; flex-direction: row; align-content: space-between;">
                  <div style="display: flex;">
                    <span class="tipo">PIX:</span>
                    <textarea readonly id="textArea">
                      36.716.085/0001-56
                    </textarea>
                  </div>
                  <div style="float: right;">
                    <button  
                      style="margin-right: 50px;"
                      v-if="state.btn == false"
                      class="btn-copy"
                      @click="copyToClipBoard()">
                      <img src="/img/copy_png.png" style="width: 30px;">
                    </button>

                   <button
                      v-if="state.btn == true"
                      class="btn-copy-check"
                      @click="copyToClipBoard()">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="16"
                          height="16"
                          fill="currentColor"
                          class="bi bi-check-lg"
                          viewBox="0 0 16 16"
                        >
                          <path
                            d="M12.736 3.97a.733.733 0 0 1 1.047 0c.286.289.29.756.01 1.05L7.88 12.01a.733.733 0 0 1-1.065.02L3.217 8.384a.757.757 0 0 1 0-1.06.733.733 0 0 1 1.047 0l3.052 3.093 5.4-6.425a.247.247 0 0 1 .02-.022Z"
                          />
                      </svg>
                    </button>
                  </div>
                  <div style="clear: both;"></div>
                </div>
              </div>
              <div class="quad-code">
                <img class="img-code" src="../../../public/img/qrcode.png" />
                <p class="tipo-code">QRCODE:</p>
              </div>
              <div class="btn_div">
                <a href="/"
                  ><button type="button" class="btn_inscricao">
                    FINALIZAR
                  </button></a
                >
              </div>
            </div>
            <div class="col-md-4 offset-md-1">
              <h1 class="text-apracam">
                FORMULÁRIO DE CADASTRO APRACAM ASSOCIÇÃO PARANAENSE DE APOIO A
                CANNABIS MEDICINAL
              </h1>
            </div>
            <div class="col-md-3">
              <div class="div-img">
                <img
                  class="img-heart"
                  src="../../../public/img/icon_apracam/heart_hand.png"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- <div class="container">
      <div class="row">
        <div
          class="col-md-8 offset-lg-2"
          v-if="state.metodo == 'anualidade' || state.metodo == 'mensal'"
        >
          <div class="quad_agradecimento">
            <p class="p-text">
              Agradecemos por contruibir com nossa equipe, sua forma de
              contruibição foi:
              <span v-if="state.metodo == 'anualidade'"
                >Anualidade de R$ 240,00</span
              >
              <span v-if="state.metodo == 'mensal'">Mensal de R$ 20,00</span>
            </p>
          </div>
        </div>
        <div
          class="col-md-8 offset-2"
          v-if="state.metodo == 'nao_contribuinte' || state.metodo == 'cota'"
        >
          <div class="quad_agradecimento">
            <p class="p-text">
              Agradecemos a sua confiança ao nosso time, ficamos felizes em
              tê-lo conosco!
            </p>
          </div>
        </div>
      </div>
      <div
        class="row"
        v-if="state.metodo == 'anualidade' || state.metodo == 'mensal'"
      >
        <div class="col-md-4">
          <div class="pag-deb">
            <p class="tipo">DADOS BANCÁRIOS</p>
            <p class="p_data">Ag: 0726 /</p>
            <p class="p_data">c/c: 55363-7</p>
          </div>
        </div>
        <div class="col-md-4">
          <div class="pag-pix">
            <p class="pix">CHAVE PIX</p>
            <div class="row">
              <div class="col-6">
                <textarea readonly id="textArea">36.716.085/0001-56</textarea>
              </div>
              <div class="col-6">
                <button
                  v-if="state.btn == false"
                  class="btn-copy"
                  @click="copyToClipBoard()"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    class="bi bi-clipboard"
                    viewBox="0 0 16 16"
                  >
                    <path
                      d="M4 1.5H3a2 2 0 0 0-2 2V14a2 2 0 0 0 2 2h10a2 2 0 0 0 2-2V3.5a2 2 0 0 0-2-2h-1v1h1a1 1 0 0 1 1 1V14a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1V3.5a1 1 0 0 1 1-1h1v-1z"
                    />
                    <path
                      d="M9.5 1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-3a.5.5 0 0 1-.5-.5v-1a.5.5 0 0 1 .5-.5h3zm-3-1A1.5 1.5 0 0 0 5 1.5v1A1.5 1.5 0 0 0 6.5 4h3A1.5 1.5 0 0 0 11 2.5v-1A1.5 1.5 0 0 0 9.5 0h-3z"
                    />
                  </svg>
                </button>
                <button
                  v-if="state.btn == true"
                  class="btn-copy-check"
                  @click="copyToClipBoard()"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    class="bi bi-check-lg"
                    viewBox="0 0 16 16"
                  >
                    <path
                      d="M12.736 3.97a.733.733 0 0 1 1.047 0c.286.289.29.756.01 1.05L7.88 12.01a.733.733 0 0 1-1.065.02L3.217 8.384a.757.757 0 0 1 0-1.06.733.733 0 0 1 1.047 0l3.052 3.093 5.4-6.425a.247.247 0 0 1 .02-.022Z"
                    />
                  </svg>
                </button>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-4">
          <div class="pag-qrcode">
            <p class="cod_pag">QRCODE</p>
            <img src="../../../public/img/qrcode.png" />
          </div>
        </div>
      </div>
      <div class="btn_div">
        <a href="/">
          <button type="button" class="btn_inscricao">HOME</button></a
        >
      </div>
    </div>-->
  </section>
</template>
<script>
import MenuMobileDois from "../../components/MenuMobileDois.vue";
import TopCell from "../../components/TopCell.vue";
import { reactive, onMounted } from "vue";
import { useStorage } from "vue3-storage";
import { toast } from "vue3-toastify";
import "vue3-toastify/dist/index.css";
export default {
  setup() {
    const storage = useStorage();
    const state = reactive({
      metodo: "",
      btn: false,
    });
    function copyToClipBoard() {
      var content = document.getElementById("textArea");
      content.select();
      document.execCommand("copy");
      state.btn = true;
      toast.success("PIX Copiado!!!", {
        autoClose: 2000,
      });
    }
    onMounted(() => {
      state.metodo = storage.getStorageSync("metodo");
    });

    return {
      copyToClipBoard,
      state,
      MenuMobileDois,
      TopCell,
    };
  },
};
</script>
<style scoped>
h1,
h2,
h3,
h4,
h5,
h6,
p,
span {
  font-family: Yanone;
  font-weight: 100;
}
.escolha {
  color: rgb(22, 82, 78);
  font-weight: 400;
}
.quad-code {
  margin-bottom: 1rem;
  border: none;
  color: rgb(22, 82, 78);
  border-radius: 20px;
  padding: 10px;
  font-family: Yanone;
  text-align: center;
}
.tipo-code {
  color: #16524e !important;
  text-align: center;
  font-size: 16pt;
  font-weight: 300;
}
.title_curso {
  color: #fff;
  margin-top: 1rem;
}
#curso_selecionado {
  background-color: #fff;
  min-height: 100vh;
}
header {
  background-color: rgb(22, 82, 78);
  padding-bottom: 50px;
  height: 90px;
}
.tipo {
  color: #16524e !important;
  text-align: left;
  font-size: 16pt;
  font-weight: 300;
  margin-right: 5px;
  margin-top: 3px;
}
.quad-space {
  padding-top: 5rem;
}
.quad {
  margin-bottom: 1rem;
  border: solid 1px rgb(22, 82, 78);
  color: rgb(22, 82, 78);
  border-radius: 20px;
  padding: 10px;
  font-family: Yanone;
}
.quad-pix {
  margin-bottom: 1rem;
  border: solid 1px rgb(22, 82, 78);
  color: rgb(22, 82, 78);
  border-radius: 20px;
  padding: 10px;
  font-family: Yanone;
  display: flex;
}
img {
  width: 120px;
}

.btn-copy {
  background-color: transparent;
  color: rgb(22, 82, 78);
  padding: 5px;
  transition: 0.3s;
  border: none; 
}
.btn-copy-check {
  background-color: transparent;
  border: none;
  color: rgb(22, 82, 78);
  padding: 5px;
  transition: 0.3s;
}
textarea {
  color: #16524e !important;
  text-align: left;
  font-size: 16pt;
  font-weight: 300;
  background: transparent;
  border: none;
  resize: none;
  height: 36px;
}
textarea:focus {
  outline: none;
}
.text-apracam {
  font-weight: 500;
  color: #84a4a1;
  font-size: 40pt;
  line-height: 1.2;
  font-family: Yanone;
}
.btn_div {
  text-align: center;
}
.btn_inscricao {
  width: 150px;
  background: #1f6e69;
  color: #fff;
  font-size: 16pt;
  height: 50px;
  border: solid 2px #fff;
  border-radius: 20px;
  transition: 0.4s;
  margin-top: 5px;
  border: solid 2px #fff;
  font-family: Yanone;
  font-weight: 100;
}
.div-img {
  text-align: center;
  margin-top: 5rem;
}
.img-heart {
  width: 100%;
}
.img-code {
}
.title_curso {
  font-weight: 300;
  color: #fff;
  font-size: 25pt;
  padding-top: 20px;
  font-family: yanone;
  letter-spacing: 0.2rem;
}

@media (min-width: 992px) and (max-width: 1200px) {
  .logoQuatro {
    display: none;
  }
  #curso_selecionado {
    height: 100vh;
  }
  .btn-copy {
    margin-left: 70px;
  }
  .text-apracam {
    font-size: 35pt;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .logoQuatro {
    position: absolute;
    margin-left: 0;
    left: auto;
    right: 0px;
    z-index: 100;
    width: 20%;
  }
  textarea {
    padding: 0;
    margin-top: 0;
  }
  .btn-copy {
    margin-top: 70px;
    margin-left: 25px;
  }
  .btn-copy-check {
    margin-top: 70px;
    margin-left: 30px;
  }
  .img-code {
    margin-left: 35px;
  }
}
@media (min-width: 576px) and (max-width: 767px) {
  .logoQuatro {
    display: none;
  }
  #curso_selecionado {
    height: auto;
  }
  .text-apracam {
    text-align: center;
    margin-top: 5rem;
  }
  .btn-copy {
    margin-left: 12rem;
  }
  .btn-copy-check {
    margin-left: 12rem;
  }
  .text-apracam {
    display: none;
  }
  .div-img {
    display: none;
  }
}
@media (max-width: 575px) {
  .icon_cel {
    margin-right: 20px;
    width: 65px;
    height: 60px;
    background: #0e3b38;
    padding: 10px;
    border-radius: 100px;
  }
  .titulo_cel {
    font-family: Yanone;
    font-size: 14pt;
    color: #feb715;
    padding-top: 1rem;
    text-align: right;
  }
  header {
    height: auto;
  }
  .logoQuatro {
    display: none;
  }
  .logoQuatro {
    display: none;
  }
  #curso_selecionado {
    height: auto;
  }
  .text-apracam {
    display: none;
  }
  .div-img {
    display: none;
  }

  header {
    height: auto;
  }
  .logoQuatro {
    display: none;
  }
  .div_nome_curso {
    width: 100%;
  }
  .title_curso {
    color: #fff;
  }
  .btn-copy {
    margin-left: 6rem;
  }
  .btn-copy-check {
    margin-left: 6rem;
  }
}
</style>
<template>
  <div class="dropdown">
    <button class="btn_menu" type="button" data-toggle="dropdown" aria-expanded="false">
        <img src="/img/barras_menu.png" style="width: 100%; padding: 5px;">
    </button>
    <div class="dropdown-menu">
        <a class="dropdown-item" href="#">HOME</a>
        <a class="dropdown-item" href="#servicos">SERVIÇOS</a>
        <a class="dropdown-item" href="#equipe">EQUIPE</a>
        <a class="dropdown-item" href="#doe_apracam">DOE</a>
      <!--<a class="dropdown-item" href="#cursos">CURSOS</a>-->
        <a class="dropdown-item" href="#colaboradores">COLABORADORES</a>
    </div>
  </div>
</template>
<style>
.dropdown{
    z-index: 300;
}
.btn_menu{
  width:80px; height:80px; border-radius: 50%; border:#fff solid 4px; background-color: #0C5B56;  margin-left: 15px;
}
.dropdown-item {
    color: #0C5B56 !important;
}
.dropdown-menu .dropdown-item:focus{
  background: #feb715;
}


@media (max-width: 575px) {
  .dropdown{
    display: none !important;
  }
}
@media (min-width: 576px) and (max-width: 767px) {
  .dropdown{
    display: none;
  }
}
</style>
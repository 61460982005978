<template>
  <div class="wrapper" :class="{ 'nav-open': $sidebar.showSidebar }">
    <side-bar :background-color="sidebarBackground" short-title="">
      <template v-slot:links>
        <sidebar-item
          :link="{
            name: 'Início',
            icon: 'bi bi-house-door-fill',
            path: '/admin/inicio',
          }"
        />
        <sidebar-item
          :link="{
            name: 'Pacientes',
            icon: ' bi bi-person-fill',
            path: '/admin/pacientes',
          }"
        />
        <sidebar-item
          :link="{
            name: 'Pets',
            icon: ' bi bi-heart-fill',
            path: '/admin/pets',
          }"
        />
        <sidebar-item
          :link="{
            name: 'Entrevista-Social',
            icon: ' bi bi-journal-bookmark-fill',
            path: '/admin/entrevista-social',
          }"
        />

        <sidebar-item
          :link="{
            name: 'Cooperadores',
            icon: 'bi bi-capsule',
            path: '/admin/cooperadores',
          }"
        />

        <sidebar-item
          :link="{
            name: 'Médicos',
            icon: 'bi bi-capsule',
            path: '/admin/medicos',
          }"
        />

        <sidebar-item
          :link="{
            name: 'Patologias',
            icon: 'bi bi-capsule',
            path: '/admin/patologias',
          }"
        />

        <sidebar-item
          :link="{
            name: 'Tipos de Óleos',
            icon: 'bi bi-capsule',
            path: '/admin/tipos-oleos',
          }"
        />
        <sidebar-item
          :link="{
            name: 'Relatórios',
            icon: 'bi bi-clipboard2-fill',
            path: '/admin/relatorios',
          }"
        />
        <!-- <sidebar-item
          :link="{
            name: 'Cursos',
            icon: 'bi bi-journal',
            path: '/admin/cursos',
          }"
        />
        <sidebar-item
          :link="{
            name: 'Alunos Cursos',
            icon: 'bi bi-journal-bookmark-fill',
            path: '/admin/alunos-cursos',
          }"
        />-->
        <sidebar-item
          :link="{
            name: 'Usuários',
            icon: 'bi bi-person-fill',
            path: '/admin/usuarios',
          }"
        />
        <button @click="deslogar" title="SAIR" type="button" class="btn_sair">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            fill="currentColor"
            class="bi bi-door-open"
            viewBox="0 0 16 16"
          >
            <path
              d="M8.5 10c-.276 0-.5-.448-.5-1s.224-1 .5-1 .5.448.5 1-.224 1-.5 1z"
            />
            <path
              d="M10.828.122A.5.5 0 0 1 11 .5V1h.5A1.5 1.5 0 0 1 13 2.5V15h1.5a.5.5 0 0 1 0 1h-13a.5.5 0 0 1 0-1H3V1.5a.5.5 0 0 1 .43-.495l7-1a.5.5 0 0 1 .398.117zM11.5 2H11v13h1V2.5a.5.5 0 0 0-.5-.5zM4 1.934V15h6V1.077l-6 .857z"
            />
          </svg>
        </button>
      </template>
    </side-bar>
    <div class="main-content" :data="sidebarBackground">
      <dashboard-navbar></dashboard-navbar>

      <div @click="toggleSidebar">
        <!-- your content here -->
        <router-view></router-view>
        <content-footer v-if="!$route.meta.hideFooter"></content-footer>
      </div>
    </div>
  </div>
</template>
<script>
import DashboardNavbar from "./DashboardNavbar.vue";
import ContentFooter from "./ContentFooter.vue";
import { useRouter } from "vue-router";
import { useStorage } from "vue3-storage";
export default {
  components: {
    DashboardNavbar,
    ContentFooter,
  },
  data() {
    return {
      sidebarBackground: "vue", //vue|blue|orange|green|red|primary
    };
  },
  methods: {
    toggleSidebar() {
      if (this.$sidebar.showSidebar) {
        this.$sidebar.displaySidebar(false);
      }
    },
  },
  setup() {
    const storage = useStorage();
    const router = useRouter();
    var token = storage.getStorageSync("token");
    console.log(token);
    async function deslogar() {
      if (confirm("Deseja realmente sair?")) {
        try {
          storage.removeStorageSync("token");
          router.push({ name: "home" });
          return;
        } catch (error) {
          console.log(error);
        }
      }
    }
    return {
      deslogar,
    };
  },
};
</script>
<style scoped>
.btn_sair {
  margin-left: 25px;
  width: 40px;
  height: 40px;
  border-radius: 5px;
  border: 2px solid #0a2423;
  background: #0a2423;
  color: #f2af15;
  transition: 0.2s;
}

.btn_sair:hover {
  background: #f05a5a;
  color: #f8f9fe;
}
</style>
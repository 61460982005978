export default httpClient => ({
  /*----------------SITE--------------------*/
  ativas: async () => {
    const response = await httpClient.get('/tipos-oleo-ativos')
    return { data: response }
  },


  /*--------------SISTEMA--------------------*/
  obterPorId: async ({ id, token }) => {
    const headers = {
      "Authorization": "Bearer " + token
    }
    const response = await httpClient.get('/tipos-oleo/' + id, { headers });
    return { data: response.data }
  },
  getAll: async ({ token }) => {
    const headers = {
      "Authorization": "Bearer " + token
    }
    const response = await httpClient.get('/tipos-oleo', { headers });
    return { data: response.data }
  },
  save: async ({ token, tipo_oleo }) => {
    const headers = {
      "Authorization": "Bearer " + token
    }
    const response = await httpClient.post('/tipos-oleo', tipo_oleo, { headers })

    return {
      data: response.data,
    }
  },
  update: async ({ token, tipo_oleo }) => {
    let headers = { "Authorization": "Bearer " + token }
    const response = await httpClient.put('/tipos-oleo/' + tipo_oleo.id, tipo_oleo, { headers })
    return {
      data: response.data,
    }
  },
  delete: async ({ id, token }) => {
    const headers = {
      "Authorization": "Bearer " + token
    }
    const response = await httpClient.delete('/tipos-oleo/' + id, { headers })
    return { data: response }
  },

  dispensaOleoCliente: async ({ id, token }) => {
    const headers = { "Authorization": "Bearer " + token }
    const response = await httpClient.get('/dispensa-oleo-por-paciente/' + id, { headers });
    return { data: response.data }
  },
  saveDispensa: async (dispensa, token) => {
    const headers = { "Authorization": "Bearer " + token }
    const response = await httpClient.post('/dispensa-oleo', dispensa, { headers })
    return {
      data: response.data,
    }
  },
  deleteDispensa: async ({ id, token }) => {
    const headers = { "Authorization": "Bearer " + token }
    const response = await httpClient.delete('/dispensa-oleo/' + id, { headers })
    return { data: response }
  },
})

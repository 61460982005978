<template>
  <modal-factory />
  <div>
    <base-header
      type="gradient-success"
      class="pb-6 pb-8 pt-5 pt-md-8 header_gradiant"
    >
    </base-header>
    <div class="container-fluid mt--7">
      <div class="row">
        <div class="col-xl-12 col-lg-12">
          <stats-card>
            <nav aria-label="breadcrumb">
              <ol class="breadcrumb">
                <li class="breadcrumb-item router_header">
                  <router-link to="/patologias">Tipo de Oleos</router-link>
                </li>
                <li class="breadcrumb-item router_header">
                  <router-link to="/cadastrar-tipo-oleo " class="primary"
                    >Salvar
                  </router-link>
                </li>
              </ol>
            </nav>
            <form>
              <h2>Informações do Tipo de Oleo:</h2>
              <div class="row">
                <div class="col-md-2" style="display: none">
                  <div class="form-group">
                    <label>ID</label>
                    <input
                      type="text"
                      class="form-control"
                      readonly
                      v-model="state.tipo_oleo.id"
                    />
                  </div>
                </div>
              </div>

              <div class="row">
                <div class="col-md-8">
                  <div class="form-group">
                    <label>Nome</label>
                    <input
                      type="text"
                      class="form-control"
                      v-model="state.tipo_oleo.nome"
                    />
                  </div>
                </div>
                <div class="col-md-2">
                  <div class="form-group">
                    <label>Status</label>
                    <select
                      class="form-control"
                      v-model="state.tipo_oleo.status"
                    >
                      <option value="ATIVO">Ativo</option>
                      <option value="INATIVO">Inativo</option>
                    </select>
                  </div>
                </div>
              </div>
              <div class="div_btns">
                <router-link to="/admin/patologias">
                  <button
                    class="btn btn_cancelar"
                    id="submit-button"
                    type="button"
                  >
                    Cancelar
                  </button>
                </router-link>
                <button
                  class="btn btn_salvar"
                  id="submit-button"
                  type="button"
                  @click="salvarPatologia"
                >
                  Cadastrar
                </button>
              </div>
            </form>
          </stats-card>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { onMounted, reactive } from "vue";
import { useRouter } from "vue-router";
import services from "../../services";
import { useStorage } from "vue3-storage";
export default {
  setup() {
    const storage = useStorage();
    const router = useRouter();
    var token = storage.getStorageSync("token");
    const state = reactive({
      tipo_oleo: {
        id: null,
        nome: null,
        status: "ATIVO",
      },
    });
    onMounted(() => {
      console.log(router.currentRoute._value.params.id);
      if (router.currentRoute._value.params.id != undefined) {
        fetchTipoOleo();
      }
    });
    async function fetchTipoOleo() {
      try {
        const { data } = await services.tipos_oleo.obterPorId({
          id: router.currentRoute._value.params.id,
          token,
        });
        state.tipo_oleo = data;
      } catch (error) {
        console.log(error);
      }
    }

    async function salvarPatologia() {
      if (!state.tipo_oleo.id) {
        services.tipos_oleo
          .save({ token, tipo_oleo: state.tipo_oleo })
          .then(() => {
            router.push({ name: "tipoOleo" });
          })
          .catch((error) => {
            console.log(error);
          });
      } else {
        services.tipos_oleo
          .update({ id: state.id, token, tipo_oleo: state.tipo_oleo })
          .then(() => {
            router.push({ name: "tipoOleo" });
          })
          .catch((error) => {
            console.log(error);
          });
      }
    }
    return {
      salvarPatologia,
      state,
    };
  },
};
</script>
      <style scoped>
.header_gradiant {
  background: linear-gradient(
    50deg,
    rgb(82, 181, 174) 0%,
    rgb(10, 36, 35) 100%,
    rgb(22, 82, 78)
  ) !important;
}
h2 {
  color: black;
  font-size: 18pt;
  font-weight: 700;
}
.btn_cancelar {
  background-color: rgb(255, 255, 255);
  color: rgb(10, 36, 35);
  border: solid 3px rgb(10, 36, 35);
  padding: 15px;
  transition: 0.2s;
  margin-right: 15px;
  width: 20%;
  font-size: 11pt;
}
.btn_cancelar:hover {
  background-color: rgb(10, 36, 35);
  border: solid 3px rgb(10, 36, 35);

  color: #ffffff;
  padding: 15px;
  transform: none;
}
.btn_salvar {
  background-color: rgb(255, 255, 255);
  border: solid 3px rgb(40, 149, 146);
  color: rgb(40, 149, 146);
  padding: 15px;
  transition: 0.3s;
  width: 15%;
  font-size: 11pt;
}
.btn_salvar:hover {
  background-color: rgb(40, 149, 146);
  border: solid 3px rgb(40, 149, 146);
  color: rgb(0, 0, 0);
  padding: 15px;
  transition: 0.3s;
  transform: none;
}
.div_btn {
  text-align: right;
}
input {
  color: #000;
  border: solid 2px rgb(10, 36, 35);
  border-color: rgb(10, 36, 35);
  padding-top: 22px;
  padding-bottom: 22px;
  font-size: 12pt;
}
input:focus {
  color: #000;
  border: solid 2px rgb(40, 149, 146);
  border-color: rgb(40, 149, 146);
}
select {
  color: #000;
  border: solid 2px rgb(10, 36, 35);
  border-color: rgb(10, 36, 35);
  font-size: 12pt;
}
select:focus {
  color: #000;
  border: solid 2px rgb(40, 149, 146);
  border-color: rgb(40, 149, 146);
  font-size: 12pt;
}
label {
  color: #000;
  margin-bottom: 0;
  font-size: 14pt;
}
.div_btns {
  width: 100%;
  text-align: right;
}
a {
  color: rgb(82, 181, 174);
}
a:hover {
  color: rgb(22, 82, 78);
}
.file_input {
  height: 180px;
  color: #000;
  border: solid 2px rgb(10, 36, 35);
  border-color: rgb(10, 36, 35);
  padding-top: 22px;
  padding-bottom: 22px;
  font-size: 12pt;
}
.file_input:focus {
  color: #000;
  border: solid 2px rgb(40, 149, 146);
  border-color: rgb(40, 149, 146);
  font-size: 12pt;
}
textarea {
  color: #000;
  border: solid 2px rgb(10, 36, 35);
  border-color: rgb(10, 36, 35);
  padding-top: 22px;
  padding-bottom: 22px;
  font-size: 12pt;
  resize: none;
}
textarea:focus {
  color: #000;
  border: solid 2px rgb(40, 149, 146);
  border-color: rgb(40, 149, 146);
  font-size: 12pt;
}
.img_fetch {
  width: 100%;
}
.btn_deletar_imagem {
  background: rgb(255, 0, 0);
  border: solid 1px #000;
  height: 30px;
  border-radius: 5px;
  color: #fff;
  transition: 0.2s;
}
.btn_deletar_imagem:hover {
  background: rgb(255, 255, 255);
  color: #000000;
}
</style>